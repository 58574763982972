<app-navigation></app-navigation>
<ngx-loading [show]="service.loading"> </ngx-loading>
<section class="main-cont">
  <section class="section-1">
    <div class="row">
      <div class="col-sm-12">
        <h4>Edit Profile</h4>
      </div>
    </div>
  </section>
  <section class="section-2 edit-profile">
    <form [formGroup]="profileForm">
      <div class="row">

        <div class="col-sm-8 px-0">
          <div class="row">

            <div class="col-sm-12">
              <!-- <div class="custom-file"> -->
               <div class="profilePicture">
                <img [src]="imgURL || userProfile.profileUrl || '/assets/imgs/icon-avatar.svg'" class="userImg">
                <!-- <img [src]="imgURL" class="userImg"> -->
                <button class="btn btn-primary ml-2" (click)="SelectFiles0.click()" style="border-radius:5px !important; height: 35px;">Select Profile Picture</button>
                <input #SelectFiles0 class="d-none" type="file" accept="image/*" (change)="onChangeFile($event)">
               </div>
              <!-- </div> -->
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label for="my-input">Full Name</label>
                <input id="my-input" class="form-control" type="text" formControlName="name"
                  [(ngModel)]="userProfile.name" placeholder="Enter Name">
                <div *ngIf="submitted && f.name.errors" class="redErrorText pl-2">
                  <div *ngIf="f.name.errors.required">*Full Name is required</div>
                  <div *ngIf="f.name.errors.minlength || f.name.errors.maxlength">*Full Name length should be between 3
                    to 30 characters</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="my-input">Email</label>
                <input id="my-input" class="form-control" type="text" formControlName="email"
                  [(ngModel)]="userProfile.email" placeholder="Enter Email" readonly>
                <!-- <div *ngIf="submitted && f.name.errors" class="redErrorText pl-2">
                  <div *ngIf="f.name.errors.required">*Full Name is required</div>
                  <div *ngIf="f.name.errors.minlength || f.name.errors.maxlength">*Full Name length should be between 3
                    to 30 characters</div>
                </div> -->
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="my-input">Categories</label>
                <ng-select [items]="categoryList" formControlName="categoryId" [(ngModel)]="userProfile.categoryId"
                  placeholder="Select Category" bindLabel="categoryName" bindValue="categoryId" [multiple]="false"
                  (change)="getSubCategories($event)">
                </ng-select>

                <!-- <div *ngIf="submitted && !userProfile.categoryId" class="redErrorText pl-2">
                  *Category is required </div> -->
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="my-input">Sub-Categories</label>
                <ng-select [items]="subCategoryList" formControlName="subCategoryId"
                  [(ngModel)]="userProfile.subCategoryId" placeholder="Select Sub-Category" bindLabel="categoryName"
                  bindValue="subCategoryId" [multiple]="false">
                </ng-select>

                <!-- <div *ngIf="submitted && !userProfile.subCategoryId" class="redErrorText pl-2">
                  *Sub Category is required </div> -->
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="my-input">Phone</label>
                <input id="my-input" class="form-control" type="tel" formControlName="phoneNo"
                  [(ngModel)]="userProfile.phoneNo" placeholder="Enter Phone">
                <div *ngIf="submitted && f.phoneNo.errors" class="redErrorText pl-2">
                  <div *ngIf="f.phoneNo.errors.required">*Phone Number is required</div>
                  <div *ngIf="f.phoneNo.errors.minlength || f.phoneNo.errors.maxlength">*Phone Number length should be
                    between 7 to 16 characters</div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label for="my-input">Location</label>
                <ngxgeo-autocomplete id="locationSerach" [userSettings]="userSettings1"
                  style="background-color: white !important;" (componentCallback)="autoCompleteCallback1($event) ">
                </ngxgeo-autocomplete>

                <div *ngIf="submitted && !userProfile.location" class="redErrorText pl-2">
                  *Location is required </div>
              </div>
            </div>
            <div class="col-sm-12 pt-5">
              <label for="">Area (Select area radius Km for your services)</label><br>
              <ngx-slider [options]="options" formControlName="maxRadius" [(ngModel)]="userProfile.maxRadius">
              </ngx-slider>

              <div *ngIf="submitted && !userProfile.maxRadius" class="redErrorText pl-2">
                *Area Radius is required </div>
            </div>

            <!-- <div class="col-sm-12">
              <div class="custom-file">
                <img [src]="imgURL || '/assets/imgs/person.svg'"
                  style="width: 100px; height: 100px; border-radius: 50%;" alt="">
                <button class="btn btn-primary ml-2" (click)="SelectFiles0.click()"
                  style="border-radius:5px !important; height: 35px;">Select Profile Pic</button>
                <input #SelectFiles0 class="d-none" type="file" accept="image/*" (change)="onChangeFile($event)">
              </div>
            </div> -->

          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label for="my-textarea">About Me</label>
            <textarea id="my-textarea" class="form-control" formControlName="biography"
              [(ngModel)]="userProfile.biography" rows="17" placeholder="Enter here..."></textarea>
          </div>
        </div>
        <div class="col-sm-8 text-center my-3 pt-5">
          <button class="btn btn-primary" type="button" (click)="submitProfileData()">Submit</button>
        </div>
      </div>
    </form>
  </section>

</section>
