<app-navigation></app-navigation>
<section class="main-cont">
  <section class="section-1 page-title">
    <div class="row">
      <div class="col-sm-12">
        <h4>Privacy Policy</h4>
      </div>
    </div>
  </section>
  <section class="section-2">

    <div class="row">
        <div class="col-sm-12">
            <div class=Section1>

                <p class=Default align=center style='margin-top:10.0pt;margin-right:0in;
                margin-bottom:10.0pt;margin-left:0in;text-align:center;line-height:120%'><b><span
                lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>MATES
                RATES PRIVACY POLICY</span></b><b><span lang=NL style='font-size:12.0pt;
                line-height:120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></b></p>
                
                <p class=BodyA align=center style='text-align:center;line-height:120%'><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>Last updated:</span></b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'> 12 October 2021</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA align=center style='margin-left:14.2pt;text-align:center;
                line-height:120%;tab-stops:411.1pt'></p>
                
                <p class=BodyA align=center style='margin-left:14.2pt;text-align:center;
                line-height:120%;mso-pagination:none;tab-stops:411.1pt'><i><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>This Privacy Policy explains
                in detail how we collect, use and disclose your personal data when you use
                Mates Rates. Please read this document carefully before submitting any personal
                data to us. </span></i><i></i></p>
                
                <p class=BodyA align=center style='margin-left:14.2pt;text-align:center;
                line-height:120%;mso-pagination:none;tab-stops:411.1pt'><i><span lang=NL
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></i></p>
                
                <p class=Body align=center style='margin-left:14.2pt;text-align:center;
                line-height:120%;mso-pagination:none;tab-stops:411.1pt'></p>
                
                <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>1. GENERAL INFORMATION<span
                style='mso-tab-count:1'> </span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>1</span></p>
                
                <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>2. WHAT PERSONAL DATA DO WE COLLECT
                AND FOR WHAT PURPOSES DO WE USE IT? </span> <span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'> - 2</span></p>
                
                <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>3. HOW DO WE COMMUNICATE WITH YOU?<span
                style='mso-tab-count:1'> </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'> - 3</span></p>
                
                <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>4. HOW LONG DO WE KEEP YOUR PERSONAL
                DATA?<span style='mso-tab-count:1'>-  </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'> - 4</span></p>
                
                <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>5. HOW DO WE SHARE AND DISCLOSE YOUR
                PERSONAL DATA?</span> <span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'> - 4</span></p>
                
                <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>6. HOW DO WE PROTECT YOUR PERSONAL
                DATA?</span> <span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'> - 5</span></p>
                
                <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>7. WHAT RIGHTS DO YOU HAVE WITH REGARD
                TO YOUR PERSONAL DATA?</span> <span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'> - 6</span></p>
                
                <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>8. CONTACT </span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'> - 7</span></p>
                
                <p class=MsoNormal align=center style='margin-left:14.2pt;text-align:center;
                line-height:120%;mso-pagination:none;tab-stops:411.1pt'><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><i></i></span></p>
                
                <p class=BodyA style='line-height:120%;mso-pagination:widow-orphan lines-together;
                page-break-after:avoid'><b><i></i></b></p>
                
                <p class=Heading><a name="_Toc"></a><a name="_headingh.gjdgxs1"></a><span
                style='mso-bookmark:_Toc'><u><span lang=NL style='font-size:12.0pt;font-family:
                "Century Gothic","sans-serif";mso-ansi-language:NL'>1</span></u></span><span
                style='mso-bookmark:_Toc'><u><span lang=DE style='font-size:12.0pt;font-family:
                "Century Gothic","sans-serif";mso-ansi-language:DE'>. GENERAL INFORMATION</span></u></span><span
                style='mso-bookmark:_Toc'></span><span style='font-size:12.0pt;font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>1.1
                </span><b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>About the Privacy Policy. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>This Mates Rates Privacy Policy (the "</span><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>Privacy Policy</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>")
                governs the processing of personal data collected from natural persons ("<b>you</b>"
                and "<b>your</b>") through the </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>website
                </span><span lang=PT style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:PT'>https://matesrates.site</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>, the software application "Mates
                Rates", and the related services (collectively, the "<b>Platform</b>" or "<b>Mates
                Rates</b>")</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>. The Privacy Policy does not cover any third-party
                websites, applications, software, products or services that integrate with </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>Mates Rates</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> or
                are linked to from </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Mates
                Rates</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>. </span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>1.2
                </span><b><span lang=IT style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:IT'>Owner of Mates Rates</span></b><b><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>. </span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Mates
                Rates is owned and operated by Chris Mansour having an address at </span><span
                lang=IT style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                IT'>Epping </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>VIC
                3076, Australia, and the ABN number </span><span lang=IT style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:IT'>646 656 377</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> ("<b>we</b>,""</span><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>us</span></b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>,</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>" or "</span><b><span
                lang=FR style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                FR'>our</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>"). </span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>1.3
                </span><b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>Your consent. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Before you submit any personal data through the
                Platform, you are encouraged to read this Privacy Policy that is always
                available on the Platform to understand on what legal bases (other than your
                consent) we rely when handling your personal data. In some cases, if required
                by the applicable law, we may seek to obtain your informed consent for the
                processing of your personal data. For example, you consent may be necessary if:
                (i) we intend to collect other types of personal data that are not mentioned in
                this Privacy Policy; (ii) we would like to use your personal data for other
                purposes that are not specified in this Privacy Policy; or (iii) we would like
                to transfer your personal data to third parties that are not listed in this
                Privacy Policy. </span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>1.4
                </span><b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>Minors.</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> Mates Rates is not intended for use by children
                (i.e., persons who are minors in their country of residence, usually - below
                the age of 18). Therefore, we do not knowingly collect minors" </span><span
                lang=PT style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                PT'>personal data. If you, as a parent or a legal guardian of a child, become
                aware that the child has submitted his/her personal data to us, please contact
                us immediately. We will delete your child"s personal data from our systems
                without undue delay.<span style='mso-spacerun:yes'>" </span></span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>1.5
                </span><b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>Term and termination. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>This Privacy Policy enters into force on the effective
                date indicated at the top of the Privacy Policy and remains valid until
                terminated or updated by us.</span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>1.6
                </span><b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>Amendments.</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> The Privacy Policy may be changed from time to time
                to address the changes in laws, regulations, and industry standards. We encourage
                you to review our Privacy Policy to stay informed. For significant material
                changes in the Privacy Policy or, where required by the applicable law, we may
                seek your consent.</span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'></p>
                
                <p class=Heading><a name="_Toc1"></a><a name="_headingh.30j0zll1"></a><span
                style='mso-bookmark:_Toc1'><u><span lang=NL style='font-size:12.0pt;font-family:
                "Century Gothic","sans-serif";font-variant:small-caps;mso-ansi-language:NL'>2</span></u></span><span
                style='mso-bookmark:_Toc1'><u><span style='font-size:12.0pt;font-family:"Century Gothic","sans-serif";
                font-variant:small-caps'>. WHAT PERSONAL DATA DO WE COLLECT AND FOR WHAT
                PURPOSES DO WE USE IT? </span></u></span><span style='mso-bookmark:_Toc1'></span><span
                style='font-size:12.0pt;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>2.1
                </span><b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>Sources of personal data. </span></b><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>We obtain your personal data from the following
                categories of sources:</span></p>
                
                <p class=BodyA style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.25in;line-height:120%;mso-list:l8 level1 lfo2'><span
                lang=NL style='font-family:"Palatino","serif";mso-fareast-font-family:Palatino;
                mso-bidi-font-family:Palatino'><span style='mso-list:Ignore'>"<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>Directly from you. </span></b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>For example, if you
                submit certain personal data directly to us when registering your user account
                or contacting us;</span></p>
                
                <p class=BodyA style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.25in;line-height:120%;mso-list:l8 level1 lfo2'><span
                lang=NL style='font-family:"Palatino","serif";mso-fareast-font-family:Palatino;
                mso-bidi-font-family:Palatino'><span style='mso-list:Ignore'>"<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>Directly or
                indirectly through your activity on the Platform</span></b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>. When you use the Platform,
                we automatically collect technical information about your use of the Platform</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>; and</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'></span></p>
                
                <p class=BodyA style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.25in;line-height:120%;mso-list:l8 level1 lfo2'><span
                lang=NL style='font-family:"Palatino","serif";mso-fareast-font-family:Palatino;
                mso-bidi-font-family:Palatino'><span style='mso-list:Ignore'>"<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>From third parties. </span></b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>We may receive
                information about you from third parties to whom you have previously provided
                your personal data, if those third parties have a lawful basis for disclosing
                your personal data to us.</span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>2.2
                <b>Types of personal data. </b></span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>We respect data minimisation principles. Thus, we collect
                only a minimal amount of personal data through Mates Rates that is necessary to
                ensure the proper provision of the Platform as described below. We use your
                personal data for limited, specified and legitimate purposes explicitly
                mentioned in this Privacy Policy. We do not use your personal data for any
                purposes that are different from the purposes for which it was provided. </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>When
                processing personal d</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>a</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>ta, we make sure that we do so by relying on one of
                the available legal bases. You can find more information about the legal bases
                below.</span></p>
                
                <p class=BodyA style='margin-left:57.25pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.25in;line-height:120%;mso-list:l0 level2 lfo4'><span
                lang=NL style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
                "Times New Roman"'><span style='mso-list:Ignore'>"<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Your
                account. </span></b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>When
                you register your user account on, we collect your full name, email address,
                and occupation. When you update your user account, we collect your<span
                style='mso-spacerun:yes'>" </span>phone number, suburb, postcode, trade or
                profession, and profile picture.</span></p>
                
                <p class=BodyA style='margin-left:57.25pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.25in;line-height:120%;mso-list:l0 level2 lfo4'><span
                lang=NL style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
                "Times New Roman"'><span style='mso-list:Ignore'>"<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>We
                use the said information to register and maintain your user account, enable
                your access to </span><span lang=DE style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:DE'>Mates Rates</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>,
                feature your profile, provide you with the requested services, contact you, if
                necessary, and maintain our business records. The legal bases on which we rely
                are "<i>performing a contract with you</i>" and "<i>pursuing our legitimate
                business interests</i>" (i.e., operate, analyse, grow, and administer </span><span
                lang=DE style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                DE'>Mates Rates</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>).
                We store such data until you amend it or delete your user account. </span></p>
                
                <p class=BodyA style='margin-left:57.25pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.25in;line-height:120%;mso-list:l0 level2 lfo4'><span
                lang=NL style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
                "Times New Roman"'><span style='mso-list:Ignore'>"<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Jobs.
                </span></b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>When you post a job on Mates Rates or apply for a job, we collect any
                information that you decide to provide in your post (for example, information
                about you, your company, business activities, skills, education, and
                professional experience). We use such information to feature your post on the
                Platform, provide you with the requested services and maintain our business
                records. The legal bases on which we rely are "<i>performing a contract with
                you,</i>""<i>pursuing our legitimate business interests</i>" (i.e., operate and
                administer Mates Rates), and <i>"your consent"</i> (for optional personal
                data). We keep such data until you remove your posting. </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'></span></p>
                
                <p class=BodyA style='margin-left:57.25pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.25in;line-height:120%;mso-list:l0 level2 lfo4'><span
                lang=NL style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
                "Times New Roman"'><span style='mso-list:Ignore'>"<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>E</span></b><b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>nquiries.
                </span></b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>When you contact us by email, we collect your name, email address, and
                any information that you decide to include in your message. We use such data to
                respond to your inquiries. The legal bases on which we rely are "<i>pursuing
                our legitimate business interests</i>" (i.e., to grow and promote our business)
                and "<i>your consent</i>" (for optional personal data). </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>We store such data until you
                stop communicating with us. </span></p>
                
                <p class=BodyA style='margin-left:57.25pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.25in;line-height:120%;mso-list:l0 level2 lfo4'>"<b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Cookies. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>When you browse Mates Rates, we may collect your
                cookie-related data. For more information about our use of cookies, please
                refer to our <u>Cookie Policy</u>. The legal bases on which we rely are "<i>pursuing
                our legitimate business interests</i>" (i.e., to analyse and promote our
                business) and "<i>your consent</i>" (for non-essential cookies)</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>. We store such data
                until your cookies expire, you disable cookies, or you withdraw your consent,
                whichever earlier.</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>2.3</span><b><span
                lang=IT style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                IT'> Sensitive data. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>We do not collect or have access to any special
                categories of personal data from you, unless you decide, at your own
                discretion, to provide such data to us. Sensitive data is information that
                relates to your health, genetics, biometrics, religious and political beliefs,
                racial origins, membership of a professional or trade association, sex life, or
                sexual orientation.<span style='mso-spacerun:yes'>""" </span></span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>2.4
                </span><b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>Refusal to provide personal data.</span></b><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'> If you refuse to
                provide us with your personal data when we ask to, we may not be able to
                perform the requested operation and you may not be able to register your
                account, use the full functionality of Mates Rates or get our response. Please
                contact us immediately if you think that any personal data that we collect is
                excessive or not necessary for the intended purpose.<span
                style='mso-spacerun:yes'>" </span></span></p>
                
                <p class=BodyA style='line-height:120%;mso-pagination:widow-orphan lines-together;
                page-break-after:avoid'><b></b></p>
                
                <p class=Heading><a name="_Toc2"></a><a name="_headingh.3znysh71"></a><span
                style='mso-bookmark:_Toc2'><u><span lang=NL style='font-size:12.0pt;font-family:
                "Century Gothic","sans-serif";mso-ansi-language:NL'>3</span></u></span><span
                style='mso-bookmark:_Toc2'><u><span style='font-size:12.0pt;font-family:"Century Gothic","sans-serif"'>.
                HOW DO WE COMMUNICATE WITH YOU?</span></u></span><span style='mso-bookmark:
                _Toc2'></span><span style='font-size:12.0pt;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>3.1
                <b>Newsletters. </b>If we have your email address, w</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>e
                may, from time to time, send you a newsletter informing you </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>about the latest
                developments related to</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> Mates Rates and </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>our special offers</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>. You
                will receive ou</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>r
                newsletters in the following instances</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>:</span><u><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                text-transform:uppercase'></span></u></p>
                
                <p class=BodyBA style='margin-left:9.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l9 level1 lfo6'><span
                lang=NL style='mso-ansi-language:NL'><span style='mso-list:Ignore'>"<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>If w</span><span style='font-family:"Century Gothic","sans-serif"'>e
                receive your express (</span><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>"opt-in"</span><span style='font-family:"Century Gothic","sans-serif"'>)
                consent to receive marketing messages</span><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:NL'>; or</span></p>
                
                <p class=BodyBA style='margin-left:9.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l9 level1 lfo6'><span
                lang=NL style='mso-ansi-language:NL'><span style='mso-list:Ignore'>"<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>If you voluntarily subscribe for our newsletter; or</span></p>
                
                <p class=BodyBA style='margin-left:9.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l9 level1 lfo6'><span
                lang=NL style='mso-ansi-language:NL'><span style='mso-list:Ignore'>"<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>If w</span><span style='font-family:"Century Gothic","sans-serif"'>e decide
                to send you </span><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>information closely related to services already used by
                you.</span></p>
                
                <p class=BodyBA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif";
                text-transform:uppercase;mso-ansi-language:NL'>3</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:NL'>.2<b>
                Opting-out.</b></span><span style='font-family:"Century Gothic","sans-serif"'>
                You can opt-out from receiving </span><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>our commercial communication </span><span
                style='font-family:"Century Gothic","sans-serif"'>at any time free of charge by
                clicking on the "unsubscribe" link </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:NL'>included in our
                newsletters, adjusting the settings of your user account, or by </span><span
                style='font-family:"Century Gothic","sans-serif"'>contacting us directly.</span><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyBA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>3.3 <b>Tracking pixels. </b>The newsletters sent by us
                may contain tracking pixels that allow us to conduct analysis of our marketing
                campaigns. Tracking pixels allow us to see whether you opened the newsletter
                and what links you have clicked on. We use such information to conduct
                analytics and pursue our legitimate business interests.</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic";mso-ansi-language:NL'></span></p>
                
                <p class=BodyBA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>3.4 <b>Transactional notices. </b>If necessary, we will </span><span
                style='font-family:"Century Gothic","sans-serif"'>send you</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:NL'>
                important</span><span style='font-family:"Century Gothic","sans-serif"'>
                informational </span><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>messages</span><span style='font-family:"Century Gothic","sans-serif"'>,
                such as </span><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>confirmation receipts</span><span style='font-family:
                "Century Gothic","sans-serif"'>,technical emails, and other </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>administrative updates</span><span style='font-family:"Century Gothic","sans-serif"'>.
                Please note that such</span><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'> message</span><span style='font-family:"Century Gothic","sans-serif"'>s
                </span><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>are sent </span><span style='font-family:"Century Gothic","sans-serif"'>on
                an "if-needed" basis and they do not fall within the scope of </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>commercial </span><span style='font-family:"Century Gothic","sans-serif"'>communication
                that </span><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>may </span><span style='font-family:"Century Gothic","sans-serif"'>require
                your prior consent.</span><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'> You cannot opt-out from our transactional notices. </span><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='line-height:120%;mso-pagination:widow-orphan lines-together;
                page-break-after:avoid'><b></b></p>
                
                <p class=Heading><a name="_Toc3"></a><a name="_headingh.2et92p01"></a><span
                style='mso-bookmark:_Toc3'><u><span lang=NL style='font-size:12.0pt;font-family:
                "Century Gothic","sans-serif";mso-ansi-language:NL'>4</span></u></span><span
                style='mso-bookmark:_Toc3'><u><span lang=DE style='font-size:12.0pt;font-family:
                "Century Gothic","sans-serif";font-variant:small-caps;mso-ansi-language:DE'>.
                HOW LONG DO WE KEEP YOUR PERSONAL DATA?</span></u></span><span
                style='mso-bookmark:_Toc3'></span><span style='font-size:12.0pt;font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>4.1
                </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>W</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>e store your personal data inour systems only for as
                long as such personal data is required for the purposes described in this
                Privacy Policy or until you request us to delete your personal data, whichever
                comes first. After your personal data is no longer necessary for its primary
                purposes and we do not have another legal basis for storing it, we securely
                delete your personal data from our systems.</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>4.2
                </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>In certain cases, if we are required by law to store your personal data
                for a certain period of time, we will keep your personal data for the time
                period stipulated by the applicable law and securely delete it as soon as the
                required storage period expires.</span></p>
                
                <p class=BodyA style='line-height:120%;mso-pagination:widow-orphan lines-together;
                page-break-after:avoid'><b></b></p>
                
                <p class=Heading><a name="_Toc4"></a><a name="_headingh.tyjcwt1"></a><span
                style='mso-bookmark:_Toc4'><u><span lang=NL style='font-size:12.0pt;font-family:
                "Century Gothic","sans-serif";mso-ansi-language:NL'>5.</span></u></span><span
                style='mso-bookmark:_Toc4'><u><span style='font-size:12.0pt;font-family:"Century Gothic","sans-serif";
                font-variant:small-caps'> HOW DO WE SHARE AND DISCLOSE YOUR </span></u></span><span
                style='mso-bookmark:_Toc4'><u><span lang=NL style='font-size:12.0pt;font-family:
                "Century Gothic","sans-serif";font-variant:small-caps;mso-ansi-language:NL'>PERSONAL
                </span></u></span><span style='mso-bookmark:_Toc4'><u><span style='font-size:
                12.0pt;font-family:"Century Gothic","sans-serif";font-variant:small-caps'>DATA?</span></u></span><span
                style='mso-bookmark:_Toc4'></span><span style='font-size:12.0pt;font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>5.1
                </span><b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>Disclosure to data processors. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>From time to time, your personal data is disclosed to
                our service providers with whom we cooperate (our data processors). For
                example, we share your personal and non-personal data with entities that
                provide certain technical support services to us, such as hosting and email
                distribution services. We do not sell your personal data to third parties. The
                disclosure is limited to the situations when your personal data is required for
                the following purposes:</span></p>
                
                <p class=BodyA style='margin-left:9.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l6 level1 lfo8'><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Ensuring the proper operation of Mates Rates;</span></p>
                
                <p class=BodyA style='margin-left:9.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l6 level1 lfo8'><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Providing you with the requested information;</span></p>
                
                <p class=BodyA style='margin-left:9.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l6 level1 lfo8'><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Pursuing our legitimate business interests;</span></p>
                
                <p class=BodyA style='margin-left:9.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l6 level1 lfo8'><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Enforcing our rights, preventing fraud, and security
                purposes;</span></p>
                
                <p class=BodyA style='margin-left:9.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l6 level1 lfo8'><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Carrying out our contractual obligations; or </span></p>
                
                <p class=BodyA style='margin-left:9.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l6 level1 lfo8'><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>If you provide your prior consent to such a
                disclosure. </span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>5.2
                </span><b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>List of our data processors. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>We use a limited number of data processors. We choose
                them only if they agree to ensure an adequate level of protection of your
                personal data that is consistent with this Privacy Policy and the applicable
                data protection laws. The data processors that have access to your personal
                data are: </span></p>
                
                <p class=BodyA style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.25in;line-height:120%;mso-list:l2 level1 lfo10'>"<span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Our hosting service provider </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>[insert name]</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                located in </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>[insert
                country]</span>;</p>
                
                <p class=BodyA style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.25in;line-height:120%;mso-list:l2 level1 lfo10'>"<span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Our newsletter service provider </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>[insert name]</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                located in </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>[insert
                country]</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>;</span></p>
                
                <p class=BodyA style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.25in;line-height:120%;mso-list:l2 level1 lfo10'>"<span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Our analytics service provider </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>[insert name]</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                located in </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>[insert
                country]</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> located in </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>[insert country]</span><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>;</span></p>
                
                <p class=BodyA style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.25in;line-height:120%;mso-list:l2 level1 lfo10'>"<span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Our email service provider </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>[insert name]</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                located in </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>[insert
                country]</span>;</p>
                
                <p class=BodyA style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.25in;line-height:120%;mso-list:l2 level1 lfo10'>"<span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Our </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>_________</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                service provider </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>[insert
                name]</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> located in </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>[insert
                country]</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>; and</span></p>
                
                <p class=BodyA style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.25in;line-height:120%;mso-list:l2 level1 lfo10'><span
                lang=NL style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
                "Times New Roman"'><span style='mso-list:Ignore'>"<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Our
                independent contractors and consultants.</span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>5.3
                </span><b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>Legal requests.</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> If we are contacted by a public authority, we may
                need to disclose information about you to the extent necessary for pursuing a
                public interest objective, such as national security or law enforcement.</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>5.4
                </span><b><span lang=IT style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:IT'>Successors.</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> In case Mates Rates is sold partly or fully, we will
                provide your personal data to a purchaser or successor entity and request the
                successor to handle your personal data in line with this Privacy Policy. We
                will notify you of any changes of the data controller. </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>5.5 <b>Selling personal data. </b></span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>We do not sell your
                personal data to third parties.</span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>5.6
                <b>International transfers.</b> Some of our data processors listed above are
                located outside the country in which you reside. For example, if you reside in
                the European Economic Area (EEA), we may need to transfer your personal data to
                jurisdictions outside the EEA. In case it is necessary to make such a transfer,
                we will make sure that the jurisdiction in which the recipient third party is
                located guarantees an adequate level of protection for your personal data or we
                conclude a data processing agreement with the respective third party that
                ensures such protection. We will not transfer your personal data
                internationally if no appropriate level of protection can be granted</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>.</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'></span></p>
                
                <p class=Heading><a name="_Toc5"><u><span lang=NL style='font-size:12.0pt;
                font-family:"Century Gothic","sans-serif";mso-ansi-language:NL'>6</span></u></a><span
                style='mso-bookmark:_Toc5'><u><span style='font-size:12.0pt;font-family:"Century Gothic","sans-serif"'>.
                HOW DO WE PROTECT YOUR PERSONAL DATA?</span></u></span><span style='mso-bookmark:
                _Toc5'></span><span style='font-size:12.0pt;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>6.1
                <b>Our s</b></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>ecurity measures.</span></b><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'> We implement
                organisational and technical information security measures to protect your
                personal data from loss, misuse, unauthorised access, and disclosure. The
                security measures taken by us include access control</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>, s</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>ecured
                networks</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>,
                s</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>trong passwords</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>,
                and c</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>arefully selected data processors.</span><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>6.2
                </span><b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>Security breaches.</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> Although we put our best efforts to protect your
                personal data, given the nature of communications and information processing
                technology and the Internet, we cannot be liable for any unlawful destruction,
                loss, use, copying, modification, leakage, and falsification of your personal
                data that was caused by circumstances that are beyond our reasonable control.
                In case a serious breach occurs, we will take reasonable measures to mitigate
                the breach, as required by the applicable law. Our liability for any security
                breach will be limited to the highest extent permitted by the applicable law. </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='line-height:120%;mso-pagination:widow-orphan lines-together;
                page-break-after:avoid'><b><u></u></b></p>
                
                <p class=Heading><a name="_Toc6"><u><span lang=NL style='font-size:12.0pt;
                font-family:"Century Gothic","sans-serif";mso-ansi-language:NL'>7</span></u></a><span
                style='mso-bookmark:_Toc6'><u><span style='font-size:12.0pt;font-family:"Century Gothic","sans-serif"'>.
                WHAT RIGHTS DO YOU HAVE WITH REGARD TO YOUR PERSONAL DATA?</span></u></span><span
                style='mso-bookmark:_Toc6'></span><span style='font-size:12.0pt;font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>7.1
                </span><b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>The list of your rights. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>You have the right to control how your personal data
                is processed by us by exercising the rights listed below (unless, in very
                limited cases, the applicable law provides otherwise):</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='margin-left:39.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l6 level2 lfo8'><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Right of access: </span></b><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>you can get a copy of
                your personal data that we store in our systems and a list of purposes for
                which your personal data is processed;</span></p>
                
                <p class=BodyA style='margin-left:39.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l6 level2 lfo8'><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Right to rectification:</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> you
                can rectify inaccurate personal data that we hold about you;</span></p>
                
                <p class=BodyA style='margin-left:39.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l6 level2 lfo8'><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Right to erasure ("right to be forgotten"): </span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>you
                can ask us to erase your personal data from our systems;</span></p>
                
                <p class=BodyA style='margin-left:39.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l6 level2 lfo8'><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Right to restriction: </span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>you
                can ask us to restrict </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>processing of your personal data;</span></p>
                
                <p class=BodyA style='margin-left:39.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l6 level2 lfo8'><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Right to data portability: </span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>you
                can ask us to provide you with a copy of your personal data in a structured,
                commonly used and machine-readable format and move that personal data to
                another processor;</span></p>
                
                <p class=BodyA style='margin-left:39.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l6 level2 lfo8'><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Right to object: </span></b><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>you can ask us to stop
                processing your personal data;</span></p>
                
                <p class=BodyA style='margin-left:39.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l6 level2 lfo8'><span
                lang=NL style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
                "Times New Roman"'></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Right
                to withdraw consent: </span></b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>you
                have the right to w</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>ithdraw your consent, if you have provided one</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>; or</span></p>
                
                <p class=BodyA style='margin-left:39.45pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-9.45pt;line-height:120%;mso-list:l6 level2 lfo8'><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Right to complaint: </span></b><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>you can submit your
                complaint regarding </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>our
                processing of </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>your personal data.</span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>7.2
                </span><b><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>How to exercise your rights?</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> If you would like to exercise any of your rights,
                please contact us by email at m</span><span lang=DE style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:DE'>atesratesworks@gmail.com</span><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>or by post (you can find
                our postal address at the end of this Privacy Policy) and explain your request
                in detail. In order to verify the legitimacy of your request, we may ask you to
                provide us with an identifying piece of information that allows us to identify
                you in our system. We will answer your request within a reasonable time frame
                but no later than 30 days.</span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span lang=NL style='font-family:"Century Gothic","sans-serif"'>7.3</span><b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                Complaints.</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> If you would like to launch a complaint about the way
                in which we process your personal data, we kindly ask you to contact us first
                and express your concerns. If we receive your complaint, we will investigate it
                and provide you with our response as soon as possible. If you are not satisfied
                with the outcome of your complaint, you have the right to lodge a complaint
                with your local data protection authority.</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>7.4 <b>Non-discrimination.</b> We do not discriminate
                against you if you decide to exercise your rights. </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>It means that </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>we
                will not</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                (i) deny any goods and services, (ii) charge you different prices, (iii) deny
                any discounts or benefits, (iv) impose penalties, or (v) provide you with lower
                quality services.</span></p>
                
                <p class=BodyA style='line-height:120%;mso-pagination:widow-orphan lines-together;
                page-break-after:avoid'><b><u><span lang=PT style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:PT'></span></u></b></p>
                
                <p class=Heading><a name="_Toc7"><u><span lang=NL style='font-size:12.0pt;
                font-family:"Century Gothic","sans-serif";mso-ansi-language:NL'>8</span></u></a><span
                style='mso-bookmark:_Toc7'><u><span lang=DE style='font-size:12.0pt;font-family:
                "Century Gothic","sans-serif";mso-ansi-language:DE'>. CONTACT</span></u></span><span
                style='mso-bookmark:_Toc7'></span><span style='font-size:12.0pt;font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%;mso-pagination:none;tab-stops:14.2pt 411.1pt'><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>If you have any questions
                about this Privacy Policy or </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>our data protection practices, please contact us by:</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%;mso-pagination:none;tab-stops:14.2pt 411.1pt'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Email:</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>matesratesworks@gmail.com</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%;mso-pagination:none;tab-stops:14.2pt 411.1pt'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Postal
                address: </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Mates Rates, Epping VIC 3076, Australia</span><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic";mso-ansi-language:EN-US'></span></p>
                
                <p class=BodyBB align=center style='margin-bottom:2.0pt;text-align:center;
                mso-pagination:none;tab-stops:14.2pt 411.1pt'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic";mso-ansi-language:NL'></span></p>
                
                <p class=BodyBB align=center style='margin-bottom:2.0pt;text-align:center;
                mso-pagination:none;tab-stops:14.2pt 411.1pt'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:NL'>[END]</span></p>
                
                </div>
        </div>
    </div>
    
</section>
</section>
