import { Component, OnInit } from '@angular/core';
import { iNotification } from '../model/all-classes';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  

  public notificationsList: iNotification[];
  public notifyCount = 0;

  constructor(public service: DbDataApiService,
    public router: Router) {
    this.service.notifyDataCheck.subscribe((value) => {
      this.ngOnInit()
    });
  }

  ngOnInit(): void {

    this.service.pageUrl = this.router.routerState.snapshot.url

    this.notificationsList = [];
    if (this.service.notificationsList && this.service.notificationsList.length) {
      this.notificationsList = JSON.parse(JSON.stringify(this.service.notificationsList));
    }
    this.notifyCount = this.notificationsList.length;
  }

  updateNotificationCount() {
    this.service.openNotifyMd = !this.service.openNotifyMd
    if (this.notifyCount > this.service.myNotifyCounts && this.service.openNotifyMd) {
      this.service.myNotifyCounts = this.notifyCount;

      var data = {
        myJobQoutes: this.service.myJobQoutes,
        myNotifyCounts: this.service.myNotifyCounts,
      };
      localStorage.setItem('mateRatesCounts' + this.service.userData.uid, JSON.stringify(data));
    }
  }


  checkNotificationType(notifyData) {
    this.service.openNotifyMd = false;

    var category = notifyData.category;
    if (!category) {
      category = notifyData.title
    }


    if (category == 'Message Received') {
      this.service.checkMsgUser(notifyData.senderId)
      this.service.chatDataCheck.next();
    } else {
      this.service.getFirebaseData();
      if (category == 'New Job Posted') {
        this.router.navigate(['/home']);
      } else if (category == 'New Review') {
        this.router.navigate(['/profile']);
      } else if (category == 'New Quote Received') {
        this.router.navigate(['/quotes-posted/' + notifyData.jobId]);
      }
    }

  }

}
