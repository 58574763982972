<app-navigation></app-navigation>
<ngx-loading [show]="service.loading"> </ngx-loading>
<section class="main-cont">
  <section class="section-1">
    <div class="row">
      <div class="col-sm-10">
        <h4>Post Job</h4>
      </div>
      <div class="col-sm-2 d-none d-sm-block">
        <button class="btn btn-primary" (click)="submitJobData()" type="button">Post Job</button>
      </div>
    </div>
  </section>
  <section class="section-2 job-posting">
    <form [formGroup]="jobForm">
      <div class="row jobForm">

        <div class="col-sm-8 pl-0">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="my-input">Category</label>
                <ng-select [items]="categoryList" formControlName="categoryId" [(ngModel)]="jobData.categoryId"
                  placeholder="Select Category" bindLabel="categoryName" bindValue="categoryId" [multiple]="false"
                  (change)="getSubCategories()">
                </ng-select>
    
                <div *ngIf="submitted && !jobData.categoryId" class="redErrorText pl-2">
                  *Category is required </div>
    
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="my-input">Sub-Category</label>
                <ng-select [items]="subCategoryList" formControlName="subCategoryId" [(ngModel)]="jobData.subCategoryId"
                  placeholder="Select Sub-Category" bindLabel="categoryName" bindValue="subCategoryId" [multiple]="false">
                </ng-select>
    
                <div *ngIf="submitted && !jobData.subCategoryId" class="redErrorText pl-2">
                  *Sub Category is required </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="my-input">Location</label>
                <ngxgeo-autocomplete id="locationSerach" [userSettings]="userSettings1" (componentCallback)="autoCompleteCallback1($event) ">
                </ngxgeo-autocomplete>
                <div *ngIf="submitted && !jobData.location" class="redErrorText pl-2 pt-5">
                  *Job Location is required </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="my-input">Price <span class="text-muted">(optional)</span></label>
                <input id="my-input" class="form-control" type="price" formControlName="price" [(ngModel)]="jobData.price"
                  placeholder="Enter Price">
                <!-- <div *ngIf="submitted && f.price.errors" class="redErrorText pl-2">
                  <div *ngIf="f.price.errors.required">*Price is required</div>
                  <div *ngIf="f.price.errors.min || f.price.errors.max">*Price should be between 1 to 10000000</div>
                </div> -->
              </div>
            </div>
            <div class="col-sm-12">
              <label for="my-input">Add your images <span class="text-muted">(optional)</span></label>
              <div class="add-images">
                <div class="row">
                  <div class="col-sm-4 my-1">
                    <div class="card" [class.p-1]="imgUrlList && imgUrlList.length>=1">
                      <img [src]="imgUrlList[0] || '/assets/imgs/card-image.svg'"
                        [class.imgDimensions]="imgUrlList && imgUrlList.length" (click)="SelectFiles0.click()"
                        style="max-width: 120px; max-height: 120px;" alt="">
                      <input #SelectFiles0 class="d-none" type="file" accept="image/*" (change)="onChangeFile($event , 0)">
                    </div>
    
                  </div>
                  <div class="col-sm-4 my-1">
                    <div class="card" [class.p-1]="imgUrlList && imgUrlList.length>=2">
                      <img [src]="imgUrlList[1] || '/assets/imgs/card-image.svg'"
                        [class.imgDimensions]="imgUrlList && imgUrlList.length>1" (click)="SelectFiles1.click()"
                        style="max-width: 120px; max-height: 120px;" alt="">
                      <input #SelectFiles1 class="d-none" type="file" accept="image/*" (change)="onChangeFile($event, 1)">
                    </div>
                  </div>
                  <div class="col-sm-4 my-1">
                    <div class="card" [class.p-1]="imgUrlList && imgUrlList.length>=3">
                      <img [src]="imgUrlList[2] || '/assets/imgs/card-image.svg'"
                        [class.imgDimensions]="imgUrlList && imgUrlList.length>2" (click)="SelectFiles2.click()"
                        style="max-width: 120px; max-height: 120px;" alt="">
                      <input #SelectFiles2 class="d-none" type="file" accept="image/*" (change)="onChangeFile($event, 2)">
                    </div>
                  </div>
    
                  <div class="col-sm-4 my-1">
                    <div class="card px-3 py-1">
                      <img class="imgDimensions" src="/assets/imgs/job-card-image.png"
                        (click)="pickFileFromAssets('/assets/imgs/job-card-image.png', 0)"
                        style="max-width: 120px; height: 120px;" alt="">
    
                    </div>
    
                  </div>
                  <div class="col-sm-4 my-1">
                    <div class="card px-3 py-1">
                      <img class="imgDimensions" src="/assets/imgs/photographer.png"
                        (click)="pickFileFromAssets('/assets/imgs/photographer.png', 1)"
                        style="max-width: 120px; height: 120px;" alt="">
    
                    </div>
                  </div>
                  <div class="col-sm-4 my-1">
                    <div class="card px-3 py-1">
                      <img class="imgDimensions" src="/assets/imgs/carpenter.png"
                        (click)="pickFileFromAssets('/assets/imgs/carpenter.png' , 2)"
                        style="max-width: 120px; height: 120px;" alt="">
                    </div>
                  </div>
    
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="my-textarea">Description</label>
                <textarea id="my-textarea" class="form-control" formControlName="description"
                  [(ngModel)]="jobData.description" rows="21" placeholder="Enter job Description"></textarea>

                <div *ngIf="submitted && f.description.errors" class="redErrorText pl-2">
                  <div *ngIf="f.description.errors.required">*Description is required</div>

                </div>
              </div>
            </div>
          </div>
        </div>

      <div class="col-sm-12 d-block d-sm-none">
        <button class="btn btn-primary mt-4 w-100" (click)="submitJobData()" type="button">Post Job</button>
      </div>
      </div>
    </form>
  </section>

</section>
