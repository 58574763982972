<app-navigation></app-navigation>
<ngx-loading [show]="service.loading"> </ngx-loading>
<section class="main-cont">
  <section class="section-1">
    <div class="row">
      <div class="col-sm-12 px-0">
        <div class="row pb-5">
          <div class="col-sm-1 profile-img">
            <img [src]="userProfile.profileUrl || '/assets/imgs/person.svg'">
          </div>
          <div class="col-sm-9 pl-4">
            <h4>{{userProfile.name | titlecase}} Profile</h4>
            <div class="rating">
              <i class="fas fa-star"></i>
              <span *ngIf="!userProfile.rating">Not rated yet</span>
              <span *ngIf="userProfile.rating">{{userProfile.rating | number:'1.2-2'}}</span>

            </div>
          </div>
          <div class="col-sm-2 text-right">
            <!-- <button class="btn btn-primary" type="button" [disabled]="!userProfile || !userProfile.name"
            (click)="service.checkMsgUser(userProfile.uid)">Message</button> -->
            <button class="btn btn-primary" type="button" *ngIf="service.userData && service.userData.uid"
            (click)="service.checkMsgUser(userProfile.uid)">Message</button>
            <button class="btn btn-primary" type="button" *ngIf="!service.userData || !service.userData.uid" data-toggle="modal" data-target="#loginModal">Message</button>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="section-2">
    <div class="row">
      <div class="col-sm-6">
        <div class="card about">
          <h4>About {{userProfile.name | titlecase}}</h4>
          <p> {{userProfile.biography}} </p>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="reviews-cont">
          <div class="reviews-header">
            <h4 class="primary">Reviews</h4>
            <!-- <button class="btn btn-primary" type="button" [disabled]="!userProfile || !userProfile.name"
              data-toggle="modal" data-target="#exampleModal">Rate</button> -->
              <button class="btn btn-primary" type="button" *ngIf="service.userData && service.userData.uid"
              data-toggle="modal" data-target="#exampleModal">Rate</button>
              <button class="btn btn-primary" type="button" *ngIf="!service.userData || !service.userData.uid" data-toggle="modal" data-target="#loginModal">Rate</button>
          </div>
          <div class="reviews-body">
            <h4 *ngIf="!reviewsList || !reviewsList.length" class="text-danger">No Reviews to show.</h4>
            <div class="card review" *ngFor="let review of reviewsList; let i=index;">
              <p> {{review.comment}} </p>
              <div class="d-flex justify-content-between">
                <p class="primary">{{review.ratedUserName}}</p>
                <div>
                  <i class="far fa-star" *ngIf="review.rating <0.3"></i>
                  <i class="fas fa-star" *ngIf="review.rating >=1"></i>
                  <i class="far fa-star" *ngIf="review.rating <1.3"></i>
                  <i class="fas fa-star" *ngIf="review.rating >=1.8"></i>
                  <i class="fas fa-star-half-alt" *ngIf="review.rating >=1.3 && review.rating <1.8"></i>
                  <i class="far fa-star" *ngIf="review.rating <2.3"></i>
                  <i class="fas fa-star" *ngIf="review.rating >=2.8"></i>
                  <i class="fas fa-star-half-alt" *ngIf="review.rating >=2.3 && review.rating <2.8"></i>
                  <i class="far fa-star" *ngIf="review.rating <3.3"></i>
                  <i class="fas fa-star" *ngIf="review.rating >=3.8"></i>
                  <i class="fas fa-star-half-alt" *ngIf="review.rating >=3.3 && review.rating <3.8"></i>
                  <i class="far fa-star" *ngIf="review.rating <4.3"></i>
                  <i class="fas fa-star" *ngIf="review.rating >=4.8"></i>
                  <i class="fas fa-star-half-alt" *ngIf="review.rating >=4.3 && review.rating <4.8"></i>


                  ({{review.rating | number:'1.2-2'}})
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>

</section>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" id="closeReviewMd" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img [src]=" userProfile.profileUrl || '/assets/imgs/person.svg'" alt="">
        <h4>{{userProfile.name | titlecase}}</h4>
        <p class="mb-0">Rate this user</p>

        <i class="far fa-star" *ngIf="reviewData.rating <1" (click)="reviewData.rating=1"></i>
        <i class="fas fa-star" *ngIf="reviewData.rating >=1" (click)="reviewData.rating=1"></i>
        <i class="far fa-star" *ngIf="reviewData.rating <2" (click)="reviewData.rating=2"></i>
        <i class="fas fa-star" *ngIf="reviewData.rating >=2" (click)="reviewData.rating=2"></i>
        <i class="far fa-star" *ngIf="reviewData.rating <3" (click)="reviewData.rating=3"></i>
        <i class="fas fa-star" *ngIf="reviewData.rating >=3" (click)="reviewData.rating=3"></i>
        <i class="far fa-star" *ngIf="reviewData.rating <4" (click)="reviewData.rating=4"></i>
        <i class="fas fa-star" *ngIf="reviewData.rating >=4" (click)="reviewData.rating=4"></i>
        <i class="far fa-star" *ngIf="reviewData.rating <5" (click)="reviewData.rating=5"></i>
        <i class="fas fa-star" *ngIf="reviewData.rating >=5" (click)="reviewData.rating=5"></i>

        <div *ngIf="submitted && reviewData.rating<1" class="redErrorText pl-2">
          *Minimum User rating should be 1.
        </div>

        <div class="form-group">
          <label for="my-textarea">Leave a Comment</label>
          <textarea id="my-textarea" [(ngModel)]="reviewData.comment" class="form-control" name="" rows="3"
            placeholder="Write Comment"></textarea>
          <div *ngIf="submitted && (!reviewData.comment || reviewData.comment.length<10 || 
          reviewData.comment.length>300)" class="redErrorText pl-2">
            *Comment is required and length should be between 10 to 300 characters.
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="submitReviewData()">Submit</button>
      </div>
    </div>
  </div>
</div>
