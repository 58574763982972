import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { LandingComponent } from './landing/landing.component';
import { HomeComponent } from './home/home.component';
import { JobPostingComponent } from './job-posting/job-posting.component';
import { ProfileComponent } from './profile/profile.component';
import { JobsPostedComponent } from './jobs-posted/jobs-posted.component';
import { QuotesPostedComponent } from './quotes-posted/quotes-posted.component';
import { SearchProvidersComponent } from './search-providers/search-providers.component';
import { MessagesComponent } from './messages/messages.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PostQuoteComponent } from './post-quote/post-quote.component';
import { QuotesSubmittedComponent } from './quotes-submitted/quotes-submitted.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';



const routes: Routes = [
  { path: 'signup', component: SignupComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: 'landing', component: LandingComponent },
  { path: 'home', component: HomeComponent },
  { path: 'job-posting', component: JobPostingComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'jobs-posted', component: JobsPostedComponent },
  { path: 'quotes-posted/:id', component: QuotesPostedComponent },
  { path: 'search-providers', component: SearchProvidersComponent },
  { path: 'messages', component: MessagesComponent },
  { path: 'user-profile/:id', component: UserProfileComponent },
  { path: 'post-quote/:id', component: PostQuoteComponent },
  { path: 'quotes-submitted', component: QuotesSubmittedComponent },
  { path: 'edit-profile', component: EditProfileComponent },
  { path: 'terms&conditions', component: TermsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'cookie-policy', component: CookiePolicyComponent },
  
  // { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
