<app-navigation></app-navigation>
<ngx-loading [show]="service.loading"> </ngx-loading>
<section class="main-cont">
  <section class="section-1 page-title">
    <div class="row">
      <div class="col-sm-6">
        <h4>Job's Qoutes</h4>
      </div>
      <div class="col-sm-6">
        <!-- <button class="btn btn-outline-secondary" type="button">Previous</button>
        <button class="btn btn-outline-secondary" type="button">Next</button> -->
        <!-- <input type="search" class="form-control" [(ngModel)]="term"
          placeholder="Search Qoute by User, Category, Sub Category"> -->
      </div>
    </div>
  </section>
  <section class="section-2">
    <div class="row">

      <div class="col-md-4 col-sm-6 job-card-left">
        <div class="card item-quote">
          <!-- <div class="like-star">
            <i class="far fa-star"></i>
          </div> -->
          <div class="rating">
            <i class="fas fa-star"></i>
            <span *ngIf="!service.userData.rating">'N/A'</span>
            {{service.userData.rating | number:'1.2-2'}}
          </div>
          <img [src]="jobData.fileUrls[0] || '/assets/imgs/card-user.svg'" class="card-img-top">
          <div class="card-body">
            <h5 class="card-title">{{service.userData.name | titlecase}}</h5>
            <p class="mb-2"><small class="primary">{{jobData.categoryName | titlecase}},
                {{jobData.subCategoryName | titlecase}}</small></p>
            <h5 class="primary bold"><small>Price: </small> ${{jobData.price}}</h5>
            <p>{{jobData.description}}</p>
            <p>{{jobData.location}}</p>

            <div class="d-flex">
              <button class="btn btn-success" type="button" *ngIf="jobData.status!='Ended'">Live</button>
              <button class="btn btn-danger" type="button" *ngIf="jobData.status=='Ended'">Ended</button>
              <!-- <button class="btn btn-outline-secondary" type="button"  *ngIf="jobData.status!='Ended'"
                         data-toggle="modal" data-target="#quoteModal">END</button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-sm-6 quotes pr-0">
        <div class="row">
          <div class="col-sm-12">
            <h4>Received Qoutes</h4>
          </div>
          <div class="col-sm-6" *ngFor="let qoute of qoutesList ; let i=index;">
            <div class="card">
             
              <div class="row">
                <div class="col-sm-8 px-2 d-flex justify-content-start" style="align-items: center;">
                  <img class="pointer" [src]="qoute.userProfile" [routerLink]="['/user-profile',qoute.userId]" alt="">
                 <div>
                  <p class="pointer ml-2" [routerLink]="['/user-profile',qoute.userId]">{{qoute.userName | titlecase}}</p>
                  <p class="pointer ml-2 f-12 primary">{{qoute.categoryName | titlecase}}, {{qoute.subCategoryName | titlecase}}</p>
                 </div>
                </div>
                <div class="col-sm-4 px-2">
                  <p>${{qoute.price}}</p>
                  <button class="btn btn-primary mt-0" type="button" (click)="service.checkMsgUser(qoute.userId)">Message</button>
                </div>
                <div class="offset-sm-2 col-sm-10 px-2">
                  <p style="line-height: 1.2;"><small>{{qoute.description}}</small></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" *ngIf="!qoutesList || !qoutesList.length">
            No Qoute is recieved yet.
          </div>
        </div>
      </div>

    </div>
  </section>
</section>


<!--update Qoute Status Modal-->
<!-- <div class="modal fade" id="qouteStatusModal" tabindex="-1" aria-labelledby="jobStatusModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title primary" id="deleteModalLabel">Update Qoute Status</h5>
        </div>
        <div class="modal-body text-center" *ngIf="!status">
          <img src="/assets/imgs/CrossIcon.png" height="100px;">
          <p>Are you sure? D you want to Reject this Qoute by {{selectedQoute.userName | titlecase}} ?</p>
        </div>
        <div class="modal-body text-center" *ngIf="status">
            <img src="/assets/imgs/greenCheck.png" height="100px;">
            <p>Are you sure? D you want to Accept this Qoute by {{selectedQoute.userName | titlecase}} ?</p>
          </div>
  
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" id="closeQouteStatusMd" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-success" *ngIf="status" (click)="updateQouteStatus('Accepted')">Accept</button>
          <button type="button" class="btn btn-danger" *ngIf="!status" (click)="updateQouteStatus('Rejected')">Reject</button>
        </div>
      </div>
    </div>
  </div> -->
