
import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public user: any = {
    email: '',
    password: ''
  }

  constructor(public service: DbDataApiService,
    private toastr: ToastrService,
    private zone: NgZone,
    public router: Router) {
    if(service.userData && service.userData.uid) {
      this.router.navigate(['/home']);
    }
   }

  ngOnInit(): void {
  }


  loginAccount() {
    var self = this;
    self.service.loading = true;
    firebase.auth().signInWithEmailAndPassword(this.user.email, this.user.password)
      .then((user) => {
        if (user) {
          self.getUserData( user.user.uid );
        } else {
          self.toastr.error('user not exist', '', { timeOut: 4000 })
          self.service.loading = false;
        }
      })
      .catch((e) => {
        self.service.loading = false;
        self.toastr.error(e.message, '', { timeOut: 4000 })
      });
  }

  getUserData( uid ) {
    var self = this;
    firebase.database().ref().child('Users/' + uid)
      .once('value', (snapshot) => {
        self.zone.run(() => {
          var user = snapshot.val();
          self.service.loading = false;
          if (user && user.active) {
            self.service.setActiveUser(user);
            self.user = {
              email: '',
              password: ''
            }
            var btn=document.getElementById('closeLoginMd');
            if(btn){
              btn.click();
            }
            self.toastr.success('You have logged in successfully', '', { timeOut: 4000 })
            self.router.navigate(['/home']);
          } else {
            self.toastr.error('This account is blocked by Admin', '', { timeOut: 4000 })
          }
        });
      }).catch((e) => {
        self.service.loading = false;
        self.toastr.error(e.message, '', { timeOut: 4000 });

      })
  }

}
