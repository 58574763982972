import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  public userEmail: string = '';

  constructor(public service: DbDataApiService,
    public toastr: ToastrService,
    public router: Router) {
    if (service.userData && service.userData.uid) {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit(): void {
  }

  sendPasswordResetLink() {
    var self = this;
    self.service.loading = true;
    firebase.auth().sendPasswordResetEmail(self.userEmail)
      .then(() => {
        self.service.loading = false;
        self.toastr.success('Password reset link sent to your email: ' + self.userEmail + '. Please check your email and follow instructions. ', '', { timeOut: 10000 });
        self.userEmail = '';
        self.router.navigate(['/home']);
      }).catch((e) => {
        self.service.loading = false;
        self.toastr.error(e.message, '', { timeOut: 4000 });
      });
  }

}
