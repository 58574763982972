<app-navigation></app-navigation>
<ngx-loading [show]="service.loading"> </ngx-loading>
<section class="main-cont">
  <section class="section-1">
    <div class="row">
      <div class="col-sm-10">
        <h4>Post Quote</h4>
      </div>
      <!-- <div class="col-sm-2">
        <button class="btn btn-primary" type="button" (click)="submitQouteData()">Post Quote</button>
      </div> -->
    </div>
  </section>
  <section class="section-2 quote-posting">
    <form [formGroup]="qouteForm">
      <div class="row">
        <div class="col-md-6 col-sm-9">
          <div class="row">
            <!-- <div class="col-sm-12">
              <div class="form-group">
                <label for="my-input">Phone <span class="pl-2"><small>(Only visible once quote
                      accepted)</small></span></label>
                <input id="my-input" class="form-control" type="tel" formControlName="phoneNumber"
                  [(ngModel)]="qouteData.phoneNumber" placeholder="Enter Phone">
                <div *ngIf="submitted && f.phoneNumber.errors" class="redErrorText pl-2">
                  <div *ngIf="f.phoneNumber.errors.required">*Description is required</div>
                  <div *ngIf="f.phoneNumber.errors.minlength || f.phoneNumber.errors.maxlength">
                    *Phone Number length should be between 7 to 16 digits</div>
                </div>
              </div>
            </div> -->
            <div class="col-sm-12 px-0">
              <div class="form-group">
                <label for="my-input">Price</label>
                <input id="my-input" class="form-control" type="number" formControlName="price"
                  [(ngModel)]="qouteData.price" placeholder="Enter Price">

                <div *ngIf="submitted && f.price.errors" class="redErrorText pl-2">
                  <div *ngIf="f.price.errors.required">*Price is required</div>
                  <div *ngIf="f.price.errors.min || f.price.errors.max">*Price should be between 1 to 10000000</div>
                </div>
              </div>
              <div class="form-group">
                <label for="my-textarea">Description</label>
                <textarea id="my-textarea" class="form-control" rows="10" formControlName="description"
                  [(ngModel)]="qouteData.description" placeholder="Enter your Description"></textarea>
                <div *ngIf="submitted && f.description.errors" class="redErrorText pl-2">
                  <div *ngIf="f.description.errors.required">*Description is required</div>
                  <div *ngIf="f.description.errors.minlength || f.description.errors.maxlength">
                    *Description length should be between 3 to 200 characters</div>
                </div>
              </div>

              <div class="mt-3">
                <button class="btn btn-primary" type="button" (click)="submitQouteData()">Post Quote</button>
              </div>
            </div>
            <!-- <div class="col-sm-12">
              <label for="my-input">Add your images</label>
              <div class="add-images">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="card" [class.p-3]="imgUrlList && imgUrlList.length>=1">
                      <img [src]="imgUrlList[0] || '/assets/imgs/card-image.svg'"
                        [class.imgDimensions]="imgUrlList && imgUrlList.length" (click)="SelectFiles0.click()"
                        style="max-width: 120px; max-height: 120px;" alt="">
                      <input #SelectFiles0 class="d-none" type="file" accept="image/*"
                        (change)="onChangeFile($event , 0)">
                    </div>
                    <div *ngIf="submitted && (!imgUrlList || !imgUrlList.length)" class="redErrorText pl-2">
                      *Minimum 1 file is required.
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="card" [class.p-3]="imgUrlList && imgUrlList.length>=2">
                      <img [src]="imgUrlList[1] || '/assets/imgs/card-image.svg'"
                        [class.imgDimensions]="imgUrlList && imgUrlList.length>1" (click)="SelectFiles1.click()"
                        style="max-width: 120px; max-height: 120px;" alt="">
                      <input #SelectFiles1 class="d-none" type="file" accept="image/*"
                        (change)="onChangeFile($event, 1)">
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="card" [class.p-1]="imgUrlList && imgUrlList.length>=3">
                      <img [src]="imgUrlList[2] || '/assets/imgs/card-image.svg'"
                        [class.imgDimensions]="imgUrlList && imgUrlList.length>2" (click)="SelectFiles2.click()"
                        style="max-width: 120px; max-height: 120px;" alt="">
                      <input #SelectFiles2 class="d-none" type="file" accept="image/*"
                        (change)="onChangeFile($event, 2)">
                    </div>
                  </div>
                  <div class="col-sm-4">
                                        <div class="card">
                                            <img src="/assets/imgs/card-image.svg" alt="">
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="card">
                                            <img src="/assets/imgs/card-image.svg" alt="">
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="card">
                                            <img src="/assets/imgs/card-image.svg" alt="">
                                        </div>
                                    </div> 
                 <div class="col-sm-4">
                    <div class="card">
                      <img src="/assets/imgs/photography.svg" alt="">
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="card">
                      <img src="/assets/imgs/photography.svg" alt="">
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="card">
                      <img src="/assets/imgs/photography.svg" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- <div class="col-sm-4">
          <div class="form-group">
            <label for="my-textarea">Description</label>
            <textarea id="my-textarea" class="form-control" rows="17" formControlName="description"
              [(ngModel)]="qouteData.description" placeholder="Enter your Description"></textarea>
            <div *ngIf="submitted && f.description.errors" class="redErrorText pl-2">
              <div *ngIf="f.description.errors.required">*Description is required</div>
              <div *ngIf="f.description.errors.minlength || f.description.errors.maxlength">
                *Description length should be between 3 to 100 characters</div>
            </div>
          </div>
        </div> -->
      </div>
    </form>
  </section>

</section>