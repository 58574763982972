export class iUser {
    active: boolean;
    biography: string;
    categoryId: string;
    categoryName: string;
    deviceTokens: string[];
    email: string;
    location: string;
    latitude: number;
    longitude: number;
    maxRadius: number;
    name: string;
    onlySeeker: boolean;
    phoneNo: string;
    profileUrl: string;
    rating: number;
    subCategoryId: string;
    subCategoryName: string;
    timeStamp: number;
    uid: string;
}

export class iCategory {
    active: boolean;
    categoryId: string;
    categoryImage: string;
    categoryName: string;
    timeStamp: number;
    subCategories: iSubCategory[];
}

export class iSubCategory {
    active: boolean;
    categoryId: string;
    categoryName: string;
    parentCategoryName: string;
    subCategoryId: string;
    timeStamp: number;
}

export class iJob {
    active: boolean;
    categoryId: string;
    categoryName: string;
    description: string;
    fileUrls: string[];
    jobId: string
    latitude: number;
    location: string;
    longitude: number;
    onlySeeker: boolean;
    price: number;
    qouteCount: number;
    rating: number;
    status: string;
    subCategoryId: string;
    subCategoryName: string;
    timeStamp: number;
    userId: string;
    userName: string;
    userPhone: string;
}

export class iQoute {
    active: boolean;
    categoryName: string;
    subCategoryName: string
    description: string;
    fileUrls: string[];
    jobId: string;
    jobDescription: string;
    jobPrice: number;
    jobLocation: string;
    phoneNumber: string;
    price: string;
    qouteId: string;
    status: string;
    timeStamp: number;
    userId: string;
    userName: string;
    userProfile: string;
}

export class iReview {
    uid: string;
    ratedUser: string;
    ratedUserName: string;
    comment: string;
    reviewId: string;
    rating: number;
    timeStamp: number;
}

export class iNotification {
    message: string;
    notificationId: string;
    receiverId: string[];
    senderId: string;
    timeStamp: number;
    title: string;
}

export class iChat {
    uid:string;
    messages: iMessage[];
    userName:string;
    userProfile:string;
    chatId:string;
    deletedChat:number;
    subCategoryName: string;
}

export class iMessage {
    text:string;
    testMessage:string;
    timeStamp: number;
    uid:string;
}