<app-navigation></app-navigation>
<section class="main-cont">
  <section class="section-1 page-title">
    <div class="row">
      <div class="col-sm-12">
        <h4>Cookie Policy</h4>
      </div>
    </div>
  </section>
  <section class="section-2">

    <div class="row pb-5">
      <div class="col-sm-12">
        <div class=Section1>

          <p class=Default align=center style='margin-top:0in;text-align:center;
                line-height:120%'><b><span lang=NL style='font-size:14.0pt;line-height:120%;
                font-family:"Century Gothic","sans-serif"'>MATES RATES COOKIE POLICY</span></b><b><span lang=NL style='font-size:14.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></b></p>

          <p class=BodyA align=center style='text-align:center;line-height:120%'><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>Last updated:</span></b><span lang=NL
              style='font-family:"Century Gothic","sans-serif"'> 12 October 2021</span><span lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>

          <p class=BodyA style='line-height:120%;mso-pagination:widow-orphan lines-together;
                page-break-after:avoid'><b><u><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></u></b></p>

          <p class=BodyA align=center style='text-align:center;line-height:120%'><i><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>We
                use cookies on</span></i><i><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                Mates Rates. Below, you can find detailed </span></i><i><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>information about the
                types of our cookies, their purposes, and your right to manage cookies.</span></i><i><span lang=NL
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></i></p>

          <p class=BodyA align=center style='text-align:center;line-height:120%'><i><span lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></i></p>

          <p class=Body align=center style='text-align:center;line-height:120%'></p>

          <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>1. WHO IS RESPONSIBLE FOR COOKIES ON
              MATES RATES?</span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'> - 1</span></p>

          <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>2. WHAT SHOULD YOU KNOW ABOUT COOKIES?<span
                style='mso-tab-count:1'> </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'> - 1</span></p>

          <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>3. WHAT COOKIES DO WE USE?<span style='mso-tab-count:1'>
              </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'> - 1</span></p>

          <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>4. COOKIE CONSENT<span style='mso-tab-count:1'>
              </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'> - 2</span></p>

          <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>5. HOW TO DISABLE COOKIES?</span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'> - 3</span></p>

          <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>6. GOOGLE ANALYTICS<span
                style='mso-tab-count:1'></span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'> - 3</span></p>

          <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>7. INTEREST-BASED ADVERTISING<span
                style='mso-tab-count:1'></span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'> - 3</span></p>

          <p class=MsoToc1><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>8. CONTACT</span>
            <span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'> - 4</span>
          </p>

          <p class=MsoNormal align=center style='text-align:center;line-height:120%'><span style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><i></i></span></p>

          <p class=Heading style='line-height:120%'><u><span style='font-size:12.0pt;
                line-height:120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></u></p>

          <p class=Heading style='line-height:120%'><a name="_Toc"><u><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>1. WHO IS RESPONSIBLE FOR COOKIES ON MATES RATES?</span></u></a><span
              style='mso-bookmark:_Toc'></span><u><span lang=NL style='font-size:12.0pt;
                line-height:120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic";mso-ansi-language:NL'></span></u></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The data controller that is responsible for the
              processing of personal data through </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>the website </span><span class=Hyperlink0><span lang=PT><a
                  href="https://matesrates.site">https://matesrates.site</a></span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>,
                the software application "Mates Rates", and the related services (collectively,
                "<b>Mates Rates</b>"), including placing first-party cookies,</span></span><span class=None><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> isChris Mansourhaving a</span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>n address at </span></span><span class=None><span
                lang=IT style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:IT'>Epping </span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>VIC 3076, Australia, and the
                ABN number </span></span><span class=None><span lang=IT style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:IT'>646 656 377</span></span><span class=None><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>(</span></span><span dir=RTL></span><span
              class=None><span lang=AR-SA dir=RTL style='font-family:
                "Arial Unicode MS","sans-serif";mso-ansi-language:AR-SA'><span dir=RTL></span>"</span></span><span
              class=None><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>we</span></b></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>," </span></span><span dir=RTL></span><span
              class=None><span lang=AR-SA dir=RTL style='font-family:
                "Arial Unicode MS","sans-serif";mso-ansi-language:AR-SA'><span dir=RTL></span>"</span></span><span
              class=None><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>us</span></b></span><span
              class=None><span lang=NL style='font-family:"Century Gothic","sans-serif"'>," or
              </span></span><span dir=RTL></span><span class=None><span lang=AR-SA dir=RTL
                style='font-family:"Arial Unicode MS","sans-serif";mso-ansi-language:AR-SA'><span
                  dir=RTL></span>"</span></span><span class=None><b><span lang=FR
                  style='font-family:"Century Gothic","sans-serif";mso-ansi-language:FR'>our</span></b></span><span
              class=None><span lang=NL style='font-family:"Century Gothic","sans-serif"'>").</span></span><span
              class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span class=None><u><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></u></span></p>

          <p class=Heading style='line-height:120%'><a name="_Toc1"><span class=None><u><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>2. WHAT SHOULD YOU KNOW ABOUT COOKIES?</span></u></span></a><span
              style='mso-bookmark:_Toc1'></span><span class=None><u><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:NL'></span></u></span></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span class=None><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>A cookie is a small piece of data typically consisting
                of letters and numbers. When you visit a website, th</span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>at</span></span><span class=None><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> website may send a cookie to your browser. Subsequently, the browser
                may store the cookie on your computer or mobile device for </span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>some
              </span></span><span class=None><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>time</span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'> (cookie expiration date
                depends on its type)</span></span><span class=None><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>. Cookies are </span></span><span
              class=None><span lang=NL style='font-family:"Century Gothic","sans-serif"'>used</span></span><span
              class=None><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> to </span></span><span class=None><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>recognise </span></span><span class=None><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>your
                device and collect certain information about your use of website</span></span><span class=None><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>s</span></span><span class=None><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>. Thus, over time, cookies allow websites to </span></span><span dir=RTL></span><span
              class=None><span lang=AR-SA dir=RTL style='font-family:
                "Arial Unicode MS","sans-serif";mso-ansi-language:AR-SA'><span dir=RTL></span>"</span></span><span
              class=None><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>remember</span></span><span class=None><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>" </span></span><span class=None><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>your
                actions and preferences. There are several types of cookies, namely, (i)
                persistent cookies, which remain valid until deleted by you, (ii) cookies that
                remain valid until their expiration date, and (iii) session cookies that are
                stored on a web browser and remain valid until the moment the browser is
                closed. Cookies may also be (i) first-party cookies (set by the </span></span><span class=None><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>original
              </span></span><span class=None><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>website itself) and (ii) third-party cookies (placed
                by third-party websites).</span></span><span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=Heading style='line-height:120%'><a name="_Toc2"><span class=None><u><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>3. WHAT COOKIES DO WE USE?</span></u></span></a><span
              style='mso-bookmark:_Toc2'></span><span class=None><u><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:NL'></span></u></span></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span class=None><span lang=DE style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:DE'>We</span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'> may use </span></span><span class=None><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>different types of cookies on</span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'> Mates Rates,</span></span><span class=None><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> including: </span></span><span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=BodyA style='margin-left:33.15pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-15.15pt;line-height:120%;mso-list:l3 level1 lfo2'>"<span class=None><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Essential </span></b></span><span class=None><b><span lang=NL
                  style='font-family:"Century Gothic","sans-serif"'>technical </span></b></span><span
              class=None><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>cookies </span></b></span><span class=None><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>that
                are </span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>strictly
                necessary </span></span><span class=None><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>to ensure the correct functioning ofMates Ratesand
                provide the services requested by you; </span></span></p>

          <p class=BodyA style='margin-left:33.15pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-15.15pt;line-height:120%;mso-list:l3 level1 lfo2'>"<span class=None><b><span lang=NL
                  style='font-family:"Century Gothic","sans-serif"'>Preference
                  cookies</span></b></span><span class=None><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'> that allow </span></span><span class=None><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Mates
                Rates</span></span><span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                to "remember" your settings; </span></span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'></span></p>

          <p class=BodyA style='margin-left:33.15pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-15.15pt;line-height:120%;mso-list:l3 level1 lfo2'>"<span class=None><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Marketing cookies</span></b></span><span class=None><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> that
                allow us to create, implement, and examine our marketing campaigns. Such cookies
                allow us to reach the right customers, analyse the productivity of our
                marketing campaigns, and offer you personalised advertisement; and</span></span><span
              style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'></span></p>

          <p class=BodyA style='margin-left:33.15pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-15.15pt;line-height:120%;mso-list:l3 level1 lfo2'>"<span class=None><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Statistics cookies</span></b></span><span class=None><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> that
                allow us to generate statistical reports about how you </span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>use </span></span><span class=None><span lang=PT
                style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:PT'>Mates Rates.</span></span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'></span></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span class=None><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Below, you can find a list of cookies that we use on
                Mates Rates</span></span><span class=None><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'> at present</span></span><span class=None><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>,
                including their purpose and expiration time:</span></span><span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0 width=620 style='margin-left:27.0pt;background:#CADFFF;border-collapse:collapse;
                 mso-table-layout-alt:fixed;border:none;mso-border-alt:solid white 1.0pt;
                 mso-yfti-tbllook:1184;mso-padding-alt:0in 5.4pt 0in 5.4pt;mso-border-insideh:
                 1.0pt solid white;mso-border-insidev:1.0pt solid white'>
            <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:17.0pt'>
              <td width=620 colspan=4 style='width:464.9pt;border:solid black 1.0pt;
                  mso-border-alt:solid black .5pt;background:#D9D9D9;padding:4.0pt 4.0pt 4.0pt 4.0pt;
                  height:17.0pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Essential technical cookies</span></b></span></p>
              </td>
            </tr>
            <tr style='mso-yfti-irow:1;height:29.0pt'>
              <td width=99 style='width:73.95pt;border:solid black 1.0pt;border-top:none;
                  mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:29.0pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Name</span></b></span></p>
              </td>
              <td width=152 style='width:114.05pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:29.0pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Provider</span></b></span><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-fareast-font-family:
                  "Times New Roman"'></span></b></span></p>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>(location)</span></b></span></p>
              </td>
              <td width=107 style='width:80.5pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:29.0pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Expiration</span></b></span></p>
              </td>
              <td width=262 style='width:196.4pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:29.0pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Purpose</span></b></span></p>
              </td>
            </tr>
            <tr style='mso-yfti-irow:2;height:16.0pt'>
              <td width=99 style='width:73.95pt;border:solid black 1.0pt;border-top:none;
                  mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:transparent;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=152 style='width:114.05pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:transparent;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=107 style='width:80.5pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:transparent;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=262 style='width:196.4pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:transparent;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
            </tr>
            <tr style='mso-yfti-irow:3;height:15.0pt'>
              <td width=620 colspan=4 style='width:464.9pt;border:solid black 1.0pt;
                  border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#D9D9D9;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:15.0pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span lang=NL style='font-size:9.0pt;font-family:"Times New Roman","serif";
                  mso-bidi-font-family:"Helvetica Neue";mso-ansi-language:NL'>Preference</span></b></span><span
                    class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";
                  mso-bidi-font-family:"Helvetica Neue"'> cookies</span></b></span></p>
              </td>
            </tr>
            <tr style='mso-yfti-irow:4;height:.3in'>
              <td width=99 style='width:73.95pt;border:solid black 1.0pt;border-top:none;
                  mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:.3in'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Name</span></b></span></p>
              </td>
              <td width=152 style='width:114.05pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:.3in'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Provider</span></b></span><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-fareast-font-family:
                  "Times New Roman"'></span></b></span></p>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>(location)</span></b></span></p>
              </td>
              <td width=107 style='width:80.5pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:.3in'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Expiration</span></b></span></p>
              </td>
              <td width=262 style='width:196.4pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:.3in'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Purpose</span></b></span></p>
              </td>
            </tr>
            <tr style='mso-yfti-irow:5;height:16.0pt'>
              <td width=99 style='width:73.95pt;border:solid black 1.0pt;border-top:none;
                  mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#E6EFFF;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=152 style='width:114.05pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#E6EFFF;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=107 style='width:80.5pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#E6EFFF;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=262 style='width:196.4pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#E6EFFF;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
            </tr>
            <tr style='mso-yfti-irow:6;height:15.05pt'>
              <td width=620 colspan=4 style='width:464.9pt;border:solid black 1.0pt;
                  border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#D9D9D9;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:15.05pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Marketing cookies</span></b></span></p>
              </td>
            </tr>
            <tr style='mso-yfti-irow:7;height:27.05pt'>
              <td width=99 style='width:73.95pt;border:solid black 1.0pt;border-top:none;
                  mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:27.05pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Name</span></b></span></p>
              </td>
              <td width=152 style='width:114.05pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:27.05pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Provider</span></b></span><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-fareast-font-family:
                  "Times New Roman"'></span></b></span></p>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>(location)</span></b></span></p>
              </td>
              <td width=107 style='width:80.5pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:27.05pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Expiration</span></b></span></p>
              </td>
              <td width=262 style='width:196.4pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:27.05pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Purpose</span></b></span></p>
              </td>
            </tr>
            <tr style='mso-yfti-irow:8;height:16.0pt'>
              <td width=99 style='width:73.95pt;border:solid black 1.0pt;border-top:none;
                  mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#FEFFFE;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=152 style='width:114.05pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#FEFFFE;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=107 style='width:80.5pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#FEFFFE;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=262 style='width:196.4pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#FEFFFE;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
            </tr>
            <tr style='mso-yfti-irow:9;height:17.6pt'>
              <td width=620 colspan=4 style='width:464.9pt;border:solid black 1.0pt;
                  border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#D9D9D9;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:17.6pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Statistics cookies</span></b></span></p>
              </td>
            </tr>
            <tr style='mso-yfti-irow:10;height:29.0pt'>
              <td width=99 style='width:73.95pt;border:solid black 1.0pt;border-top:none;
                  mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:29.0pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Name</span></b></span></p>
              </td>
              <td width=152 style='width:114.05pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:29.0pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Provider</span></b></span><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-fareast-font-family:
                  "Times New Roman"'></span></b></span></p>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>(location)</span></b></span></p>
              </td>
              <td width=107 style='width:80.5pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:29.0pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Expiration</span></b></span></p>
              </td>
              <td width=262 style='width:196.4pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:29.0pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Purpose</span></b></span></p>
              </td>
            </tr>
            <tr style='mso-yfti-irow:11;height:16.0pt'>
              <td width=99 style='width:73.95pt;border:solid black 1.0pt;border-top:none;
                  mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#FEFFFE;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=152 style='width:114.05pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#FEFFFE;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=107 style='width:80.5pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#FEFFFE;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=262 style='width:196.4pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#FEFFFE;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
            </tr>
            <tr style='mso-yfti-irow:12;height:13.0pt'>
              <td width=620 colspan=4 style='width:464.9pt;border:solid black 1.0pt;
                  border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#D9D9D9;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:13.0pt'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span lang=NL style='font-size:9.0pt;font-family:"Times New Roman","serif";
                  mso-bidi-font-family:"Helvetica Neue";mso-ansi-language:NL'>Unclassified</span></b></span><span
                    class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";
                  mso-bidi-font-family:"Helvetica Neue"'> cookies</span></b></span></p>
              </td>
            </tr>
            <tr style='mso-yfti-irow:13;height:.3in'>
              <td width=99 style='width:73.95pt;border:solid black 1.0pt;border-top:none;
                  mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:.3in'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Name</span></b></span></p>
              </td>
              <td width=152 style='width:114.05pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:.3in'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Provider</span></b></span><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-fareast-font-family:
                  "Times New Roman"'></span></b></span></p>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>(location)</span></b></span></p>
              </td>
              <td width=107 style='width:80.5pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:.3in'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Expiration</span></b></span></p>
              </td>
              <td width=262 style='width:196.4pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#F2F2F2;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:.3in'>
                <p class=BodyAA align=center style='text-align:center'><span class=None><b><span style='font-size:9.0pt;font-family:"Times New Roman","serif";mso-bidi-font-family:
                  "Helvetica Neue"'>Purpose</span></b></span></p>
              </td>
            </tr>
            <tr style='mso-yfti-irow:14;mso-yfti-lastrow:yes;height:16.0pt'>
              <td width=99 style='width:73.95pt;border:solid black 1.0pt;border-top:none;
                  mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#FEFFFE;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=152 style='width:114.05pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#FEFFFE;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=107 style='width:80.5pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#FEFFFE;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
              <td width=262 style='width:196.4pt;border-top:none;border-left:none;
                  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
                  solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
                  background:#FEFFFE;padding:4.0pt 4.0pt 4.0pt 4.0pt;height:16.0pt'>
                <p class=MsoNormal></p>
              </td>
            </tr>
          </table>

          <p class=BodyA style='margin-left:.3in;text-indent:-.3in;mso-pagination:none'><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=BodyA style='margin-left:16.2pt;text-indent:-16.2pt;mso-pagination:
                none'><span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=BodyA style='margin-left:.15in;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.15in;mso-pagination:none'><span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span class=None><i><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></i></span></p>

          <p class=Heading style='line-height:120%'><a name="_Toc3"><span class=None><u><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>4. COOKIE CONSENT</span></u></span></a><span
              style='mso-bookmark:_Toc3'></span><span class=None><u><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:NL'></span></u></span></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span class=None><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>When you visit Mates Rates, we </span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>may</span></span><span class=None><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> ask you to provide us with your consent to our use of all cookies via a
                cookie consent banner. If you do not provide your </span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>opt-in
              </span></span><span class=None><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>consent, we will not serve you our non-essential
                cookies. Please note that we may not be able to provide you with the best
                possible user experience</span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'> on </span></span><span class=None><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>Mates Rates if not all cookies are enabled.</span></span><span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=Heading style='line-height:120%'><a name="_Toc4"><span class=None><u><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>5. HOW TO DISABLE COOKIES?</span></u></span></a><span
              style='mso-bookmark:_Toc4'></span><span class=None><u><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:NL'></span></u></span></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span class=None><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>When we ask you to provide your consent to our use of
                non-essential cookies, you have the freedom not to provide such consent. If you
                would like to refuse our use of non-essential cookies later, you can do it at
                any time by declining cookies in your browser or device. For more information,
                you can consult the cookie management instructions of your browser:</span></span><span class=None><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic";mso-ansi-language:EN-US'></span></span></p>

          <p class=BodyA style='margin-left:33.95pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-16.5pt;line-height:120%;mso-list:l1 level1 lfo4'><span lang=IT style='font-family:"Helvetica","sans-serif";mso-fareast-font-family:
                Helvetica;mso-bidi-font-family:Helvetica;mso-ansi-language:IT'></span><span class=None><b><span lang=IT
                  style='font-family:"Century Gothic","sans-serif";mso-ansi-language:IT'>Apple
                  Safari: </span></b></span><span class=Hyperlink1><a
                href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac"><span
                  style='font-family:"Century Gothic","sans-serif"'>https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac</span></a></span><span
              lang=IT style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                IT'></span></p>

          <p class=BodyA style='margin-left:33.95pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-16.5pt;line-height:120%;mso-list:l1 level1 lfo4'><span lang=DE style='font-family:"Helvetica","sans-serif";mso-fareast-font-family:
                Helvetica;mso-bidi-font-family:Helvetica;mso-ansi-language:DE'></span><span class=None><b><span lang=DE
                  style='font-family:"Century Gothic","sans-serif";mso-ansi-language:DE'>Google
                  Chrome: </span></b></span><span class=Hyperlink1><a
                href="https://support.google.com/chrome/answer/95647"><span style='font-family:
                "Century Gothic","sans-serif"'>https://support.google.com/chrome/answer/95647</span></a></span><span
              lang=DE style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                DE'></span></p>

          <p class=BodyA style='margin-left:33.95pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-16.5pt;line-height:120%;mso-list:l1 level1 lfo4'><span style='font-family:"Helvetica","sans-serif";mso-fareast-font-family:Helvetica;
                mso-bidi-font-family:Helvetica;mso-ansi-language:EN-US'></span><span class=None><b><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>Firefox: </span></b></span><span
              class=Hyperlink1><a
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"><span
                  style='font-family:"Century Gothic","sans-serif"'>https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</span></a></span><span
              style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'></span></p>

          <p class=BodyA style='margin-left:33.95pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-16.5pt;line-height:120%;mso-list:l1 level1 lfo4'><span lang=NL style='font-family:"Helvetica","sans-serif";mso-fareast-font-family:
                Helvetica;mso-bidi-font-family:Helvetica'></span><span
              class=None><b><span lang=NL
                  style='font-family:"Century Gothic","sans-serif"'>Opera: </span></b></span><span class=Hyperlink1><a
                href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/"><span
                  style='font-family:"Century Gothic","sans-serif"'>https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/</span></a></span><span
              lang=NL style='font-family:"Century Gothic","sans-serif"'></span></p>

          <p class=BodyA style='margin-left:33.95pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-16.5pt;line-height:120%;mso-list:l1 level1 lfo4'><span lang=IT style='font-family:"Helvetica","sans-serif";mso-fareast-font-family:
                Helvetica;mso-bidi-font-family:Helvetica;mso-ansi-language:IT'></span><span class=None><b><span lang=IT
                  style='font-family:"Century Gothic","sans-serif";mso-ansi-language:IT'>Microsoft
                  Edge: </span></b></span><span class=Hyperlink1><a
                href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"><span
                  style='font-family:"Century Gothic","sans-serif"'>https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy</span></a></span><span
              lang=IT style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                IT'></span></p>

          <p class=BodyAA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span class=None><span style='font-size:12.0pt;line-height:
                120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=Heading style='line-height:120%'><a name="_Toc5"><span class=None><u><span
                    style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>6.
                    GOOGLE </span></u></span></a><span style='mso-bookmark:_Toc5'><span class=None><u><span lang=NL
                    style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>ANALYTICS</span></u></span></span><span style='mso-bookmark:
                _Toc5'></span><span class=None><u><span style='font-size:12.0pt;line-height:
                120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></u></span></p>

          <p class=BodyAA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span class=None><span style='font-size:12.0pt;line-height:
                120%;font-family:"Century Gothic","sans-serif"'>We use Google Analytics, a web
                analytics service provided by Google LLC,</span></span><span class=None><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'> registered at </span></span><span class=None><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>1600
                Amphitheatre Parkway, Mountain View, CA 94043, USA (</span></span><span dir=RTL></span><span
              class=None><span lang=AR-SA dir=RTL style='font-size:12.0pt;
                line-height:120%;mso-ansi-language:AR-SA'><span dir=RTL></span>"</span></span><span class=None><span
                lang=NL style='font-size:12.0pt;line-height:120%;font-family:
                "Century Gothic","sans-serif";mso-ansi-language:NL'>Google"</span></span><span class=None><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>).
                Google generates statistical information by means of cookies and creates
                reports about your use </span></span><span class=None><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>of </span></span><span class=None><span style='font-size:
                12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>Mates Rates.
                The cookies served by Google are anonymous first-party cookies that do not allow
                us to identify you in any manner. The information generated by cookies will be
                transmitted to and stored by Google on servers in the United States. To ensure
                your privacy, your IP address will be anonymised and Google will not combine
                your IP address with other information Google holds about you. Thus, Google
                will not be able to identify you. In certain cases (e.g., when required by law
                or when third parties conduct services on behalf of Google), Google may
                transfer the information to third parties. For more information about Google
                Analytics" privacy practices, please visit </span></span><span class=Hyperlink2><span
                style='font-size:12.0pt;line-height:120%'><a
                  href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a></span></span><span
              class=None><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:
                "Century Gothic","sans-serif";mso-ansi-language:NL'>. </span></span><span class=None><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>If
                you would like to opt out from Google Analytics, you can do so by installing a
                Google Analytics opt-out browser add-on available at </span></span><span class=Hyperlink2><span
                style='font-size:12.0pt;line-height:120%'><a
                  href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a></span></span><span
              class=None><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:
                "Century Gothic","sans-serif";mso-ansi-language:NL'>.</span></span><span class=None><span style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=BodyAA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span class=None><b><u><span style='font-size:12.0pt;
                line-height:120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></u></b></span></p>

          <p class=Heading style='line-height:120%'><a name="_Toc6"><span class=None><u><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>7. INTEREST-BASED ADVERTISING </span></u></span></a><span
              style='mso-bookmark:_Toc6'></span><span class=None><u><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:NL'></span></u></span></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span class=None><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>You may encounter targeted interest-based advertising
                based on your use of Mates Rates</span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'> and other websites on the
                Internet. Where necessary, we will seek your consent. </span></span><span class=None><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>You can control how such advertising is shown to you or opt-out from
                targeted advertising by consulting the guide powered by the Digital Advertising
                Alliance available at </span></span><span class=Hyperlink3>https://youradchoices.com</span><span
              class=None><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>. For more information on opting-out from advertising features on your
                device, please visit </span></span><span class=Hyperlink3><span
                style='mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'><a
                  href="https://www.networkadvertising.org"><span
                    style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>https://www.networkadvertising.org</span></a></span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'> .</span></span><span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%;mso-pagination:none;tab-stops:14.2pt 411.1pt'><span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=Heading style='line-height:120%'><a name="_Toc7"><span class=None><u><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>8. CONTACT</span></u></span></a><span style='mso-bookmark:
                _Toc7'></span><span class=None><u><span lang=NL style='font-size:12.0pt;
                line-height:120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic";mso-ansi-language:NL'></span></u></span></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%;mso-pagination:none;tab-stops:14.2pt 411.1pt'><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>If
                you have any questions</span></span><span class=None><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'> about th</span></span><span class=None><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>is
                cookie policy our</span></span><span class=None><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> data protection practices</span></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>,
                please contact </span></span><span class=None><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>us by using the following contact details:</span></span><span class=None><span
                lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%;mso-pagination:none;tab-stops:14.2pt 411.1pt'><span class=None><b><span lang=NL
                  style='font-family:"Century Gothic","sans-serif"'>Email:
                </span></b></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>m</span></span><span class=None><span lang=DE style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:DE'>atesratesworks@gmail.com</span></span><span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=BodyA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%;mso-pagination:none;tab-stops:14.2pt 411.1pt'><span class=None><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Postal address:</span></b></span><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>Mates Rates, Epping
                VIC 3076, Australia</span></span><span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></span></p>

          <p class=BodyA
            style='margin-left:28.35pt;text-align:justify;text-justify:inter-ideograph;
                line-height:120%;tab-stops:35.4pt 70.8pt 106.2pt 141.6pt 177.0pt 2.95in 247.8pt 283.2pt 318.6pt 354.0pt 389.4pt 5.9in 456.6pt'>
            <span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-font-kerning:.5pt'></span></span></p>

          <p class=BodyA
            style='margin-left:28.35pt;text-align:justify;text-justify:inter-ideograph;
                line-height:120%;tab-stops:35.4pt 70.8pt 106.2pt 141.6pt 177.0pt 2.95in 247.8pt 283.2pt 318.6pt 354.0pt 389.4pt 5.9in 456.6pt'>
            <span class=None><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-font-kerning:.5pt'></span></span></p>

          <p class=BodyA align=center style='text-align:center;line-height:120%;
                mso-pagination:none;tab-stops:14.2pt 411.1pt'><span class=None><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>[END]</span></span></p>

        </div>
      </div>
    </div>

  </section>
</section>
