import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { iJob, iQoute } from '../model/all-classes';

@Component({
  selector: 'app-quotes-posted',
  templateUrl: './quotes-posted.component.html',
  styleUrls: ['./quotes-posted.component.scss']
})
export class QuotesPostedComponent implements OnInit {

  public jobData: iJob;
  public qoutesList: iQoute[];
  // public searchFields: any = ['userName', 'price'];
  // public term:string = '';
  public status: boolean = false;

  constructor(public service: DbDataApiService,
    public router: Router,
    public route: ActivatedRoute,
    private toastr: ToastrService,
    public zone: NgZone) {
    this.jobData = new iJob();
  }

  ngOnInit(): void {
    this.service.openNotifyMd = false;

    if (!this.jobData || !this.jobData.jobId) {
      this.jobData = new iJob();
      if (this.route.snapshot.params['id']) {
        this.jobData.jobId = this.route.snapshot.params['id'];
      } else {
        this.router.navigate(['/search-providers']);
      }
    }

    this.getJobData()
    this.service.jobDataCheck.subscribe((value) => {
      this.getJobData()
    });

    this.getJobQoutesData()
    this.service.qouteDataCheck.subscribe((value) => {
      this.getJobQoutesData()
    });

  }

  public getJobData() {
    if (this.service.jobsList && this.service.jobsList.length) {
      var uIndex = this.service.jobsList.findIndex(obj => obj.jobId == this.jobData.jobId);
      if (uIndex >= 0) {
        this.jobData = JSON.parse(JSON.stringify(this.service.jobsList[uIndex]));
        if (this.service.myOldJobs && this.service.myOldJobs.length) {
          var oIndex = this.service.myOldJobs.findIndex(obj => obj.jobId == this.jobData.jobId);
          if (oIndex >= 0 && this.service.myOldJobs[oIndex].qouteCount != this.jobData.qouteCount) {
            this.service.myOldJobs[oIndex].qouteCount = this.jobData.qouteCount;
            localStorage.setItem('mateRatesmyJobs', JSON.stringify(this.service.myOldJobs));
          }
        }
      }
    }

  }

  public getJobQoutesData() {
    var self = this;

    self.qoutesList = [];
    if (this.service.qoutesList && this.service.qoutesList.length) {
      for (var i = 0; i < self.service.qoutesList.length; i++) {
        if (self.service.qoutesList[i].jobId == self.jobData.jobId) {
          self.qoutesList.push(JSON.parse(JSON.stringify(self.service.qoutesList[i])));
        }
      }
    }
  }


  // updateQouteStatus(status) {
  //   var self = this;
  //   self.service.loading = true;
  //   var updateData: any = {};
  //   updateData['Quotes/' + self.selectedQoute.qouteId + '/status'] = status;

  //   firebase.database().ref().update(updateData).then(() => {

  //     var index = self.service.qoutesList.findIndex(x => x.qouteId == self.selectedQoute.qouteId);
  //     if (index >= 0) {
  //       self.service.qoutesList[index].status = status;
  //     } var index2 = self.qoutesList.findIndex(x => x.qouteId == self.selectedQoute.qouteId);
  //     if (index2 >= 0) {
  //       self.qoutesList[index2].status = status;
  //     }
  //     self.selectedQoute = new iQoute();
  //     document.getElementById('closeQouteStatusMd').click();
  //     self.service.loading = false;
  //     self.toastr.success('Qoute Status updated Successfully.', '', { timeOut: 2500 });
  //   }).catch((error) => {
  //     self.service.loading = false;
  //     self.toastr.error('Qoute status is not updated, Kindly Try Again!', '', { timeOut: 2500 });
  //   })

  // }

}
