import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
/*ng-select */
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/*range slider */
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing.component';
import { HomeComponent } from './home/home.component';
import { NavigationComponent } from './navigation/navigation.component';
import { JobPostingComponent } from './job-posting/job-posting.component';
import { ProfileComponent } from './profile/profile.component';
import { JobsPostedComponent } from './jobs-posted/jobs-posted.component';
import { QuotesPostedComponent } from './quotes-posted/quotes-posted.component';
import { SearchProvidersComponent } from './search-providers/search-providers.component';
import { MessagesComponent } from './messages/messages.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PostQuoteComponent } from './post-quote/post-quote.component';
import { QuotesSubmittedComponent } from './quotes-submitted/quotes-submitted.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';

import { SearchPipe } from './search.pipe';
import  firebase from 'firebase';
import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxGeoautocompleteModule } from 'ngx-geoautocomplete';
import { HttpClientModule } from '@angular/common/http';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';

var firebaseConfig = {
  apiKey: "AIzaSyApUpUsLr508PpyJDzQ2CS-RVau3St51Mk",
  authDomain: "matesrates-dff00.firebaseapp.com",
  databaseURL: "https://matesrates-dff00.firebaseio.com",
  projectId: "matesrates-dff00",
  storageBucket: "matesrates-dff00.appspot.com",
  messagingSenderId: "361667732290",
  appId: "1:361667732290:web:100026b9c1d43c8bed9edb",
  measurementId: "G-BGMW2EKG0F"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LoginComponent,
    LandingComponent,
    HomeComponent,
    NavigationComponent,
    JobPostingComponent,
    ProfileComponent,
    JobsPostedComponent,
    QuotesPostedComponent,
    SearchProvidersComponent,
    MessagesComponent,
    UserProfileComponent,
    PostQuoteComponent,
    QuotesSubmittedComponent,
    ForgetPasswordComponent,
    EditProfileComponent,
    SearchPipe,
    TermsComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    /*ng-select */
    NgSelectModule,FormsModule,
    /*range slider */
    NgxSliderModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({
      fullScreenBackdrop:true, backdropBorderRadius:'3px'
    }),
    NgxGeoautocompleteModule.forRoot(),
    ToastNoAnimationModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
