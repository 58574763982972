import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { iCategory, iSubCategory } from '../model/all-classes';

import { pipe } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: 'app-job-posting',
  templateUrl: './job-posting.component.html',
  styleUrls: ['./job-posting.component.scss']
})
export class JobPostingComponent implements OnInit {

  public jobForm: FormGroup;

  public jobData: any = {
    categoryId: '',
    description: '',
    fileUrls: [],
    location: '',
    subCategoryId: '',
  }

  public submitted: boolean = false;
  public categoryList: iCategory[];
  public subCategoryList: iSubCategory[];
  public imgUrlList: any[];
  public imageFileList: any[];

  public userSettings1: any = {
    noOfRecentSearchSave: 0,
    inputPlaceholderText: 'Enter job location',
    showSearchButton: false,
    showRecentSearch: false,
  };

  constructor(private formBuilder: FormBuilder,
    public service: DbDataApiService,
    public http: HttpClient,
    public router: Router,
    private toastr: ToastrService,
    public zone: NgZone) { }

  ngOnInit(): void {
    var self = this;
    self.service.openNotifyMd = false;
    self.categoryList = [];
    self.subCategoryList = [];
    self.imageFileList = [];
    self.imgUrlList = [];

    if (self.service.categoriesList && self.service.categoriesList.length) {
      self.getActiveCategories();
    }

    self.service.categoryDataCheck.subscribe(value => {
      self.getActiveCategories();
    });

    this.jobForm = this.formBuilder.group({
      // price: ['', Validators.compose([
      //   Validators.required, Validators.min(1), Validators.max(10000000)
      // ])],
      price: [''],
      categoryId: ['', Validators.required],
      subCategoryId: ['', Validators.required],
      location: ['', Validators.required],
      description: ['', Validators.required],
    });

  }


  getActiveCategories() {
    var self = this;
    self.categoryList = [];
    for (var i = 0; i < self.service.categoriesList.length; i++) {
      if (self.service.categoriesList[i].active) {
        self.categoryList.push(JSON.parse(JSON.stringify(self.service.categoriesList[i])));
      }
    }
  }


  get f() { return this.jobForm.controls; }


  getSubCategories() {
    var self = this;

    self.jobForm.get('subCategoryId').setValue('');
    self.jobData.subCategoryId = '';

    self.subCategoryList = [];
    if (self.service.subCategoriesList && self.service.subCategoriesList.length && self.service.categoriesList && self.service.categoriesList.length) {

      for (var i = 0; i < self.service.subCategoriesList.length; i++) {
        if (self.jobForm.get('categoryId').value == self.service.subCategoriesList[i].categoryId && self.service.subCategoriesList[i].active) {
          self.subCategoryList.push(JSON.parse(JSON.stringify(self.service.subCategoriesList[i])))
        }
      }
    }
  }

  autoCompleteCallback1(data: any): any {
    this.jobData['latitude'] = data.data.geometry.location.lat;
    this.jobData['longitude'] = data.data.geometry.location.lng;
    this.jobData['location'] = data.data.formatted_address;
    this.jobForm.get('location').setValue(data.data.formatted_address);
  }


  // commented section starts

  pickFileFromAssets(filePath: string, index) {


    this.http.get(filePath, { responseType: 'blob' })
      .subscribe(res => {

        if (!this.imageFileList || !this.imageFileList.length) {
          this.imageFileList.push(res);
          debugger
        } else {
          debugger
          if (this.imageFileList.length >= index) {
            this.imageFileList[index] = res;
            debugger
          } else {
            this.imageFileList[index - 1] = res;
            debugger
          }
        }

        const reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = () => {
          if (!this.imgUrlList || !this.imgUrlList.length) {
            this.imgUrlList.push(reader.result);
          } else {
            if (this.imgUrlList.length >= index) {
              this.imgUrlList[index] = reader.result;
            } else {
              this.imgUrlList[index - 1] = reader.result;
            }
          }
        }

      });
  }

  onChangeFile(event: EventTarget, index) {

    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    if (!this.imageFileList || !this.imageFileList.length) {
      this.imageFileList.push(files[0]);
    } else {
      if (this.imageFileList.length >= index) {
        this.imageFileList[index] = files[0];
      } else {
        this.imageFileList[index - 1] = files[0];
      }
    }

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      if (!this.imgUrlList || !this.imgUrlList.length) {
        this.imgUrlList.push(reader.result);
      } else {
        if (this.imgUrlList.length >= index) {
          this.imgUrlList[index] = reader.result;
        } else {
          this.imgUrlList[index - 1] = reader.result;
        }

      }

    }

  }

//commented section ends


  submitJobData() {
    var self = this;
    if (!self.service.userData || !self.service.userData.uid) {
      self.router.navigate(['/home']);
    }

    if (!self.jobForm.valid) {
      self.submitted = true;
      return;
    }
    self.submitted = false;
    self.service.loading = true;
    // self.updateJobData();

    //commented
    if (self.imageFileList && self.imageFileList.length) {
      debugger;
      self.uploadPhoto(0);
    } else {
      self.jobData.fileUrls = [];
      self.updateJobData();
    }
    //commented


  }
  //commented

  uploadPhoto(fileIndex) {
    var self = this;
    const randomId = Math.random().toString(36).substring(2);
    firebase.storage().ref(randomId).put(self.imageFileList[fileIndex]).then((result) => {
      result.ref.getDownloadURL().then((url) => {
        if (!self.jobData.fileUrls || !self.jobData.fileUrls.length) {
          self.jobData.fileUrls = [];
        }
        self.jobData.fileUrls.push(url);
        if (self.jobData.fileUrls.length >= self.imageFileList.length) {
          self.updateJobData();
        } else {
          self.uploadPhoto(fileIndex + 1);
        }
      }).catch((err) => {
        debugger
        self.service.loading = false;
        self.toastr.error('New Job is not posted due to file uploading issue', '', { timeOut: 4000 });
      });
    }).catch((err) => {
      debugger
      self.service.loading = false;
      self.toastr.error('New Job is not posted due to file uploading issue', '', { timeOut: 4000 });
    });

  }
  //commented

  updateJobData() {
    var self = this;
    debugger;
    var jobKey = firebase.database().ref().child('Jobs/').push().key;

    self.jobData['userId'] = self.service.userData.uid;
    self.jobData['timeStamp'] = Number(new Date());

    var updates = {};
    if(!self.jobData.price){
        self.jobData.price = '';
      }
    updates['/Jobs/' + jobKey] = self.jobData;

    // var cIndex = self.service.categoriesList.findIndex(obj => obj.categoryId == self.jobData.categoryId ) ;
    // debugger
    // if(cIndex>=0) {
    //   self.jobData.fileUrls[0] = self.service.categoriesList[cIndex].categoryImage ;
    //   debugger
    // } else {
    //   self.jobData.fileUrls[0] = self.service.catImage ;
    // }
    firebase.database().ref().update(updates).then(() => {
      self.service.loading = false;

      var subCategoryIndex = self.service.subCategoriesList.findIndex(obj => obj.subCategoryId == self.jobData.subCategoryId);
      if (subCategoryIndex >= 0) {
        self.jobData.subCategoryName = self.service.subCategoriesList[subCategoryIndex].categoryName;
        self.jobData.categoryName = self.service.subCategoriesList[subCategoryIndex].parentCategoryName;
      }
      debugger
      self.jobData.userName = self.service.userData.name;
      self.jobData.onlySeeker = self.service.userData.onlySeeker;
      self.jobData.userPhone = self.service.userData.phoneNo;
      self.jobData.rating = self.service.userData.rating;
      self.jobData.jobId = jobKey;
      self.service.jobsList.unshift(JSON.parse(JSON.stringify(self.jobData)));
      self.getUserDistance(self.jobData.latitude, self.jobData.longitude, self.jobData.categoryId, self.jobData.subCategoryId, self.jobData.jobId)
      self.toastr.success('New Job posted successfully.', '', { timeOut: 3500 });
      self.router.navigate(['/home']);
    }).catch((e) => {
      self.service.loading = false;
      self.toastr.error('New Job is not posted.' + e.message, '', { timeOut: 3500 });
    });
  }

  getUserDistance(lat, lng, catId, sCatId, jobId) {
    var self = this;
    var notifyUsers: any = [];
    var tokensList: any = [];
    var usersIdList: any = [];

    for (var i = 0; i < self.service.usersList.length; i++) {

      var userData: any = JSON.parse(JSON.stringify(self.service.usersList[i]));
      if (userData.latitude && userData.longitude && userData.categoryId == catId
        && userData.subCategoryId == sCatId && userData.uid != self.service.userData.uid) {
        var distance = self.inNearbyRange(lat, lng, userData.latitude, userData.longitude)

        if (userData.maxRadius && userData.maxRadius >= distance) {

          if (userData.deviceTokens && userData.deviceTokens.length) {
            for (var k = 0; k < userData.deviceTokens.length; k++) {
              if (userData.deviceTokens[k]) {
                tokensList.push(userData.deviceTokens[k]);
              }
            }
          }

          usersIdList.push(userData.uid);
          var tempUserData: any = JSON.parse(JSON.stringify(userData));
          tempUserData['distance'] = distance;
          notifyUsers.push(tempUserData);

        }
      }
    }

    if (notifyUsers && notifyUsers.length) {
      if (tokensList && tokensList.length) {
        self.sendUserNotification(tokensList, usersIdList, jobId);
      } else {
        self.saveNotificationFirebase(tokensList, usersIdList, jobId)
      }
    }
  }

  inNearbyRange(lat, lng, userLat1, userLng1): number {
    var R = 6371;
    var dLat = (userLat1 - lat) * (Math.PI / 180)
    var dLon = (userLng1 - lng) * (Math.PI / 180);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat) * (Math.PI / 180)) * Math.cos((userLat1) * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return Math.round(d * 10) / 10;
  }

  sendUserNotification(tokensList, usersIdList, jobId) {
    var self = this;

    self.service.loading = true;

    var addMessage = firebase.functions().httpsCallable('sendNotification');
    addMessage({
      title: 'New Job Posted',
      message: 'New job posted in your area, check it out.',
      deviceToken: tokensList,
    }).then((result) => {
      self.saveNotificationFirebase(tokensList, usersIdList, jobId);
    }, (error) => {
      self.toastr.error(error.message, '', { timeOut: 3500 });
    });

  }

  saveNotificationFirebase(tokensList, usersIdList, jobId) {
    var self = this;
    var updates = {};
    var newPostKey = firebase.database().ref().child('Notifications/').push().key;
    var postData = {
      title: 'New Job Posted',
      category: 'New Job Posted',
      message: 'New job posted in your area, check it out.',
      receiverId: usersIdList,
      senderId: self.service.userData.uid,
      jobId: jobId,
      deviceToken: tokensList,
      notificationId: newPostKey,
      timeStamp: Number(new Date()),
    };

    updates['/Notifications/' + "/" + newPostKey] = postData;
    firebase.database().ref().update(updates).then(() => {
      //  self.toastr.success('Notification sent to the service providers successfully.', '', { timeOut: 3500 });
      self.service.loading = false;
    }).catch((error) => {
      self.service.loading = false;
      self.toastr.error(error.message, '', { timeOut: 3500 });
    });

  }

}
