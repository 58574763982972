import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { iCategory, iUser } from '../model/all-classes';

@Component({
  selector: 'app-search-providers',
  templateUrl: './search-providers.component.html',
  styleUrls: ['./search-providers.component.scss']
})
export class SearchProvidersComponent implements OnInit {

  public usersList: iUser[];
  public categoryList: iCategory[];
  public selectedCat: string = 'All';

  public searchFields: any = ['name', 'categoryName', 'subCategoryName']

  constructor(public service: DbDataApiService,
    public router: Router,
    private toastr: ToastrService,
    public zone: NgZone) { }

  ngOnInit(): void {
    var self = this;
    self.service.openNotifyMd = false;
    self.usersList = [];
    self.categoryList = [];

    if (self.service.usersList && self.service.usersList.length) {
      self.getActiveUsers();
    } if (self.service.categoriesList && self.service.categoriesList.length) {
      self.getActiveCategories();
    }

    self.service.userDataCheck.subscribe(value => {
      if (self.service.usersList && self.service.usersList.length) {
        self.getActiveUsers();
      }
    });

    self.service.categoryDataCheck.subscribe(value => {
      if (self.service.categoriesList && self.service.categoriesList.length) {
        self.getActiveCategories();
      }
    });

  }

  getActiveUsers() {
    var self = this;
    self.usersList = [];
    for (var i = 0; i < self.service.usersList.length; i++) {
      if (self.service.usersList[i].active) {
        self.usersList.push(JSON.parse(JSON.stringify(self.service.usersList[i])));
      }
    }

    if (self.usersList && self.usersList.length) {
      self.usersList.sort((a, b) => (a.name.toLocaleLowerCase() >= b.name.toLowerCase()) ? 1 : -1);
    }

  }

  getActiveCategories() {
    var self = this;
    self.categoryList = [];
    for (var i = 0; i < self.service.categoriesList.length; i++) {
      if (self.service.categoriesList[i].active) {
        self.categoryList.push(JSON.parse(JSON.stringify(self.service.categoriesList[i])));
      }
    }
    if (self.categoryList && self.categoryList.length) {
      self.categoryList.sort((a, b) => (a.categoryName.toLocaleLowerCase() >= b.categoryName.toLowerCase()) ? 1 : -1);
    }

  }

  filterUsers(value) {

    var self = this;
    self.selectedCat = value;
    self.usersList = [];
    if (self.service.usersList && self.service.usersList.length) {
      if (value == 'All') {
        self.usersList = JSON.parse(JSON.stringify(self.service.usersList));
      } else {
        for (var i = 0; i < self.service.usersList.length; i++) {
          if (self.service.usersList[i].categoryId == value) {
            self.usersList.push(JSON.parse(JSON.stringify(self.service.usersList[i])));
          }
        }
      }
    }
  }


}
