import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { iChat, iMessage } from '../model/all-classes';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  public myChatList: iChat[];
  public searchFields: any = ['userName']
  public endQouteKey: string = '';
  public description: string = '';
  public newMsg: iMessage;
  public imgURL: any;
  public imageFile: any;
  public userName: string = '';
  public delChatId: string = '';

  constructor(public service: DbDataApiService,
    public router: Router,
    private toastr: ToastrService,
    public zone: NgZone) {
  }

  ngOnInit(): void {
    var self = this;

    self.service.openNotifyMd = false;
    self.myChatList = [];
    self.newMsg = new iMessage();
    self.getMyChatsList();

    self.service.chatDataCheck.subscribe(value => {
      self.getMyChatsList();
    });

  }

  getMyChatsList() {
    var self = this;
    self.myChatList = [];
    if (!self.service.selectedMsgUser || !self.service.selectedMsgUser.uid) {
      self.service.selectedMsgUser = new iChat();
    }

    if (self.service.chatsList && self.service.chatsList.length) {
      for (var i = 0; i < self.service.chatsList.length; i++) {

        var chatData: iChat = new iChat();
        chatData.chatId = self.service.chatsList[i].chatId;
        chatData.uid = self.service.chatsList[i].users[0];
        if (self.service.chatsList[i].deleteChat && self.service.chatsList[i].deleteChat[self.service.userData.uid]) {
          chatData.deletedChat = self.service.chatsList[i].deleteChat[self.service.userData.uid].timeStamp;
        } else {

        }
        if (self.service.chatsList[i].users[0] == self.service.userData.uid) {
          chatData.uid = self.service.chatsList[i].users[1];
        }
        var uIndex = self.service.usersList.findIndex(obj => obj.uid == chatData.uid);
        if (uIndex >= 0) {
          chatData.userName = self.service.usersList[uIndex].name;
          chatData.userProfile = self.service.usersList[uIndex].profileUrl;
          chatData.subCategoryName = self.service.usersList[uIndex].subCategoryName;
        }
        chatData.messages = [];
        for (var key in self.service.chatsList[i].messages) {

          if (!self.service.chatsList[i].deleteChat || !self.service.chatsList[i].deleteChat[self.service.userData.uid]) {
            chatData.messages.unshift(JSON.parse(JSON.stringify(self.service.chatsList[i].messages[key])));
          } else {
            var delDate = self.service.chatsList[i].deleteChat[self.service.userData.uid].timeStamp;

            if (delDate < self.service.chatsList[i].messages[key].timeStamp) {
              chatData.messages.unshift(JSON.parse(JSON.stringify(self.service.chatsList[i].messages[key])));
            }
          }

        }

        if (self.service.selectedMsgUser && self.service.selectedMsgUser.uid && self.service.selectedMsgUser.uid == chatData.uid) {
          self.service.selectedMsgUser = JSON.parse(JSON.stringify(chatData));
        }

        if (chatData.messages && chatData.messages.length) {
          chatData.deletedChat = chatData.messages[0].timeStamp;
        }

        self.myChatList.push(chatData);


      }
    }

    if (self.myChatList.length) {
      self.myChatList.sort((a, b) => (a.deletedChat < b.deletedChat) ? 1 : -1);

      if (!self.service.selectedMsgUser || !self.service.selectedMsgUser.uid) {
        self.service.selectedMsgUser = self.myChatList[0];
      }

    }
    if (self.service.selectedMsgUser && self.service.selectedMsgUser.uid && !self.service.selectedMsgUser.userName) {
      var uIndex = self.service.usersList.findIndex(obj => obj.uid == self.service.selectedMsgUser.uid);
      if (uIndex >= 0) {
        self.service.selectedMsgUser.userName = self.service.usersList[uIndex].name;
        self.service.selectedMsgUser.userProfile = self.service.usersList[uIndex].profileUrl;
        self.service.selectedMsgUser.subCategoryName = self.service.usersList[uIndex].subCategoryName;
      }
    }

    self.scrollChatDiv();
  }

  scrollChatDiv() {
    setTimeout(() => {
      var div = document.getElementById('chatBoxId');
      if (div) {
        if (div.scrollTop + div.clientHeight < div.scrollHeight) {
          div.scrollTop = div.scrollHeight;
        }
      }
    }, 100);
  }

  keyDownFunction(event) {
    if (event.keyCode === 13 && this.newMsg.text) {
      var str = JSON.parse(JSON.stringify(this.newMsg.text))
      this.newMsg.text = ''
      this.sendNewMessage(str);
    }
  }



  onChangeFile(event: EventTarget) {

    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    this.imageFile = files[0];

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      if (!this.newMsg.text) {
        this.newMsg.text = ' ';
      }
    }

  }

  sendNewMessage(strText) {
    var self = this;
    if (self.imageFile && self.imgURL) {
      self.service.loading = true;
      self.uploadPhoto();
    } else {

      var str = strText;
      if (!str.replace(/\s/g, '').length) {
        self.newMsg.text = '';
        // console.log('string only contains whitespace (ie. spaces, tabs or line breaks)');
        return;
      }

      self.saveMessageData('', strText);
    }
  }

  uploadPhoto() {
    var self = this;

    const randomId = Math.random().toString(36).substring(2);
    firebase.storage().ref(randomId).put(self.imageFile).then((result) => {
      result.ref.getDownloadURL().then((url) => {
        self.saveMessageData(url, '');
      });
    }).catch((err) => {
      self.service.loading = false;
      self.toastr.error('message is not sent due to image uploading issue', '', { timeOut: 4000 });
    });

  }

  saveMessageData(url, strText) {
    var self = this;
    self.newMsg.uid = self.service.userData.uid;
    self.newMsg.timeStamp = Number(new Date());
    var updates = {};

    if (!self.service.selectedMsgUser.chatId) {
      self.service.selectedMsgUser.chatId = firebase.database().ref().child('Chats/').push().key;
      updates['/Chats/' + self.service.selectedMsgUser.chatId + '/users/'] = [self.service.userData.uid, self.service.selectedMsgUser.uid];
    }

    if (url) {
      var imgMsgKey = firebase.database().ref().child('Chats/' + self.service.selectedMsgUser.chatId + '/messages/').push().key;
      var imgMsgData: any = {
        timeStamp: Number(new Date()),
        uid: self.service.userData.uid,
        textImage: url
      }
      updates['/Chats/' + self.service.selectedMsgUser.chatId + '/messages/' + imgMsgKey] = imgMsgData;
    }

    var str = strText;
    if (!str.replace(/\s/g, '').length && !url) {
      self.newMsg.text = '';
      //console.log('string only contains whitespace (ie. spaces, tabs or line breaks)');
      return;
    } else if (strText && str.replace(/\s/g, '').length) {
      var msgKey = firebase.database().ref().child('Chats/' + self.service.selectedMsgUser.chatId + '/messages/').push().key;
      var msgData = JSON.parse(JSON.stringify(self.newMsg))
      msgData.text = strText;
      updates['/Chats/' + self.service.selectedMsgUser.chatId + '/messages/' + msgKey] = msgData;
    }

    firebase.database().ref().update(updates).then(() => {
      self.sendUserNotification();
      self.newMsg.text = '';
      self.imgURL = '';
      self.imageFile = '';
      self.service.loading = false;
    }).catch((e) => {
      self.service.loading = false;
      console.log(e);
    });
  }

  deleteChat(chatId) {
    var self = this;
    var updates = {};
    updates['/Chats/' + chatId + '/deleteChat/' + self.service.userData.uid + '/timeStamp/'] = Number(new Date());

    firebase.database().ref().update(updates).then(() => {
      self.userName = '';
      self.delChatId = '';
      var mdDiv = document.getElementById('closeDelChatMd');
      if (mdDiv) {
        mdDiv.click();
      }
      self.toastr.success('Chat is deleted successfully.', '', { timeOut: 1500 });
    }).catch((e) => {
      self.toastr.success('Chat is not deleted.' + e.message, '', { timeOut: 1500 });
    });
  }

  sendUserNotification() {
    var self = this;
    self.service.loading = true;

    var recieverList = [self.service.selectedMsgUser.uid]
    var tokensList: any = [];
    var userData = self.service.usersList.find(obj => obj.uid == self.service.selectedMsgUser.uid);
    if (userData && userData.uid) {

      if (userData.deviceTokens && userData.deviceTokens.length) {
        for (var k = 0; k < userData.deviceTokens.length; k++) {
          if (userData.deviceTokens[k]) {
            tokensList.push(userData.deviceTokens[k]);
          }
        }
      }

      //       var data: HttpParameters = [
      //         "data": payload
      //     ]
      // let payload = [
      //         "dataUid": "\(SharedManager.shared.user?.uid ?? "")"
      //     ]

      if (tokensList && tokensList.length) {
        var addMessage = firebase.functions().httpsCallable('sendNotification');
        addMessage({
          title: 'Message Received',
          message: this.service.userData.name + ' sent you a message.',
          deviceToken: tokensList,
        }).then((result) => {
          self.saveNotificationFirebase(tokensList, recieverList);
        }, (error) => {
          self.toastr.error(error.message, '', { timeOut: 3500 });
        });
      } else {
        self.saveNotificationFirebase(tokensList, recieverList);
      }
    }

  }

  saveNotificationFirebase(tokensList, recieverList) {
    var self = this;
    var updates = {};
    var newPostKey = firebase.database().ref().child('Notifications/').push().key;
    var postData = {
      title: 'Message Received',
      category: 'Message Received',
      deviceToken: tokensList,
      userId: self.service.userData.uid,
      message: this.service.userData.name + ' sent you a message.',
      receiverId: recieverList,
      senderId: self.service.userData.uid,
      notificationId: newPostKey,
      timeStamp: Number(new Date()),
    };

    updates['/Notifications/' + "/" + newPostKey] = postData;
    firebase.database().ref().update(updates).then(() => {
      self.service.loading = false;
    }).catch((error) => {
      self.service.loading = false;
      self.toastr.error(error.message, '', { timeOut: 3500 });
    });

  }

  

  getUserData(){

  }






}
