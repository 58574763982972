import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  public signupForm: FormGroup;
  public submitted: boolean = false;
  public policy: boolean = false;

  constructor(private formBuilder: FormBuilder,
    public service: DbDataApiService,
    public router: Router,
    private toastr: ToastrService,
    public zone: NgZone) {
    if (service.userData && service.userData.uid) {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit() {
    var self = this;

    self.signupForm = self.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'),
      ])],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
      c_password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
    });
  }

  get f() { return this.signupForm.controls; }

  checkPassword() {
    // if (this.signupForm.get("password").value == this.signupForm.get("c_password").value) {
    //   return true;
    // } 
    //   return false;
      return this.signupForm.get("password").value == this.signupForm.get("c_password").value
  }

  submitSignupForm() {
    var self = this;

    if (!self.signupForm.valid || !self.checkPassword()) {
      self.submitted = true;
      return;
    }
    self.submitted = false;

    self.service.loading = true;
    firebase.auth().createUserWithEmailAndPassword(self.signupForm.get("email").value, self.signupForm.get("password").value)
      .then((user) => {
        if (firebase.auth().currentUser) {
          self.saveUserData(firebase.auth().currentUser.uid);
        } else {
          self.service.loading = false;
          self.toastr.error('Account not created, kindly try again', '', { timeOut: 4000 })
        }
      }).catch((error) => {
        self.service.loading = false;
        self.toastr.error(error.message, '', { timeOut: 3000 })
      });

  }

  saveUserData(uid: any) {

    var self = this;
    var formData = {
      name: self.signupForm.get("name").value,
      email: self.signupForm.get("email").value,
      onlySeeker: true,
      uid: uid,
      active: true,
      timeStamp: Number(new Date())
    };
 
    firebase.database().ref('Users/' + uid).update(formData).then((result) => {
      self.toastr.success('You have signedup successfully.', '', { timeOut: 4000 })
      self.service.setActiveUser(formData);
      self.signupForm.reset();
      self.service.loading = false;
      self.router.navigate(['/landing']);
    }).catch((err) => {
      self.service.loading = false;
      self.toastr.error(err, '', { timeOut: 4000 })
    });
  }

}