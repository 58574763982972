<app-navigation></app-navigation>
<section class="main-cont">
  <section class="section-1 page-title">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="mb-0">Top Services</h4>

      </div>
      <div class="col-sm-6">
        <!-- <button class="btn btn-outline-secondary" type="button">Previous</button>
                <button class="btn btn-outline-secondary" type="button">Next</button> -->
                
        <input type="search" class="form-control searchField" [(ngModel)]="term"
          placeholder="Search Job by User, Category, Sub Category">
      </div>
    </div>
  </section>
  <section class="section-2">
    <div class="row">

      <div class="col-sm-6 col-md-4 col-xxl-3 jobCard" [class.d-none]="job.status=='Ended'"
        *ngFor="let job of jobsList | search:term:searchFields; let i=index;">
        <div class="card item" [class.item-quote]="!job.onlySeeker">
          <div class="row">
            <div class="col-sm-8">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <img [src]=" service.getUserProfile(job.userId) || '/assets/imgs/post-dummy.png'"
                    class="card-img-top">
                </div>
                <div>
                  <h5 class="card-title pointer" [routerLink]="['/user-profile',job.userId]">
                    {{job.userName | titlecase}}</h5>
                  <p class="mb-1 pointer" [routerLink]="['/user-profile',job.userId]"><small
                      class="primary">{{job.subCategoryName}}</small></p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="rating">
                <i class="fas fa-star"></i>
                <span *ngIf="!job.rating">Not rated yet</span>
                <span *ngIf="job.rating">{{job.rating | number:'1.2-2'}}</span>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12">
                  <img [src]=" job.fileUrls[0] " *ngIf="job.fileUrls && job.fileUrls[0]" class="cardImg">
                  <img src="/assets/imgs/post-dummy.png" *ngIf="!job.fileUrls || !job.fileUrls[0]" class="cardImg">
                </div>
                <div class="col-sm-9">
                  <p class="pointer mb-2 descText" (click)="description=job.description; jobData=job "
                    data-toggle="modal" data-target="#quoteModal">{{job.description}}</p>
                </div>
                <div class="col-sm-3"></div>
                <div class="col-sm-9 pr-0">
                  <div class="d-flex location">
                    <i class="fas fa-map-marker-alt mr-2 mt-1"></i>
                    <!-- <p class="locationText">{{job.location}}</p> -->
                    <a class="primary pointer locationText" *ngIf="job.latitude && job.longitude"
                    [href]="'https://www.google.com/maps/?q='+job.latitude+','+job.longitude" target="_blank">{{job.location}}</a>
                  </div>
                </div>
                <div class="col-sm-3 text-right">
                  <h5 class="price bold mt-0 mb-2" *ngIf="job.price"><i class="fas fa-paper-plane mr-2"></i>
                    <small>${{job.price || '0'}}</small></h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-4 col-sm-6" [class.d-none]="job.status=='Ended'"
        *ngFor="let job of jobsList | search:term:searchFields; let i=index;">
        <div class="card item" [class.item-quote]="!job.onlySeeker">
          
          <div class="rating">
            <i class="fas fa-star"></i>
            <span *ngIf="!job.rating">Not rated yet</span>
            <span *ngIf="job.rating">{{job.rating | number:'1.2-2'}}</span>
          </div>
          <img [src]=" job.fileUrls[0] " class="card-img-top" *ngIf="job.fileUrls && job.fileUrls[0]">
          <img src="/assets/imgs/post-dummy.png" class="card-img-top" *ngIf="!job.fileUrls || !job.fileUrls[0]">
          <div class="card-body">
            <h5 class="card-title pointer" [routerLink]="['/user-profile',job.userId]">{{job.userName | titlecase}}</h5>
            <p class="mb-1 pointer" [routerLink]="['/user-profile',job.userId]"><small
                class="primary">{{job.subCategoryName}}</small></p>
            <div class="price">
              <h5 class="primary bold mt-0 mb-2" *ngIf="job.price"><small>Price: </small> ${{job.price || '0'}}</h5>
            </div>
            <p class="pointer mb-2 descText" (click)="description=job.description; jobData=job " data-toggle="modal"
              data-target="#quoteModal">{{job.description}}</p>
            <p class="locationText">{{job.location}}</p>
            <div class="d-flex" *ngIf=" service.userData.uid && service.userData.uid != job.userId">
              <button class="btn btn-primary mt-3" type="button"
                (click)="service.checkMsgUser(job.userId)">Message</button>
              <button class="btn btn-outline-secondary mt-3" type="button" [routerLink]="['/post-quote',job.jobId]"
                *ngIf=" !userQoutedJobsList.includes(job.jobId) && job.onlySeeker==true">Quote</button>
            </div>
            <div class="d-flex" *ngIf=" !service.userData ||  !service.userData.uid ">
              <button class="btn btn-primary mt-3" type="button" data-toggle="modal"
                data-target="#loginModal">Message</button>
              <button *ngIf="job.onlySeeker==true" class="btn btn-outline-secondary mt-3" type="button"
                data-toggle="modal" data-target="#loginModal">Quote</button>
            </div>

          </div>
        </div>
      </div> -->
        <div class="col-md-4" *ngIf="!jobsList || !jobsList.length">
          No Job is available.
        </div>

      </div>


    </div>
  </section>
</section>


<!-- Modal -->
<div class="modal tichun fade" id="quoteModal" tabindex="-1" aria-labelledby="quoteModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content modal-content1">
      <div class="modal-body">
        <h4 class="primary text-center">Description</h4>
        <p> {{description}} </p>
      </div>
      <div class="modal-footer">
        <button (click)="service.checkMsgUser(jobData.userId)" type="button" class="btn btn-primary"
          data-dismiss="modal" *ngIf="service.userData && service.userData.uid">Message</button>
        <button data-toggle="modal" data-target="#loginModal" *ngIf="!service.userData || !service.userData.uid"
          type="button" class="btn btn-primary" data-dismiss="modal">Message</button>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<!-- <div class="modal tichun fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-login></app-login>
    </div>
  </div>
</div> -->
