import { Component, OnInit, NgZone, Input, Self } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import firebase from 'firebase';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-post-quote',
  templateUrl: './post-quote.component.html',
  styleUrls: ['./post-quote.component.scss']
})
export class PostQuoteComponent implements OnInit {

  public qouteForm: FormGroup;

  public qouteData: any = {
    description: '',
   // fileUrls: [],
    jobId: '',
    userId: '',
    //phoneNumber: '',
  }

  public submitted: boolean = false;
  public imgUrlList: any[];
  public imageFileList: any[];

  constructor(private formBuilder: FormBuilder,
    public service: DbDataApiService,
    public router: Router,
    public route: ActivatedRoute,
    private toastr: ToastrService,
    public zone: NgZone) { }

  ngOnInit(): void {
    var self = this;
    self.service.openNotifyMd = false;
    if (this.route.snapshot.params['id']) {
      this.qouteData.jobId = this.route.snapshot.params['id'];
      this.qouteData.userId = this.service.userData.uid;
      this.qouteData.phoneNumber = this.service.userData.phoneNo;
    } else {
      this.router.navigate(['/search-providers']);
    }

    self.imageFileList = [];
    self.imgUrlList = [];

    this.qouteForm = this.formBuilder.group({
      price: ['', Validators.compose([
        Validators.required, Validators.min(1), Validators.max(10000000)
      ])],
      // phoneNumber: ['', Validators.compose([
      //   Validators.required, Validators.minLength(7), Validators.maxLength(16)
      // ])],
      description: ['', Validators.compose([
        Validators.required, Validators.minLength(3), Validators.maxLength(200)
      ])],
    });

  }

  get f() { return this.qouteForm.controls; }

  onChangeFile(event: EventTarget, index) {

    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    if (!this.imageFileList || !this.imageFileList.length) {
      this.imageFileList.push(files[0]);
    } else {
      if (this.imageFileList.length >= index) {
        this.imageFileList[index] = files[0];
      } else {
        this.imageFileList[index - 1] = files[0];
      }
    }


    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      if (!this.imgUrlList || !this.imgUrlList.length) {
        this.imgUrlList.push(reader.result);
      } else {
        if (this.imgUrlList.length >= index) {
          this.imgUrlList[index] = reader.result;
        } else {
          this.imgUrlList[index - 1] = reader.result;
        }
      }

    }

  }

  submitQouteData() {
    var self = this;
    if (!self.service.userData || !self.service.userData.uid) {
      self.router.navigate(['/home']);
    }

    // if (!self.qouteForm.valid || !self.imgUrlList || !self.imgUrlList.length) {
    //   self.submitted = true;
    //   return;
    // }
    if (!self.qouteForm.valid) {
      self.submitted = true;
      return;
    }
    self.submitted = false;
    self.service.loading = true;

   // self.uploadPhoto(0);
   self.updateQouteData();
  }

  uploadPhoto(fileIndex) {
    var self = this;

    const randomId = Math.random().toString(36).substring(2);
    firebase.storage().ref(randomId).put(self.imageFileList[fileIndex]).then((result) => {
      result.ref.getDownloadURL().then((url) => {
        if (!self.qouteData.fileUrls || !self.qouteData.fileUrls.length) {
          self.qouteData.fileUrls = [];
        }
        self.qouteData.fileUrls.push(url);
        if (self.qouteData.fileUrls.length >= self.imageFileList.length) {
          self.updateQouteData();
        } else {
          self.uploadPhoto(fileIndex + 1);
        }
      });
    }).catch((err) => {
      self.service.loading = false;
      self.toastr.error('Qoute is not posted due to file uploading issue', '', { timeOut: 4000 });
    });

  }

  updateQouteData() {
    var self = this;

    var qouteKey = firebase.database().ref().child('Quotes/').push().key;
    self.qouteData['timeStamp'] = Number(new Date());
    self.qouteData.price = '' + self.qouteData.price ;
    if(!self.qouteData.phoneNumber){
      self.qouteData.phoneNumber = '';
    }
    var updates = {};
    updates['/Quotes/' + qouteKey] = self.qouteData;

    firebase.database().ref().update(updates).then(() => {
      self.service.loading = false;
     
      var jobIndex = self.service.jobsList.findIndex(obj => obj.jobId == self.qouteData.jobId);

      if (jobIndex >= 0) {
        self.service.jobsList[jobIndex].qouteCount++;
        self.qouteData.categoryName = self.service.jobsList[jobIndex].categoryName;
        self.qouteData.subCategoryName = self.service.jobsList[jobIndex].subCategoryName;
        self.qouteData.jobPrice = self.service.jobsList[jobIndex].price;
        self.qouteData.jobLocation = self.service.jobsList[jobIndex].location;
        self.sendUserNotification( self.service.jobsList[jobIndex].userId );
      }
      self.qouteData.userName = self.service.userData.name;
      // self.service.qoutesList.unshift(JSON.parse(JSON.stringify(self.qouteData)))
      self.toastr.success('Qoute posted successfully.', '', { timeOut: 3500 });
      self.router.navigate(['/home']);
    }).catch((e) => {
      self.service.loading = false;
      self.toastr.success('Qoute is not posted.' + e.message, '', { timeOut: 3500 });
    });
  }


  sendUserNotification(uid) {
    var self = this;
    self.service.loading = true;

    var recieverList = [uid]
    var tokensList: any = [];
    var userData = self.service.usersList.find(obj=> obj.uid == uid) ;

    if (userData.deviceTokens && userData.deviceTokens.length) {
      for (var k = 0; k < userData.deviceTokens.length; k++) {
        if (userData.deviceTokens[k]) {
          tokensList.push(userData.deviceTokens[k]);
        }
      }
    }

    if (tokensList && tokensList.length) {
      var addMessage = firebase.functions().httpsCallable('sendNotification');
      addMessage({
        title: 'New Quote Received',
        message: this.service.userData.name + ' just sent you a new quote.',
        deviceToken: tokensList,
      }).then((result) => {
        self.saveNotificationFirebase(tokensList, recieverList);
      }, (error) => {
        self.toastr.error(error.message, '', { timeOut: 3500 });
      });
    } else {
      self.saveNotificationFirebase(tokensList, recieverList);
    }


  }

  saveNotificationFirebase(tokensList, recieverList) {
    var self = this;

// New Quote Received
// let payload = [
//       "dataUid": "\(self.job?.jobId ?? "")"
//   ]
//   var data: HttpParameters = [
//       "data": payload,
//   ]

    var updates = {};
    var newPostKey = firebase.database().ref().child('Notifications/').push().key;
    var postData = {
      title: 'New Quote Received',
      category: 'New Quote Received',
      message: this.service.userData.name + ' just sent you a new quote.',
      receiverId: recieverList,
      senderId: self.service.userData.uid,
      jobId:this.qouteData.jobId,
      deviceToken: tokensList,
      notificationId: newPostKey,
      timeStamp: Number(new Date()),
    };

    updates['/Notifications/' + "/" + newPostKey] = postData;
    firebase.database().ref().update(updates).then(() => {
      self.service.loading = false;
    }).catch((error) => {
      self.service.loading = false;
      self.toastr.error(error.message, '', { timeOut: 3500 });
    });

  }

}

