import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { iCategory, iSubCategory, iUser } from '../model/all-classes';
/*range sldier */
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  value: number = 0;
  options: Options = {
    floor: 0,
    ceil: 100
  };

  public submitted: boolean = false;
  public userProfile: iUser;
  public categoryList: iCategory[];
  public subCategoryList: iSubCategory[];

  public userSettings1: any = {
    noOfRecentSearchSave: 0,
    inputPlaceholderText: 'Entry your location',
    showSearchButton: false,
    showRecentSearch: false,
  };

  constructor(private formBuilder: FormBuilder,
    public service: DbDataApiService,
    public router: Router,
    private toastr: ToastrService,
    public zone: NgZone) { }

  ngOnInit(): void {
    var self = this;

    self.userProfile = new iUser();

    self.categoryList = [];
    self.subCategoryList = [];

    if (self.service.userData && self.service.userData.uid) {
      self.userProfile = JSON.parse(JSON.stringify(self.service.userData));
      if (!self.userProfile.maxRadius) {
        self.userProfile.maxRadius = 0;
      } if (self.userProfile.categoryId) {
        self.getSubCategories('');
      } if (self.userProfile.location) {
        self.userSettings1['inputString'] = self.userProfile.location;
      }
    } if (self.service.categoriesList && self.service.categoriesList.length) {
      self.getActiveCategories();
    }

    self.service.userDataCheck.subscribe(value => {
      self.userProfile = JSON.parse(JSON.stringify(self.service.userData));
      if (!self.userProfile.maxRadius) {
        self.userProfile.maxRadius = 0;
      } if (self.userProfile.categoryId) {
        self.getSubCategories('');
      } if (self.userProfile.location) {
        self.userSettings1['inputString'] = self.userProfile.location;
      }
    });
    self.service.categoryDataCheck.subscribe(value => {
      self.getActiveCategories();
    });

  }


  getActiveCategories() {
    var self = this;
    self.categoryList = [];
    for (var i = 0; i < self.service.categoriesList.length; i++) {
      if (self.service.categoriesList[i].active) {
        self.categoryList.push(JSON.parse(JSON.stringify(self.service.categoriesList[i])));
      }
    }
  }

  getSubCategories(data) {
    var self = this;
    self.userProfile.subCategoryId = '';
    self.subCategoryList = [];
    if (self.service.subCategoriesList && self.service.subCategoriesList.length && self.service.categoriesList && self.service.categoriesList.length) {

      for (var i = 0; i < self.service.subCategoriesList.length; i++) {
        if (self.userProfile.categoryId == self.service.subCategoriesList[i].categoryId && self.service.subCategoriesList[i].active) {
          self.subCategoryList.push(JSON.parse(JSON.stringify(self.service.subCategoriesList[i])))
        }
      }
    }
  }


  autoCompleteCallback1(data: any): any {
    this.userProfile.latitude = data.data.geometry.location.lat;
    this.userProfile.longitude = data.data.geometry.location.lng;
    this.userProfile.location = data.data.formatted_address;
  }

  updateProfileData() {
    var self = this;
    if (!self.userProfile || !self.userProfile.uid) {
      self.router.navigate(['/home']);
    }

    // if (!self.userProfile || !self.userProfile.categoryId || !self.userProfile.subCategoryId ||
    //   !self.userProfile.location || !self.userProfile.maxRadius) {
    //   self.submitted = true;
    //   return;
    // }
    if (!self.userProfile || !self.userProfile.location || !self.userProfile.maxRadius) {
      self.submitted = true;
      return;
    }

    self.submitted = false;
    self.service.loading = true;
    self.userProfile.onlySeeker = false;
    var updates = {};
    updates['/Users/' + self.service.userData.uid] = self.userProfile;

    firebase.database().ref().update(updates).then(() => {
      self.service.loading = false;
      self.service.setActiveUser(self.userProfile);
      self.toastr.success('Profile data updated successfully.', '', { timeOut: 3500 });
      self.router.navigate(['/home']);
    }).catch((e) => {
      self.service.loading = false;
      self.toastr.success('Profile data is not updated.' + e.message, '', { timeOut: 3500 });
    });
  }

}
