<section class="navigation">
  <!-- <div class="row topbar">
    <div class="col-sm-12 px-0">
      <nav class="navbar navbar-expand-md fixed-top">
        <div class="search-bar ml-auto">
          <div class="input-group" routerLink="/search-providers">
            <input type="search" class="form-control" [(ngModel)]="service.searchTerm"
              placeholder="Search service providers" aria-label="Username" aria-describedby="basic-addon1">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fas fa-search"></i>
              </span>
            </div>
          </div>
        </div>
        <div id="my-nav" style="margin-left: auto !important;">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="nav-link"><button class="btn btn-primary mr-5" type="button" routerLink="/job-posting"><i
                    class="fas fa-plus mr-2"></i>Add Job</button></a>
            </li>
            <li class="nav-item mr-2">
              <p class="mb-0 pointer" routerLink="/profile">
                <img class="p-0 mr-0" [src]="service.userData.profileUrl || '/assets/imgs/person.svg'" alt="">
                {{service.userData.name | titlecase}}</p>
            </li>
            <li class="nav-item" (click)=" updateNotificationCount()" style="position: relative;">
              <img src="/assets/imgs/bell.svg" class="mr-0" alt="">
              <div class="circleDiv" *ngIf="notifyCount>service.myNotifyCounts"></div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div> -->
  <div class="topbar fixed-top">
    <div class="row">
      <div class="col-sm-6">
        <div class="search-bar" [class.d-none]="service.pageUrl == '/home'">
          <div class="input-group" routerLink="/search-providers">
            <input type="search" class="form-control" [(ngModel)]="service.searchTerm"
              placeholder="Search service providers" aria-label="Username" aria-describedby="basic-addon1">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fas fa-search"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-12 text-right">
        <div id="my-nav">
          <button class="btn btn-primary mr-5" type="button" routerLink="/job-posting"
            *ngIf="service.userData && service.userData.uid">
            <i class="fas fa-plus mr-2"></i>Add Job</button>
          <button class="btn btn-primary mr-5" type="button" data-toggle="modal" data-target="#loginModal"
            *ngIf="!service.userData || !service.userData.uid">
            <i class="fas fa-plus mr-2"></i>Add Job</button>
          <div class="mb-0 pointer" routerLink="/profile" *ngIf="service.userData && service.userData.uid">
            <img class="p-0 mr-0" [src]="service.userData.profileUrl || '/assets/imgs/person.svg'" alt="">
            {{service.userData.name | titlecase}}
          </div>

          <button class="btn btn-primary mr-5" type="button" data-toggle="modal" data-target="#loginModal"
            *ngIf="!service.userData || !service.userData.uid"> Login</button>

          <div class="notification" *ngIf="service.userData && service.userData.uid"
            (click)=" updateNotificationCount()" style="position: relative;">
            <img src="/assets/imgs/bell.svg" class="mr-0" alt="">
            <div class="circleDiv" *ngIf="notifyCount>service.myNotifyCounts"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row sidebar">
    <div class="col-sm-12">
      <div>
        <nav class="navbar navbar-expand-lg fixed-top">
          <a class="navbar-brand" routerLink="/home"><img src="/assets/imgs/logo-1.svg" alt=""></a>
          <button class="navbar-toggler" data-target="#my-nav" data-toggle="collapse" aria-controls="my-nav"
            aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon">
              <i class="fas fa-bars"></i>
            </span>
          </button>
          <div id="my-nav" class="collapse navbar-collapse">
            <ul class="navbar-nav">
              <li class="nav-item" routerLink="/home" [routerLinkActive]="['active']">
                <a class="nav-link">
                  <img src="/assets/imgs/home.svg" alt="">
                  <p class="mb-0">Home</p>
                </a>
              </li>
              <li class="nav-item" routerLink="/search-providers" [routerLinkActive]="['active']">
                <a class="nav-link">
                  <img src="/assets/imgs/search.svg" alt="">
                  <p class="mb-0">Search</p>
                </a>
              </li>
              <li class="nav-item" data-toggle="modal" data-target="#loginModal"
                *ngIf="!service.userData || !service.userData.uid">
                <a class="nav-link">
                  <img src="/assets/imgs/envelope.svg" alt="">
                  <p class="mb-0">Messages</p>
                </a>
              </li>
              <li class="nav-item" routerLink="/messages" [routerLinkActive]="['active']"
                *ngIf="service.userData && service.userData.uid">
                <a class="nav-link">
                  <img src="/assets/imgs/envelope.svg" alt="">
                  <p class="mb-0">Messages</p>
                </a>
              </li>
              <li class="nav-item" data-toggle="modal" data-target="#loginModal"
                *ngIf="!service.userData || !service.userData.uid">
                <a class="nav-link">
                  <img src="/assets/imgs/user.svg" alt="">
                  <p class="mb-0">Profile</p>
                </a>
              </li>
              <li class="nav-item" routerLink="/profile" [routerLinkActive]="['active']"
                *ngIf="service.userData && service.userData.uid">
                <a class="nav-link">
                  <img src="/assets/imgs/user.svg" alt="">
                  <p class="mb-0">Profile</p>
                </a>
              </li>
              <li class="nav-item" data-toggle="modal" data-target="#loginModal"
                *ngIf="!service.userData || !service.userData.uid">
                <a class="nav-link" style="position: relative;">
                  <img src="/assets/imgs/job-seeker1.svg" style="filter: invert(.65);" alt="">
                  <p class="mb-0" style="position: relative;">Posted Jobs
                    <span class="circleDiv" *ngIf="service.newJobQoutes>service.myJobQoutes"></span>
                  </p>
                </a>
              </li>
              <li class="nav-item" routerLink="/jobs-posted" [routerLinkActive]="['active']"
                *ngIf="service.userData && service.userData.uid">
                <a class="nav-link" style="position: relative;">
                  <img src="/assets/imgs/job-seeker1.svg" style="filter: invert(.65);" alt="">
                  <p class="mb-0" style="position: relative;">Posted Jobs
                    <span class="circleDiv" *ngIf="service.newJobQoutes>service.myJobQoutes"></span>
                  </p>
                </a>
              </li>
              <li class="nav-item" data-toggle="modal" data-target="#loginModal"
                *ngIf="!service.userData || !service.userData.uid">
                <a class="nav-link">
                  <img src="/assets/imgs/job-finder.svg" style="filter: invert(.65);" alt="">
                  <p class="mb-0">Posted Quotes</p>
                </a>
              </li>
              <li class="nav-item" routerLink="/quotes-submitted" [routerLinkActive]="['active']"
                *ngIf="service.userData && service.userData.uid">
                <a class="nav-link">
                  <img src="/assets/imgs/job-finder.svg" style="filter: invert(.65);" alt="">
                  <p class="mb-0">Posted Quotes</p>
                </a>
              </li>


              <li class="nav-item" (click)="service.logoutUser()" *ngIf="service.userData && service.userData.uid">
                <a class="nav-link">
                  <img src="/assets/imgs/logout-icon.svg" alt="">
                  <p class="mb-0">Logout</p>
                </a>
              </li>
              <li class="nav-item" data-toggle="modal" data-target="#loginModal"
                *ngIf="!service.userData || !service.userData.uid">
                <a class="nav-link">
                  <img src="/assets/imgs/logout-icon.svg" alt="">
                  <p class="mb-0">Login</p>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <!-- Notifications Start-->
  <div class="notify-block" *ngIf="service.openNotifyMd">

    <div class="my-2 n-div pointer" (click)="checkNotificationType(notify)"
      *ngFor="let notify of notificationsList; let i=index">
      <h5 class="my-0">{{notify.title}}</h5>
      <p class="my-1">{{notify.message}}</p>
    </div>

    <div class="my-2 n-div" *ngIf="!notificationsList && !notificationsList.length">
      <h5>There is no notification.</h5>
    </div>

  </div>
  <!-- Notifications ends -->

  <div class="footerCont">
    <div class="footer">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="/terms&conditions" [routerLinkActive]="['active']">Terms & Conditions</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/privacy-policy" [routerLinkActive]="['active']">Privacy Policy</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/cookie-policy" [routerLinkActive]="['active']">Cookie Policy</a>
        </li>
      </ul>
    </div>
  </div>

</section>


<!-- Modal -->
<!-- <div class="modal tichun fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body text-center">
        <h4 class="primary">Alert</h4>
        <p> You have to be logged in first to proceed further </p>
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" routerLink="/login" class="btn btn-primary">Ok</button>
      </div>
    </div>
  </div>
</div> -->
