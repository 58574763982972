<ngx-loading [show]="service.loading" > </ngx-loading> 
<section class="login-form">
  <div class="row">
    <div class="col-sm-4 form-left">
      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
          <img class="img-responsive" src="/assets/imgs/logo.svg" alt="">
          <h4 class="py-4">Why our service?</h4>
          <ul>
            <li>We’re fast and experienced</li>
            <li>Powered by tech, so you can</li>
            <li>Updates and pics for every</li>
          </ul>
        </div>
        <div class="col-sm-2"></div>
      </div>
    </div>
    <div class="col-sm-8 form-right">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-8 px-0">
          <div class="row">
            <div class="col-sm-12">
              <h1>If you are a service provider</h1>
              <h1>choose your categories</h1>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="my-input">Categories</label>

                  <ng-select [items]="categoryList" [(ngModel)]="userProfile.categoryId" placeholder="Select Category"
                    bindLabel="categoryName" bindValue="categoryId" [multiple]="false"
                    (change)="getSubCategories( $event )"></ng-select>

                  <!-- <div *ngIf="submitted && !userProfile.categoryId" class="redErrorText pl-2">
                    *Category is required </div> -->
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="">Sub-Categories</label>
                  <ng-select [items]="subCategoryList" [(ngModel)]="userProfile.subCategoryId"
                    placeholder="Select Sub-Category" bindLabel="categoryName" bindValue="subCategoryId"
                    [multiple]="false"></ng-select>

                  <!-- <div *ngIf="submitted && !userProfile.subCategoryId" class="redErrorText pl-2">
                    *Sub Category is required </div> -->
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="">Location</label>
                  <!--Using items input-->
                  <ngxgeo-autocomplete id="locationSerach" [userSettings]="userSettings1"
                    style="background-color: white !important;" (componentCallback)="autoCompleteCallback1($event) ">
                  </ngxgeo-autocomplete>

                  <div *ngIf="submitted && !userProfile.location" class="redErrorText pl-2">
                    *Location is required </div>

                </div>
              </div>
              <div class="col-sm-12 pt-3">
                <label for="">Area (Select area radius Km for your services)</label><br>
                <ngx-slider [options]="options" [(ngModel)]="userProfile.maxRadius" ></ngx-slider>

                <div *ngIf="submitted && !userProfile.maxRadius" class="redErrorText pl-2">
                  *Area Radius is required </div>

              </div>
              <div class="col-sm-12 mt-3 text-center">
                <button class="btn btn-secondary my-1 mx-2" type="button" routerLink="/home">Skip</button>
                <button class="btn btn-primary my-1 mx-2" type="button" (click)="updateProfileData()">Submit</button>
              </div>
            </div>

          </div>
        </div>
        <div class="col-sm-2"></div>
      </div>

      <div class="row form-footer">
        <div class="col-sm-3">
          <p>Already a member? <span class="pl-2 primary pointer" routerLink="/home">Log in</span></p>
        </div>
        <div class="col-sm-5 align-self-center">
          <div class="footerCont">
            <div class="footer">
              <ul class="nav">
                <li class="nav-item">
                  <a class="nav-link" routerLink="/terms&conditions" [routerLinkActive]="['active']">Terms & Conditions</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/privacy-policy" [routerLinkActive]="['active']">Privacy Policy</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" routerLink="/cookie-policy" [routerLinkActive]="['active']">Cookie Policy</a>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
