import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { Component, OnInit, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';

import { iUser, iCategory, iJob, iNotification, iQoute, iReview, iSubCategory, iChat } from '../model/all-classes'
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DbDataApiService {

  public pageUrl: any = ""

  public userDataCheck: Subject<string> = new Subject<string>();
  public jobDataCheck: Subject<string> = new Subject<string>();
  public qouteDataCheck: Subject<string> = new Subject<string>();
  public categoryDataCheck: Subject<string> = new Subject<string>();
  public notifyDataCheck: Subject<string> = new Subject<string>();
  public reviewDataCheck: Subject<string> = new Subject<string>();

  public chatDataCheck: Subject<string> = new Subject<string>();

  public loading: boolean = false;
  public userData: iUser;
  public currentDate: any = new Date();


  public selectedMsgUser: iChat;
  public categoriesList: iCategory[];
  public jobsList: iJob[];
  public notificationsList: iNotification[];
  public qoutesList: iQoute[];
  public reviewsList: iReview[];
  public chatsList: any[];
  public subCategoriesList: iSubCategory[];
  public usersList: iUser[];

  public searchTerm: string;

  public myOldJobs: iJob[];
  public myJobQoutes: number = 0;
  public myNotifyCounts: number = 0;
  public newJobQoutes: number = 0;
  public openNotifyMd: boolean = false;

  public catImage = 'https://firebasestorage.googleapis.com/v0/b/matesrates-dff00.appspot.com/o/CategoryPlaceholder.png?alt=media&token=5ce25322-f80b-4941-bb91-bfd19f71e143';

  constructor(public router: Router,
    public toastr: ToastrService,
    public zone: NgZone) {
    this.openNotifyMd = false;
    this.userData = new iUser();
    if (localStorage.getItem('mateRatesWeb')) {
      this.userData = JSON.parse(localStorage.getItem('mateRatesWeb'));
      if (localStorage.getItem('mateRatesCounts' + this.userData.uid)) {
        var data = JSON.parse(localStorage.getItem('mateRatesCounts' + this.userData.uid));
        this.myJobQoutes = JSON.parse(JSON.stringify(data.myJobQoutes));
        this.myNotifyCounts = JSON.parse(JSON.stringify(data.myNotifyCounts));
      } else {
        this.myJobQoutes = 0;
        this.myNotifyCounts = 0;
      }
    }
    //  else {
    //   this.router.navigate(['/login']);
    // }
    this.getFirebaseData();
  }

  setActiveUser(user) {
    this.userData = user;
    localStorage.setItem('mateRatesWeb', JSON.stringify(user));
    this.userData = JSON.parse(localStorage.getItem('mateRatesWeb'));
    if (localStorage.getItem('mateRatesCounts' + this.userData.uid)) {
      var data = JSON.parse(localStorage.getItem('mateRatesCounts' + this.userData.uid));
      this.myJobQoutes = JSON.parse(JSON.stringify(data.myJobQoutes));
      this.myNotifyCounts = JSON.parse(JSON.stringify(data.myNotifyCounts));
    } else {
      this.myJobQoutes = 0;
      this.myNotifyCounts = 0;
    }
    this.getFirebaseData();
  }

  logoutUser() {
    var self = this;
    self.userData = new iUser();
    localStorage.removeItem('mateRatesWeb');
    var user = firebase.auth().currentUser;
    if (user != null) {
      firebase.auth().signOut().then(() => {
        var ref: any = firebase.database().ref().child('Notifications/');
        ref.off('value');
        var ref2: any = firebase.database().ref().child('Quotes/');
        ref2.off('value');
        self.router.navigate(['/home']);
      }).catch((e) => {
        alert(e.message);
      })
    } else {
      var ref: any = firebase.database().ref().child('Notifications/');
      ref.off('value');
      var ref2: any = firebase.database().ref().child('Quotes/');
      ref2.off('value');
      self.router.navigate(['/home']);
    }

  }

  getFirebaseData() {
    this.getCategoriesData();
    this.getSubCategoriesData();
    this.getReviewsData();
    this.getUsersData();
    this.getJobsData();

    if(this.userData && this.userData.uid) {
      this.getChatData();
      this.getUserNotifications();
      this.getQoutesData();
    }
  }

  getCurrentUsersData() {
    var self = this;
    firebase.database().ref().child('Users/' + self.userData.uid)
      .once('value', (snapshot) => {
        var user = snapshot.val();
        if (user && user.active) {
          self.setActiveUser(user);
        } else {
          self.toastr.error('Your account is blocked by Admin', '', { timeOut: 4000 })
          self.logoutUser();
        }

      });
  }

  getCategoriesData() {
    var self = this;

    firebase.database().ref().child('Categories/')
      .once('value', function (snapshot,) {
        self.categoriesList = [];
        var data = snapshot.val();
        if (data) {
          for (var key in data) {
            data[key].categoryId = key;
            if (!data[key].categoryImage) {
              data[key].categoryImage = self.catImage ;
            }
            data[key].subCategories = [];
            self.categoriesList.unshift(data[key]);
          }

          if (self.subCategoriesList && self.subCategoriesList.length) {
            self.updateSubCategoryNames();
          }
        }
      })
  }

  getSubCategoriesData() {
    var self = this;

    firebase.database().ref().child('SubCategories/')
      .once('value', function (snapshot,) {
        self.subCategoriesList = [];
        var data = snapshot.val();
        if (data) {
          for (var key in data) {
            data[key].subCategoryId = key;
            self.subCategoriesList.unshift(data[key]);
          }
        }
        self.updateSubCategoryNames();
      });
  }

  getReviewsData() {
    var self = this;

    firebase.database().ref().child('Reviews/')
      .once('value', function (snapshot,) {
        self.reviewsList = [];
        var data = snapshot.val();
        if (data) {
          for (var key in data) {
            data[key].reviewId = key;
            self.reviewsList.unshift(data[key]);
          }

          self.updateReviewUserName();
          if (self.usersList && self.usersList.length) {
            self.updateSubCategoryNames();
          }
        }
      })
  }

  getUsersData() {
    var self = this;
    firebase.database().ref().child('Users')
      .once('value', (snapshot) => {
        self.usersList = [];
        self.zone.run(() => {
          var data = snapshot.val();
          if (data) {
            for (var key in data) {
              if (!data[key].rating) {
                data[key].rating = 0;
              }
              self.usersList.push(data[key]);
            }
          }
          self.updateReviewUserName();
          self.updateUserCategoryName();
          self.usersList.sort((a, b) => (a.name.toLocaleLowerCase() >= b.name.toLowerCase()) ? 1 : -1);
          self.newUsersListner();
        })
      });
  }

  newUsersListner() {
    var self = this;

    var userListner;
    userListner = firebase.database().ref("Users");
    userListner.on("child_added", function (snapshot, previousChildKey) {

      self.zone.run(() => {
        var userObj = snapshot.val();
        let user = self.usersList.find(ob => ob['uid'] == userObj.uid);
        if (user) {
          return;
        }
        if (!userObj.rating) {
          userObj.rating = 0;
        }
        self.usersList.unshift(userObj);
      });

    });
  }

  getUserNotifications() {
    var self = this;
    self.notificationsList = [];
    firebase.database().ref().child('Notifications/')
      .on('value', function (snapshot,) {
        self.notificationsList = [];
        var data = snapshot.val();
        if (data) {
          for (var key in data) {

            if (data[key].receiverId.includes(self.userData.uid)) {
              data[key].notificationId = key;
              self.notificationsList.unshift(data[key]);
            }

          }
          self.notificationsList.sort((a, b) => (b.timeStamp >= a.timeStamp) ? 1 : -1);
          // self.updateReceiverNames();
        }
        self.notifyDataCheck.next();
      });
  }

  getChatData() {
    var self = this;
    firebase.database().ref().child('Chats')
      .on('value', (snapshot) => {
        self.chatsList = [];
        self.zone.run(() => {
          var data = snapshot.val();
          if (data) {
            for (var key in data) {
              if (data[key].users.includes(self.userData.uid)) {
                data[key].chatId = key
                self.chatsList.push(data[key]);
              }
            }
            self.chatsList.reverse();
          }
          self.chatDataCheck.next();
        })
      });
  }

  getJobsData() {
    var self = this;
    firebase.database().ref().child('Jobs')
      .once('value', (snapshot) => {
        self.jobsList = [];
        self.zone.run(() => {
          var data = snapshot.val();
          if (data) {
            for (var key in data) {
              data[key].jobId = key
              data[key].qouteCount = 0;
              self.jobsList.push(data[key]);
            }
            self.jobsList.reverse();
          }
          self.updateJobUserCategoryNames();
        })
      });
  }

  getQoutesData() {
    var self = this;
    self.qoutesList = [];
    firebase.database().ref().child('Quotes')
      .on('value', (snapshot) => {
        self.zone.run(() => {
          self.qoutesList = [];
          var data = snapshot.val();
          if (data) {
            for (var key in data) {
              data[key].qouteId = key
              self.qoutesList.push(data[key]);
            }
            self.qoutesList.reverse();
          }

          self.updateQouteJobsData();
        })
      });
  }

  updateSubCategoryNames() {
    var self = this;

    if (self.subCategoriesList && self.subCategoriesList.length && self.categoriesList && self.categoriesList.length) {

      for (var i = 0; i < self.subCategoriesList.length; i++) {
        var cIndex = self.categoriesList.findIndex(obj => obj.categoryId == self.subCategoriesList[i].categoryId);
        if (cIndex >= 0) {
          self.subCategoriesList[i].parentCategoryName = self.categoriesList[cIndex].categoryName;

          if (!self.categoriesList[cIndex].subCategories) {
            self.categoriesList[cIndex].subCategories = [];
          }
          self.categoriesList[cIndex].subCategories.push(self.subCategoriesList[i])
        }
      }
    }
    self.categoryDataCheck.next();
    self.loading = false;
  }

  // updateReceiverNames() {
  //   var self = this;
  //   if (self.notificationsList && self.notificationsList.length && self.usersList && self.usersList.length) {

  //     for (var i = 0; i < self.notificationsList.length; i++) {

  //       var temp = self.notificationsList[i];
  //       if (temp.receiverId[0] != 'All Users') {

  //         for (var j = 0; j < temp.receiverId.length; j++) {
  //           var uIndex = self.usersList.findIndex(obj => obj.uid == temp.receiverId[j]);
  //           if (uIndex >= 0) {
  //             self.notificationsList[i].receiverId[j] = self.usersList[uIndex].name;
  //           }
  //         }

  //       }

  //     }
  //   }

  //   self.notifyDataCheck.next();
  // }

  updateReviewUserName() {
    var self = this;

    if (self.reviewsList && self.reviewsList.length && self.usersList && self.usersList.length) {

      for (var i = 0; i < self.reviewsList.length; i++) {
        var uIndex = self.usersList.findIndex(obj => obj.uid == self.reviewsList[i].uid);
        if (uIndex >= 0) {
          self.reviewsList[i].ratedUserName = self.usersList[uIndex].name;
        } else {
          self.reviewsList[i].ratedUserName = '';
        }
      }
    }
    self.reviewDataCheck.next();
    self.loading = false;
  }

  updateUserCategoryName() {
    var self = this;

    if (self.usersList && self.usersList.length) {
      for (var i = 0; i < self.usersList.length; i++) {
        var subCategory = self.subCategoriesList.find(obj => obj.subCategoryId == self.usersList[i].subCategoryId);
        if (subCategory && subCategory.categoryName) {
          self.usersList[i].subCategoryName = subCategory.categoryName;
          self.usersList[i].categoryName = subCategory.parentCategoryName;
        } else {
          self.usersList[i].subCategoryName = '';
          self.usersList[i].categoryName = '';
        }
      }
    }

    self.userDataCheck.next();
    self.loading = false;
  }

  updateJobUserCategoryNames() {
    var self = this;

    if (self.jobsList && self.jobsList.length) {

      for (var i = 0; i < self.jobsList.length; i++) {

        var subCategoryIndex = self.subCategoriesList.findIndex(obj => obj.subCategoryId == self.jobsList[i].subCategoryId);
        if (subCategoryIndex >= 0) {
          self.jobsList[i].subCategoryName = self.subCategoriesList[subCategoryIndex].categoryName;
          self.jobsList[i].categoryName = self.subCategoriesList[subCategoryIndex].parentCategoryName;
        }

        var userIndex = self.usersList.findIndex(obj => obj.uid == self.jobsList[i].userId);
        if (userIndex >= 0) {
          self.jobsList[i].userName = self.usersList[userIndex].name;
          self.jobsList[i].onlySeeker = self.usersList[userIndex].onlySeeker;
          self.jobsList[i].userPhone = self.usersList[userIndex].phoneNo;
          self.jobsList[i].rating = self.usersList[userIndex].rating;
        }
      }
    }

    self.categoriesList.sort((a, b) => (a.categoryName >= b.categoryName) ? 1 : -1);

    self.jobDataCheck.next();
    self.loading = false;
  }

  updateQouteJobsData() {
    var self = this;

    if (self.jobsList && self.jobsList.length) {
      for (var i = 0; i < self.jobsList.length; i++) {
        self.jobsList[i].qouteCount = 0;
      }
    }

    self.newJobQoutes = 0;
    if (self.qoutesList && self.qoutesList.length) {
      for (var i = 0; i < self.qoutesList.length; i++) {

        var jobIndex = self.jobsList.findIndex(obj => obj.jobId == self.qoutesList[i].jobId);

        if (jobIndex >= 0) {
          self.jobsList[jobIndex].qouteCount++;
          // self.qoutesList[i].categoryName = self.jobsList[jobIndex].categoryName;
          // self.qoutesList[i].subCategoryName = self.jobsList[jobIndex].subCategoryName;
          self.qoutesList[i].jobPrice = self.jobsList[jobIndex].price;
          self.qoutesList[i].jobLocation = self.jobsList[jobIndex].location;

          if (self.jobsList[jobIndex].userId == self.userData.uid) {
            self.newJobQoutes++;
          }
        }

        var userIndex = self.usersList.findIndex(obj => obj.uid == self.qoutesList[i].userId);
        if (userIndex >= 0) {
          self.qoutesList[i].userName = self.usersList[userIndex].name;

          self.qoutesList[i].categoryName = self.usersList[userIndex].categoryName;
          self.qoutesList[i].subCategoryName = self.usersList[userIndex].subCategoryName;

          if(self.usersList[userIndex].profileUrl) {
            self.qoutesList[i].userProfile = self.usersList[userIndex].profileUrl;
          } else {
            self.qoutesList[i].userProfile = '/assets/imgs/person.svg';
          }
        }
      }
    }

    self.jobDataCheck.next();
    self.qouteDataCheck.next();
    self.loading = false;
  }

  countPostedTime(date) {

    // The number of milliseconds in one day
    var ONE_DAY = 1000 * 60 * 60 * 24

    // Convert both dates to milliseconds
    var date1_ms = new Date().getTime()
    var date2_ms = new Date(date).getTime()

    // Calculate the difference in milliseconds
    var difference_time = Math.abs(date1_ms - date2_ms)

    var timeSpent = ''
    if (difference_time / ONE_DAY >= 1) {
      timeSpent = Math.round(difference_time / ONE_DAY) + ' days'
    } else if (difference_time / (ONE_DAY / 24) >= 1) {
      timeSpent = Math.round(difference_time / (ONE_DAY / 24)) + ' hours'
    } else if (difference_time / (1000 * 60 * 60) >= 1) {
      timeSpent = Math.round(difference_time / (1000 * 60 * 60)) + ' minutes'
    } else if (difference_time / (1000 * 60) >= 1) {
      timeSpent = Math.round(difference_time / (1000 * 60)) + ' seconds'
    }

    return timeSpent;

  }

  checkMsgUser(uid) {
    this.selectedMsgUser = new iChat();
    this.selectedMsgUser.uid = uid;
    this.router.navigate(['/messages']);
  }

  getUserProfile(uid){
    var user = this.usersList.find(x=>x.uid==uid)
    return user?.profileUrl || '';
  }

}
