<ngx-loading [show]="service.loading"> </ngx-loading>
<section>

    <div class="row">
        <div class="col-sm-11 mx-auto px-0">
            <div class="row">
                <div class="col-sm-12 mt-3">
                    <h3>Welcome Back!</h3>
                    <h3>Enter your info to log in.</h3>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="my-input">Email Address</label>
                        <input class="form-control" type="text" [(ngModel)]="user.email">
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group mb-0">
                        <label for="my-input">Password</label>
                        <input class="form-control" type="password" [(ngModel)]="user.password">
                        <p class="text-right pointer mt-2 mb-0" data-dismiss="modal" routerLink="/forget-password">
                            <small>Forget Password?</small></p>
                    </div>
                </div>
                <div class="col-sm-12">
                    <button class="btn btn-primary mb-2" type="button" [disabled]="!user.email || !user.password"
                        (click)="loginAccount()">Login</button>
                </div>

            </div>
        </div>
    </div>
    <div class="row form-footer">
        <div class="col-sm-11 mx-auto">
            <p>Don't have an Account?
                <span class="pl-2 primary pointer" id="closeLoginMd" data-dismiss="modal" routerLink="/signup">Sign
                    Up</span>
            </p>
        </div>
    </div>

</section>