<app-navigation></app-navigation>
<ngx-loading [show]="service.loading"> </ngx-loading>
<section class="main-cont">
  <section class="section-1 page-title">
    <div class="row">
      <div class="col-sm-12">
        <h4>All Chats</h4>
      </div>
    </div>
  </section>
  <section class="section-2">
    <div class="row">
      <div class="col-sm-5 users">
        <div class="card">
          <div class="row pointer" (click)="service.selectedMsgUser=chat; newMsg.text='';scrollChatDiv() "
            style="border-bottom: 2px solid gray;" *ngFor="let chat of myChatList; let i=index">
            <div class="col-sm-2 user-image">
              <img [src]="chat.userProfile || '/assets/imgs/person.svg'" class="userImg">
              <!-- <img src="/assets/imgs/dot-online.svg" class="online" alt=""> -->
            </div>
            <div class="col-sm-10">
              <div class="d-flex justify-content-between">
               <div>
                <p class="mb-0">{{chat.userName | titlecase}}</p>
                <p class="mb-0 primary f-12">{{chat.subCategoryName | titlecase}}</p>
               </div>
                <p class="mb-0">
                  <small>{{chat.deletedChat | date:'medium'}}</small>
                </p>
                <span class="ml-1" (click)="userName=chat.userName; delChatId=chat.chatId" data-toggle="modal"
                  data-target="#delChatModal">
                  <i class="fas fa-ellipsis-v pointer"></i>
                </span>
              </div>
              <div>
                <p class="mb-1 descText"><small *ngIf="chat.messages && chat.messages.length">{{chat.messages[0].text}}</small></p>
              </div>
            </div>
          </div>

          <div *ngIf="!myChatList || !myChatList.length">
            You did not start chat with any user. <br/>
            Click <a  routerLink="/search-providers">here</a> to search a service provicer.
          </div>
        </div>
      </div>

      <div class="col-sm-7 chat">
        <div class="card">
          <div class="d-flex chat-header mb-2" *ngIf="service.selectedMsgUser && service.selectedMsgUser.uid">
            <div class="user-image">
              <img [src]="service.selectedMsgUser.userProfile || '/assets/imgs/person.svg'" class="userImg">
              <!-- <img src="/assets/imgs/dot-online.svg" class="online" alt=""> -->
            </div>
            <div class="ml-2">
              <div class="d-flex justify-content-between">
                <div>
                  <p class="mb-0">{{service.selectedMsgUser.userName | titlecase}}</p>
                  <p class="mb-0 primary f-12">{{service.selectedMsgUser.subCategoryName | titlecase}}</p>
                  <!-- <p><small>Online</small></p> -->
                </div>
              </div>
            </div>
          </div>

          <div class="chat-body" id="chatBoxId"
            *ngIf="service.selectedMsgUser && service.selectedMsgUser.uid">

            <div class="row">
              <div class="col-sm-12 left-message mt-0 mb-1"
              [class.right-message]="service.selectedMsgUser.messages[service.selectedMsgUser.messages.length-1-j].uid==service.userData.uid"
              *ngFor="let msg of service.selectedMsgUser.messages; let j=index">

              <div class="py-1">
                <p class="mb-0" style="word-break: break-word;">{{service.selectedMsgUser.messages[service.selectedMsgUser.messages.length-1-j].text}}
                </p>
                <p class="mb-2" *ngIf="service.selectedMsgUser.messages[service.selectedMsgUser.messages.length-1-j].textImage">
                  <img class="mb-0" [src]="service.selectedMsgUser.messages[service.selectedMsgUser.messages.length-1-j].textImage" 
                  style="max-width:200px; height: auto;"/> 
                 
                </p>
               
                <p class="mb-0 dateTime">
                  {{service.selectedMsgUser.messages[service.selectedMsgUser.messages.length-1-j].timeStamp | date:'short'}}
                </p>
              </div>

            </div>
          </div>
           
          </div>
        </div>

        <div class="card-2" *ngIf="service.selectedMsgUser && service.selectedMsgUser.uid">
          <div class="row" >
            <div class="col-sm-11 col-10 d-flex pl-1">
              <!-- <img src="/assets/imgs/collapse.svg" style="margin-top: 13px;" alt=""> -->

              <img [src]="imgURL || '/assets/imgs/collapse.svg'" (click)="SelectFiles0.click()" style="width: 60px; height: auto; border-radius: 5px;" alt="">
              <input #SelectFiles0 class="d-none" type="file" accept="image/*" (change)="onChangeFile($event)">

            <!-- </div>
            <div class="col-sm-10"> -->
              <div class="form-group ml-2 w-100">
                <textarea id="my-input" class="form-control" rows="1" [(ngModel)]="newMsg.text"
                (keydown)="keyDownFunction($event)" >
                </textarea>
              </div>
            </div>
            <div class="col-sm-1 col-2 px-0">
              <button class="btn btn-primary py-1" style="margin-top: 8px;" [disabled]="!newMsg.text && (!imageFile || !imgURL)"
                (click)="sendNewMessage()">
                <img src="/assets/imgs/Send.svg" alt="">
              </button>

            </div>
          </div>

        </div>

      </div>
    </div>


  </section>
</section>



<!--remove Chat Modal-->
<div class="modal fade" id="delChatModal" tabindex="-1" aria-labelledby="delChatModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteModalLabel">Delete Chat</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <img src="/assets/imgs/CrossIcon.png" height="100px;">
        <p>Are you sure? Do you want to delete Chat with : <strong>{{userName | titlecase}}</strong> ?</p>
      </div>

      <div class="modal-footer text-center" style="display: block !important;">
        <button type="button" class="btn btn-secondary" id="closeDelChatMd" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-danger" (click)="deleteChat(delChatId)">Remove</button>
      </div>

    </div>
  </div>
</div>
