<app-navigation></app-navigation>
<section class="main-cont">
  <section class="section-1 page-title">
    <div class="row">
      <div class="col-sm-12">
        <h4>Terms & Conditions</h4>
      </div>
    </div>
  </section>
  <section class="section-2">
    <!-- <div class="row">
      <div class="col-sm-12">
          <div class="text-center">
            <h5 class="bold mx-auto">MATES RATES TERMS AND CONDITIONS</h5>
            <p><span class="bold">Last updated: </span> 12 October 2021</p>
            <i>By accessing or using the Platform, you agree to be legally bound by these Mates Rates Terms and Conditions. Please read this document carefully.</i>
          </div>
          <div class="mb-3">
              <p>These Mates Rates Terms and Conditions (the “<b>Terms</b>”) is a legally binding agreement by and between an individual user or entity (“<b>you</b>”, “<b>your</b>”, and “<b>user</b>”) accessing or using the website https://matesrates.site, the software application ‘Mates Rates’, and the related services (collectively, the “<b>Platform</b>” or “<b>Mates Rates</b>”) and the owner and operator of the Platform, namely, Chris Mansour having an address at Epping VIC 3076, Australia, and the ABN number 646 656 377 (the “<b>Owner</b>”, “<b>we</b>,” “<b>us</b>,” and “<b>our</b>”). These Terms apply to the Platform only; the Terms do not apply to any third-party software, websites, and services integrated with the Platform. You must agree to all provisions of these Terms to be eligible to access or use the Platform. If you do not agree with one or more provisions of these Terms, you are not allowed to use the Platform.</p>
          </div>
          <div class="listNumber">
              <ol>
                  <li><span>Accounts</span><span>2</span></li>
                  <li><span>Purpose of the Platform</span><span>4</span></li>
                  <li><span>Contractual Relationships Between the Contractors</span><span>6</span></li>
                  <li><span>Service Fees</span><span>8</span></li>
                  <li><span>Non-Circumvention</span><span>8</span></li>
                  <li><span>Records of Compliance</span><span>9</span></li>
                  <li><span>Warranty Disclaimer</span><span>9</span></li>
                  <li><span>Limitation of Liability</span><span>10</span></li>
                  <li><span>Release</span><span>10</span></li>
                  <li><span>Indemnification</span><span>10</span></li>
                  <li><span>Term and Termination</span><span>11</span></li>
                  <li><span>Disputes Between You And Us</span><span>11</span></li>
                  <li><span>Intellectual Property</span><span>12</span></li>
                  <li><span>Final Provisions</span><span>13</span></li>
                  <li><span>Contact</span><span>14</span></li>
              </ol>
          </div>
      </div>
    </div> -->

    <div class="row">
        <div class="col-sm-12">
            <div class=Section1>

                <p class=Heading style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%;mso-pagination:widow-orphan lines-together'><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                font-weight:normal'></span></p>
                
                <p class="BodyA" align=center style='text-align:center;line-height:120%'><b><span
                lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>MATES RATES TERMS AND CONDITIONS</span></b><b><span
                lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:NL'></span></b></p>
                
                <p class="BodyA" align=center style='text-align:center;line-height:120%'><b><span
                lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>Last updated:</span></b><span lang=NL style='font-size:
                12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'> 12 October 2021</span><span lang=NL style='font-size:12.0pt;line-height:
                120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic";mso-ansi-language:NL'></span></p>
                
                <p class="BodyA" style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-size:12.0pt;line-height:120%;font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class="BodyA" align=center style='margin-left:14.2pt;text-align:center;
                line-height:120%;tab-stops:418.2pt'><i><span style='font-size:12.0pt;
                line-height:120%;font-family:"Century Gothic","sans-serif"'>By accessing or
                using</span></i><i><span lang=NL style='font-size:12.0pt;line-height:120%;
                font-family:"Century Gothic","sans-serif";mso-ansi-language:NL'> the Platform</span></i><i><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>,
                you agree to be legally bound by </span></i><i><span lang=NL style='font-size:
                12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>these Mates Rates Terms and Conditions</span></i><i><span style='font-size:
                12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>. Please
                read </span></i><i><span lang=NL style='font-size:12.0pt;line-height:120%;
                font-family:"Century Gothic","sans-serif";mso-ansi-language:NL'>this document </span></i><i><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>carefully.</span></i><i><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></i></p>
                
                <p class="BodyA" style='margin-left:14.2pt;text-align:justify;text-justify:inter-ideograph;
                line-height:120%;tab-stops:418.2pt'><i><span style='font-size:12.0pt;
                line-height:120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></i></p>
                
                <p class=BodyB style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%;tab-stops:418.2pt'><span style='font-family:"Century Gothic","sans-serif"'>Th</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>ese Mates Rates Terms and Conditions</span><span style='font-family:"Century Gothic","sans-serif"'>
                (the "</span><b><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>Terms</span></b><span style='font-family:"Century Gothic","sans-serif"'>"</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>) is a legally binding agreement by and between an individual user or
                entity ("<b>you</b>", "<b>your</b>", and "<b>user</b>") accessing or using the
                website </span><span lang=PT style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:PT'>https://matesrates.site</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:NL'>, the
                software application "Mates Rates", and the related services (collectively, the
                "<b>Platform</b>" or "<b>Mates Rates</b>") and the owner and operator of the
                Platform, namely, </span><span style='font-family:"Century Gothic","sans-serif"'>Chris
                Mansou</span><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>r</span><span style='font-family:"Century Gothic","sans-serif"'>having
                a</span><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>n address at </span><span lang=IT style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:IT'>Epping </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>VIC </span><span style='font-family:"Century Gothic","sans-serif"'>307</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>6, Australia, and the ABN number </span><span lang=IT style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:IT'>646 656 377</span><span
                style='font-family:"Century Gothic","sans-serif"'>(</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:NL'>the </span><span
                style='font-family:"Century Gothic","sans-serif"'>"</span><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:NL'>Owner</span></b><span
                style='font-family:"Century Gothic","sans-serif"'>"</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:NL'>, "<b>we</b>,"
                "<b>us</b>," and "<b>our</b>"</span><span style='font-family:"Century Gothic","sans-serif"'>)</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>. These Terms apply to the Platform only; the Terms do not apply to any
                third-party software, websites, and services integrated with the Platform. You
                must agree to all provisions of these Terms to be eligible to access or use the
                Platform.</span><span style='font-family:"Century Gothic","sans-serif"'> If you
                do not agree with one or more provisions </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:NL'>of these Terms</span><span
                style='font-family:"Century Gothic","sans-serif"'>, you </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:NL'>are not
                allowed to</span><span style='font-family:"Century Gothic","sans-serif"'> use </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>the Platform</span><span style='font-family:"Century Gothic","sans-serif"'>.</span><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>




<div class="listNumber">
  <ol>
      <li><div>
        <span>Accounts</span><span>2</span>
      </div></li>
      <li><div>
        <span>Purpose of the Platform</span><span>4</span>
      </div></li>
      <li><div><span>Contractual Relationships Between the Contractors</span><span>6</span></div></li>
      <li><div><span>Service Fees</span><span>8</span></div></li>
      <li><div><span>Non-Circumvention</span><span>8</span></div></li>
      <li><div><span>Records of Compliance</span><span>9</span></div></li>
      <li><div><span>Warranty Disclaimer</span><span>9</span></div></li>
      <li><div><span>Limitation of Liability</span><span>10</span></div></li>
      <li><div><span>Release</span><span>10</span></div></li>
      <li><div><span>Indemnification</span><span>10</span></div></li>
      <li><div><span>Term and Termination</span><span>11</span></div></li>
      <li><div><span>Disputes Between You And Us</span><span>11</span></div></li>
      <li><div><span>Intellectual Property</span><span>12</span></div></li>
      <li><div><span>Final Provisions</span><span>13</span></div></li>
      <li><div><span>Contact</span><span>14</span></div></li>
  </ol>
</div>


<!--                            
                <p class=MsoToc1 style='margin-left:27.0pt;text-indent:-27.0pt;mso-list:l2 level1 lfo1'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Accounts<span style='mso-tab-count:
                1'></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>2</span></p>
                
                <p class=MsoToc1 style='margin-left:27.0pt;text-indent:-27.0pt;mso-list:l2 level1 lfo2'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Purpose of the Platform<span
                style='mso-tab-count:1'></span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>4</span></p>
                
                <p class=MsoToc1 style='margin-left:27.0pt;text-indent:-27.0pt;mso-list:l2 level1 lfo3'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Contractual Relationships Between the
                Contractors<span style='mso-tab-count:1'>"""""" </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>6</span></p>
                
                <p class=MsoToc1 style='margin-left:27.0pt;text-indent:-27.0pt;mso-list:l2 level1 lfo4'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Service Fees<span style='mso-tab-count:
                1'>"""""""" </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>8</span></p>
                
                <p class=MsoToc1 style='margin-left:27.0pt;text-indent:-27.0pt;mso-list:l2 level1 lfo5'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Non-Circumvention<span
                style='mso-tab-count:1'>"""""" </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>8</span></p>
                
                <p class=MsoToc1 style='margin-left:23.15pt;text-indent:-23.15pt;mso-list:l2 level1 lfo6'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Records of Compliance<span
                style='mso-tab-count:1'>" </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>9</span></p>
                
                <p class=MsoToc1 style='margin-left:27.0pt;text-indent:-27.0pt;mso-list:l2 level1 lfo7'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Warranty Disclaimer<span
                style='mso-tab-count:1'>""" </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>9</span></p>
                
                <p class=MsoToc1 style='margin-left:27.0pt;text-indent:-27.0pt;mso-list:l2 level1 lfo8'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Limitation of Liability<span
                style='mso-tab-count:1'> </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>10</span></p>
                
                <p class=MsoToc1 style='margin-left:27.0pt;text-indent:-27.0pt;mso-list:l2 level1 lfo9'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Release<span style='mso-tab-count:
                1'>""""" </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>10</span></p>
                
                <p class=MsoToc1 style='margin-left:27.0pt;text-indent:-27.0pt;mso-list:l2 level1 lfo10'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Indemnification<span style='mso-tab-count:
                1'>" </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>10</span></p>
                
                <p class=MsoToc1 style='margin-left:27.0pt;text-indent:-27.0pt;mso-list:l2 level1 lfo11'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Term and Termination<span
                style='mso-tab-count:1'>"""""""" </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>11</span></p>
                
                <p class=MsoToc1 style='margin-left:27.0pt;text-indent:-27.0pt;mso-list:l2 level1 lfo12'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Disputes Between You And Us<span
                style='mso-tab-count:1'> </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>11</span></p>
                
                <p class=MsoToc1 style='margin-left:27.0pt;text-indent:-27.0pt;mso-list:l2 level1 lfo13'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Intellectual Property<span
                style='mso-tab-count:1'>" </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>12</span></p>
                
                <p class=MsoToc1 style='margin-left:27.0pt;text-indent:-27.0pt;mso-list:l2 level1 lfo14'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Final Provisions<span
                style='mso-tab-count:1'>" </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>13</span></p>
                
                <p class=MsoToc1 style='margin-left:27.0pt;text-indent:-27.0pt;mso-list:l2 level1 lfo15'><span
                style='mso-hansi-font-family:"Arial Unicode MS";text-decoration:none;
                text-underline:none'><span style='mso-list:Ignore'>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='mso-fareast-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Arial Unicode MS"'>Contact<span style='mso-tab-count:
                1'>""""" </span></span><span style='mso-fareast-font-family:
                "Arial Unicode MS";mso-bidi-font-family:"Arial Unicode MS"'>14</span></p>
                
                <p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%;tab-stops:418.2pt'><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=BodyB style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%;tab-stops:418.2pt'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p> -->
                
                <!-- <p class=Heading style='margin-left:27.0pt;text-indent:-27.0pt;line-height:
                120%;mso-list:l1 level1 lfo17'><a name="_Toc"><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'>1.</span></span><u style='text-underline:#004D80'><span class="pl-2"
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>Accounts</span></u></a><span
                style='mso-bookmark:_Toc'></span><span style='font-size:12.0pt;line-height:
                120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p> -->
                <p class="bold">1. <span class="pl-2"><u>Accounts</u></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><i><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Section 1 provides an overview of the requirements
                that you must meet before registering your user account on the Platform. </span></i><i><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic";mso-ansi-language:EN-US'></span></i></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>1.1.Registration and acceptance</span></b><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>.</span></b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>To use the full
                functionality of the Platform, you must create </span><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>a user account (the "</span><b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Account</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>").
                During the registration process,</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                you </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>will be asked to submit </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>your
                personal details (e.g., f</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>ull name, email address, phone number, suburb and
                postcode, and profession or Trade)</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>, </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>read
                the Mates Rates Privacy Policy, and </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>accept</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                these Terms</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>. </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>We
                </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>reserve the right to decline the registration</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'> of any Account for any
                reason whatsoever, at our sole discretion. </span><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'></span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Account eligibility</span></b><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>.</span></b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>B</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>y
                registering the Account, </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>you
                </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>acknowledge and agree </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>that:</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'></span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:
                "Century Gothic"'><span style='mso-list:Ignore'>i.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>You
                h</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>ave
                read the documents specified in section 1.1 and agree to abide by them;</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:
                "Century Gothic"'><span style='mso-list:Ignore'>ii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>You
                a</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>gree to
                comply</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> with all provisions of </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>these
                Terms </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>and all applicable laws; </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'></span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:
                "Century Gothic"'><span style='mso-list:Ignore'>iii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>You
                h</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>ave the
                capacity to conclude legally binding agreements;</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:
                "Century Gothic"'><span style='mso-list:Ignore'>iv.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>You
                w</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>ill </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>provide
                full, complete</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>,</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> and
                accurate information</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>, including personal data,</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>and agree to amend it as soon
                as any changes occur;</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:
                "Century Gothic"'><span style='mso-list:Ignore'>v.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>You
                will be</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>solely responsible for any activity that occurs
                through</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                your</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> Account and you shall not hold </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>us</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> liable in this regard, for any reason whatsoever</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>;</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>vi.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>You
                are not under any type of judicial interdiction;</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>vii.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>You
                will register a single Account (multiple Accounts registered by the same person
                or entity are not allowed); and</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>viii.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>You
                are a human individual or an individual acting on behalf of a business entity,
                and not a machine (machine-generated Accounts are not allowed).</span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Authorisation.
                </span></b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>If
                you act</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> on behalf of a business entity in accepting</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'> these Terms or
                registering the Account, you </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>warrant and undertake that </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>you have </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>the
                requisite power and authority to act on behalf of that e</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>ntity</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> and
                bind the e</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>ntity</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> to </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>these Terms</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>. By
                creating the Account and providing details of a business e</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>ntity</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>, </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>you </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>confirm
                that </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>you
                are </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>an authorised employee, contractor, or representative of the business e</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>ntity </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>and
                ha</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>ve</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> the
                necessary rights and authority to act on behalf of that e</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>ntity</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>. We
                are</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'> not </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>responsible
                in any manner and bear no liability for </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>your</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> activities carried out without such authorisation.</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'></span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>The
                </span></b><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Profile</span></b><b><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>To complete the registration of the Account, you will
                be requested to complete your user profile (the "</span><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>Profile</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>").
                You acknowledge and agree the Profile can be visible to other users of the
                Platform and Internet users, unless you adjust your privacy settings
                accordingly. You must (i) provide true, complete, accurate, and up-to-date
                information when completing your Profile and (ii) update the Profile
                information as soon as any changes thereto occur. You are not allowed to
                provide any information about yourself or any third parties that is false, misleading,
                defamatory, or erroneous, including, without limitation, information about your
                identity, location, education, skills, professional experience, clients,
                certifications, business, or the services that you provide. We do not verify
                all Profile information and, therefore, we are not responsible for the
                truthfulness, completeness, and accuracy of the said information. We reserve
                the right to suspend or terminate any Account or Profile that provides false or
                misleading information or otherwise violates any provision of these Terms. </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>The Platform </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>provides
                general information about the users and displays the Profiles as created by the
                users. </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>We
                do</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> not endorse any users of </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                Platform </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>and </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                Platform</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> features only a limited list of the available</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'> service providers in
                the given area. </span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Account types</span></b><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>. </span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>You
                may register two types of Accounts on the Platform as specified below. Please
                note that, if the functionality of the Platform allows it, some Accounts may be
                added or merged by using a single username and password. The following types of
                the Accounts are available on the Platform:</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:
                "Century Gothic"'><span style='mso-list:Ignore'>i.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>TheServiceProvider
                Account. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The Service Provider Account can be registered by the
                users that intend to offer their services through the Platform as independent
                contractors (the "<b>Service </b></span><b><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>Providers</span></b><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>"); and</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'></span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:
                "Century Gothic"'><span style='mso-list:Ignore'>ii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>The
                Client Account.</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> The Client Account can be registered by the users
                that intend to search and order services offered by the Service Providers
                through the Platform (the "</span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Clients</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>").</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'></span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Identity and location verification</span></b><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>.</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> We
                reserve the right but are not under obligation to verify the Accounts and the
                Profiles, including, without limitation, verifying the identity, location,
                payment methods, and email addresses, during the registration of the Account or
                from time to time thereafter. The verification may include requesting official
                documents (e.g., government issued ID) confirming your identity, location, and
                authorisation to act on behalf of a business entity. You agree to supply us the
                requested information to keep your Account active. We reserve the right to
                temporarily suspend your Account during the verification process and terminate
                the Account if the verification fails.</span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'></span></p>
                
                <!-- <p class=Heading style='margin-left:27.0pt;text-indent:-27.0pt;line-height:
                120%;mso-list:l1 level1 lfo17'><a name="_Toc1"><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'>2.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><u style='text-underline:#004D80'><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>Purpose
                of </span></u></a><span style='mso-bookmark:_Toc1'><u style='text-underline:
                #004D80'><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:
                "Century Gothic","sans-serif";mso-ansi-language:NL'>the Platform</span></u></span><span
                style='mso-bookmark:_Toc1'></span><span style='font-size:12.0pt;line-height:
                120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p> -->
                <p class="bold">2. <span class="pl-2"><u>Purpose of the Platform</u></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><i><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Section 2 discusses the rules governing the
                relationship between </span></i><i><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                Owner</span></i><i><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> and the users of the Platform. </span></i><i><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></i></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Your
                r</span></b><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>elationship </span></b><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>with us. </span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>The
                Owner provides the Platform for the purpose of facilitating transactions
                between the Clients and the Service Providers (collectively, the "</span><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>Contractors</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>"),
                including negotiating and concluding service contracts (the "</span><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>Service Contract</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>"). </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>We do </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>not
                intervene into the communication between the Contractors as well as
                negotiation, conclusion, and execution of the</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'> Service Contracts. </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Unless
                explicitly specified otherwise on</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                the Platform, our</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> responsibilities with regard to the </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>Service</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                Contracts are limited to facilitating the availability of </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>the Platform</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>. By
                using</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                the Platform, the Client </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>acknowledge</span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>s</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> and agree</span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>s</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> that the Service Provider and not </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>the Owner is </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>solely
                responsible for </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>providing
                services under the Service Contracts and any information or warranties related
                thereto. We are not a party to the Service Contract</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> and,
                therefore, </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>we
                are</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> not liable for any direct, indirect, consequential or inconsequential
                loss or damage that results from the </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>Service</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> Contracts and any business transactions made between
                the Contractors as a result of their interactions through </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>the Platform. The Contractors
                are entitled to (i) enter into additional agreements governing the Service
                Contracts (e.g. non-disclosure agreements) and (ii) agree on the terms and
                conditions of the Services Contracts, provided that (i) the agreed terms shall
                not expand or amend these Terms and (ii) in case there is a conflict between
                the terms of the Service Contract and these Terms, the Terms shall prevail. </span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Responsibilities
                of the Service Providers.</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The Service Providers are solely responsible for:</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'></span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>i.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Ensuring that they are qualified in providing services
                </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>under
                the Service Contracts;</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'></span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>ii.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Preparing, negotiating, concluding, and executing the </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>Service </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Contracts;
                and</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>iii.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Cooperating
                with t</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>he
                Owner</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> in any audits by providing information and records about the </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>Service</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                Contracts, invoices, tax returns, and other financial reports issued under the </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>Service</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                Contracts.</span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Fees,
                t</span></b><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>axes and benefits</span></b><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>.</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> The
                users of the Platform are responsible for paying all fees, tax and duties with
                regard to the Service Contracts as applicable in their respective
                jurisdictions; the Owner does not withhold any taxes related thereto. The
                Service Provider is solely responsible for:</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'></span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:
                "Century Gothic"'><span style='mso-list:Ignore'>i.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Accepting
                the fees under the Service Contracts;</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>ii.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Paying all applicable taxes, levies, duties, and other
                fees associated with payments made under the </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>Service</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                Contracts;</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>iii.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Obtaining
                appropriate insurance related to the Service Provider"s services (e.g.,
                liability, health, compensation, social security, disability, and unemployment)
                and making sure that the terms and conditions of the insurance policy do not
                contravene to the Terms;</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>iv.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Determining
                whether necessary and, if necessary, issuing invoices for any the services
                rendered under the Service Contracts;</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>v.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Determining whether necessary and, if necessary,
                paying value added tax (or its equivalent) in the Service Provider"s
                jurisdiction, maintaining records as required by tax authorities, and
                submitting records to relevant authorities, as appropriate; and</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>vi.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Upon
                the Owner"s request, cooperating with the Owner in any audits by providing
                information and records about the Service Contracts, Service Provider"s
                independent business activities, invoices, tax returns, and other financial reports
                related to Service Provider"s transactions on the Platform.</span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Feedback</span></b><b><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The users are entitled to leave feedback about other
                users of the Platform in the form of reviews, comments, indicators of
                satisfaction, or ratings (collectively, the "</span><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>Feedback</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>").
                None of the references provided by the Owner or the users of the Platform in
                relation to any user of the Platform, including the Feedback, represents
                endorsement, certification or guarantee about any user, as well as the
                information or services provided by that user. The said references are based
                solely on unverified data that the users voluntarily provide; the Platform
                features references solely for the convenience of the users. We strongly
                encourage the Clients not to conclude any Service Contracts if the Clients have
                any doubts or concerns pertaining to the Service Providers. The users
                acknowledge and agree that:</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>i.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Provision of the Feedback is essential for the
                efficiency of the Platform, therefore, the Feedback must be submitted after
                completion of each Service Contract;</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>ii.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The Feedback will be available to all users of the
                Platform and a part of it may also be visible to the Internet users that are
                not registered on the Platform;</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>iii.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>The
                Feedback must not contain any false, misleading, defamatory or unlawfully disclosed
                information;</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>iv.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>The
                Owner does not control, monitor, influence, contribute to or censor the
                Feedback; </span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>v.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The Owner is not responsible for the accuracy of the
                Feedback, even if the information in the Feedback violates this Agreement;</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>vi.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>The
                Owner reserves the right but is not under any obligation to remove the Feedback
                that, in the Owner"s sole judgement, violates these Terms or has an adverse
                effect on the reputation of the Platform or our business interests; and </span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>vii.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>The
                Feedback cannot be used for making employment, credit, underwriting, or similar
                decisions regarding the users.</span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'></span></p>
                
                <!-- <p class=Heading style='margin-left:27.0pt;text-indent:-27.0pt;line-height:
                120%;mso-list:l1 level1 lfo17'><a name="_Toc2"><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'>3.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><u style='text-underline:#004D80'><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>Contractual
                Relationship</span></u></a><span style='mso-bookmark:_Toc2'><u
                style='text-underline:#004D80'><span lang=NL style='font-size:12.0pt;
                line-height:120%;font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>s</span></u></span><span style='mso-bookmark:_Toc2'><u style='text-underline:
                #004D80'><span style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>
                Between </span></u></span><span style='mso-bookmark:_Toc2'><u style='text-underline:
                #004D80'><span lang=NL style='font-size:12.0pt;line-height:120%;font-family:
                "Century Gothic","sans-serif";mso-ansi-language:NL'>the Contractors</span></u></span><span
                style='mso-bookmark:_Toc2'></span><span style='font-size:12.0pt;line-height:
                120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p> -->
                <p class="bold">3. <span class="pl-2"><u>Contractual Relationships Between the Contractors</u></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><i><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Section 3 contains the terms and conditions with
                regard to the Service Contracts concluded between the </span></i><i><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>Contractors </span></i><i><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>through
                the Platform.</span></i><i><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></i></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><b><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Service Contracts. </span></b><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>If the Contractors
                decide to enter into the </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Service</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                Contract through</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                the Platform</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>, the </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Service
                </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>Contract is the contractual relationship between the Contractors and the
                Contractors have complete discretion with regard </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>to </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>whether
                to enter into the </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Service
                </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>Contract and the terms of the </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>Service </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Contract. The Contractors acknowledge and agree that </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>we are</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> not
                a party to the </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Service</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                Contract</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>s</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> and
                the formation of the </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Service
                </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>Contract</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>s</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> does
                not create employment, partnership, joint venture, or other service
                relationships between the Contractors and</span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'> us. </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The Contractors </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>are entitled to </span><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>enter into any
                agreements as they deem to be appropriate (e.g., confidentiality or assignment
                agreements), provided that such agreements do not conflict with, narrow, or
                expand </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>these
                Terms. </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>By concluding the </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>Service </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Contracts
                through </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                Platform</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>, the </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Client
                </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>acknowledges and agrees that the </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>Client </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>is purchasing services from the respective Service
                Provider and not directly from </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>us</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>, and
                the Service Provider is solely responsible for delivering those services.
                Therefore, for any specifics related to the </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>Service</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                Contracts, the </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Client</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>is
                requested to contact the respective Service Provider directly</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>. </span><b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'></span></b></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><b><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Checks
                of the Service Providers.</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The Client</span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'> is </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>solely responsible for carrying out appropriate checks
                regarding the Service Providers, including</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>, without limitation,</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                their relevant trade and industry accreditations, qualifications, legal </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>authorisations</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>, the
                scope of insurance</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>,
                and availability schedule</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> prior to concluding the </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>Service </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Contracts.</span><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'></span></b></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><b><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Abuse
                of the Service Contracts. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>users</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                agree to report any abuse related to the Service Contracts without undue delay
                to us. We reserve the right but are no</span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>t</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> under </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>any
                </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>obligation to investigate the reported abuse and impose proportional
                sanctions on the user</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                suspected to abuse</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>. We shall not be responsible for any late </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>or incorrect </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>reporting
                of abuse.</span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'></span></b></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><b><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Disputes</span></b><b><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'> between the users.</span></b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>We are</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> not
                responsible for any disputes that arise between the users, nor </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>are we</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                obliged to receive or process complaints against the users of </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>the Platform</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> or
                resolve disputes between the users, unless the complaint concerns the
                performance of </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>our
                </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>legal or contractual obligations under these Terms. In case you intend
                to obtain an order from any arbitrator or any court that might direct us to
                take any action regarding the dispute, you agree to: (i) give us at least 10
                (ten) business days notice of the hearing and (ii) pay to us in full the fees
                corresponding to the reasonable value of the services to be rendered pursuant
                to the order.<b></b></span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Confidential information</span></b><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>.</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> The
                users agree to keep in confidentiality and not to use, disclose or communicate
                in any manner any proprietary information about the Service Contracts, each
                other, their officers, directors, employees, </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>contractors,</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                agents, operations, marketing strategies, software, codes, passwords and login
                information to email accounts, web pages, phone systems, ticket systems,
                pricing structure, clientele, or any other proprietary information that relate</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>s</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> to
                the users. The said information is material and confidential.</span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Deliverables.</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> Any
                and all deliverables arising from or related to the Service Contacts,
                including, but not limited to, any </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>digital content, </span><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>documents, texts,
                photographs, images, graphic designs, logos, marketing materials, source code,
                reports, decks, presentations, strategies and ideas, audio and video materials,
                or any other materials and services commissioned by </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>the Client</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> from
                the Service Providers (collectively, the "<b>Deliverables</b>") shall be deemed
                to be works for hire and shall be the sole and exclusive property of </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>the Clients, unless
                agreed otherwise by the Contractors</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>. The Deliverables shall be delivered within the time
                period agreed upon by </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>and
                between the Contractors</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>. Unless permitted by the Client, the Service Provider
                may not copy, reproduce, publish, transmit, transfer, sell, rent, modify,
                create derivative works from, distribute, repost, perform, display, or in any
                way commercially exploit the Deliverables without a prior written authorisation
                from </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                Client.</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> In the event a court of law or an arbitration panel
                finds section 3.6 to be invalid or unenforceable in any respect and for any
                reason, the Service Provider hereby grants to </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>the Client </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>non-exclusive,
                perpetual, royalty-free, irrevocable and worldwide rights, with rights to
                sub-license through multiple levels of sub-licenses, to reproduce, make
                derivative works from, distribute, publicly perform, and publicly display in
                any form or medium, whether now known or later developed, make, have made, use,
                sell, import, offer for sale, and exercise any and all present or future rights
                in the Deliverables. </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'></span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Messages. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The Contractors agree to keep in confidentiality and
                protect all information, including, without limitation, communication data and
                digital files, exchanged through the "messages" functionality available on the
                Platform. On the disclosing party"s request, the receiving party shall promptly
                destroy or return all confidential information.</span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Independent
                relationship. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The Service Provider provides services to </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>the Client as an
                independent contractor and not as an employee. The relationship between the
                Owner, the Service Providers, and the Clients shall be that of</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                independent</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                contractors </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>and not employees or employers.<span
                style='mso-spacerun:yes'>" </span></span><span lang=NL style='font-family:"Century Gothic","sans-serif"'></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'></span></p>
                
                <!-- <p class=Heading style='margin-left:27.0pt;text-indent:-27.0pt;line-height:
                120%;mso-list:l1 level1 lfo17'><a name="_Toc3"><span
                lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic";mso-ansi-language:NL'><span
                style='mso-list:Ignore'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><u><span lang=NL style='font-size:12.0pt;
                line-height:120%;font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>Service </span></u></a><span style='mso-bookmark:_Toc3'><u
                style='text-underline:#004D80'><span lang=NL style='font-size:12.0pt;
                line-height:120%;font-family:"Century Gothic","sans-serif";mso-ansi-language:
                NL'>Fees</span></u></span><span style='mso-bookmark:_Toc3'></span><span
                lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:NL'></span></p> -->
                <p class="bold">4. <span class="pl-2"><u> Service Fees</u></span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><i><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Section 5 describes the </span></i><i><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>service </span></i><i><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>fees
                payable </span></i><i><span lang=NL style='font-family:"Century Gothic","sans-serif"'>under
                the Service Contracts</span></i><i><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>.</span></i><i><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></i></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>The
                Service Fees.</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> As compensation for the provided services, the
                Service Provider is entitled to the service fees </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>payable by the Clients </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>(the "<b>Service
                </b></span><b><span lang=FR style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:FR'>Fees</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>"</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>)
                for each project completed successfully through the Platform. The Service Fees
                shall be defined in the Service Contract. </span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Paying
                the Service</span></b><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> Fees</span></b><b><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>.</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> When the Client concludes a Service Contract through
                the Platform, the Client agrees to pay the Service Fees to the Service
                Provider, as agreed under the Service Contract in accordance with the Service
                Provider"s individual pricing policy and payment terms.</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'></span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Refunds. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The Service Provider is solely responsible for
                handling any requests from the Clients regarding refunds for the Service Fees
                paid or payable. Should the </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Client
                </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>find the Service </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Provider"s
                services</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> be of dissatisfactory quality, the </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>Client</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> is
                entitled<span style='mso-spacerun:yes'>" </span>to rate the Service Provider
                and provide</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                the Feedback accordingly</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>. The Owner does not handle the payment of refunds of
                the Service Fees. </span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Expenses.
                </span></b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Thecoverage
                of all </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>expenses </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>incurred
                by the Service Provider under the Service Contract and the documentation
                related thereto shall be subject to the mutual agreement of the Contractors.</span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'></span></p>
                
                <!-- <p class=Heading style='margin-left:27.0pt;text-indent:-27.0pt;line-height:
                120%;mso-list:l1 level1 lfo17'><a name="_Toc4"><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'>5.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><u style='text-underline:#004D80'><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>Non-Circumvention</span></u></a><span
                style='mso-bookmark:_Toc4'></span><span style='font-size:12.0pt;line-height:
                120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p> -->
                <p class="bold">5. <span class="pl-2"><u> Non-Circumvention</u></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><i><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Section </span></i><i><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>5</span></i><i><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> discusses your obligation to </span></i><i><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>communicate</span></i><i><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                through the Platform only.</span></i><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The users must use the Platform as a sole manner of
                communicating before entering the Service Contract. A violation of this section
                5.1 is a material breach of the Terms and, therefore, the Account may be
                permanently suspended. </span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Unless allowed otherwise on the Platform, prior to
                entering into the Service Contract, the users are not allowed to:</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>i.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Provide contact details to any user of the Platform.
                The term "contact details" hereinafter should be understood broadly as a means
                to contact the user, including, without limitation, user"s phone number, email
                address, social media account, address, a link to a contact form, information
                about third-party communication tools like Skype, WhatsApp, WeChat, Facebook,
                Slack, or Viber;</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>ii.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Use user"s contact details to attempt to or to
                contact, communicate with, solicit, or find the contact information about the
                user outside of the Platform; </span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>iii.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Provide,
                ask, or attempt to identify contact details of any user through public means;
                or</span></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.85in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.35in;line-height:120%;mso-list:l1 level3 lfo17'><b><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>iv.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Include
                your contact details into any proposal, job offering, description, message,
                invitation, or other pre-hiring communication.</span></p>
                
                <p class=BodyCA style='text-align:justify;text-justify:inter-ideograph;
                line-height:120%;tab-stops:418.2pt'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <!-- <p class=Heading style='margin-left:23.15pt;text-indent:-23.15pt;line-height:
                120%;mso-list:l1 level1 lfo18'><a name="_Toc5"><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'>6.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><u style='text-underline:#004D80'><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>Records
                of Compliance</span></u></a><span style='mso-bookmark:_Toc5'></span><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p> -->
                <p class="bold">6. <span class="pl-2"><u> Records of Compliance</u></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><i><span lang=FR style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:FR'>Section 6</span></i><i><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'> states that you agree to make and keep all
                required records related to your use of the Platform.</span></i><i><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></i></p>
                
                <p class=Default style='margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.55in;margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
                text-indent:-.3in;line-height:120%;mso-list:l1 level2 lfo18'><span
                style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-hansi-font-family:"Arial Unicode MS";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'>6.1.<span
                style='font:7.0pt "Times New Roman"'> </span></span></span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>You
                agree to create and maintain records that document your compliance with the
                Terms, including, but not limited to, payment obligations and compliance with tax
                and employment laws. Upon our request, you agree to provide copies of the said
                records to us or any third parties acting on our behalf. You are solely
                responsible for creating, maintaining, storing, and backing up your records.
                Nothing in these Terms is construed as a requirement for us to (i) monitor or
                assess your compliance with the Terms or the terms of the Service Contracts or
                (ii) store, backup, retain, or grant access to the records.<span
                style='mso-spacerun:yes'>" </span></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'></span></p>
                
                <!-- <p class=Heading style='margin-left:27.0pt;text-indent:-27.0pt;line-height:
                120%;mso-list:l1 level1 lfo17'><a name="_Toc6"><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'>7.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><u style='text-underline:#004D80'><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>Warranty
                Disclaimer</span></u></a><span style='mso-bookmark:_Toc6'></span><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p> -->
                <p class="bold">7. <span class="pl-2"><u> Warranty Disclaimer</u></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><i><span lang=FR style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:FR'>Section 7</span></i><i><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'> disclaims all warranties with regard to your
                use of the Platform.</span></i><i><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></i></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>The
                Owner</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> provide</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>s
                the Platform </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'><span style='mso-spacerun:yes'>"</span>on "as
                available," "as is," and "with all faults" basis. To the extent permitted by
                the applicable law, t</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>he
                Owner does </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>not make any representations or warranties about the
                reliability, suitability, and accuracy, for any purpose, of </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>the Platform</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>, any
                content featured on </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                Platform</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>, whether provider by t</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>he Owner, the users,</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> or
                by third parties, and hereby disclaim</span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>s</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> all warranties regarding </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>the Platform</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> and
                its operation.It is your sole responsibility to verify and assess the fit for
                the purpose of </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                Platform </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>prior to using it and to decide whether or not </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>the Platform</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> fits
                for the intended use.</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'></span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>By using </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                Platform</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>, you acknowledge that t</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>he Owner</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> may
                use third-party suppliers to provide software, hardware, storage, networking,
                and other technological services. The acts and omissions of third-party
                suppliers may be outside of t</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>he
                Owner"s</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> reasonable control. To the maximum extent permitted
                by law, t</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>he
                Owner</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> exclude</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>s</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> any
                liability for any loss or damage resulting from the acts and omissions of such
                third-party suppliers. </span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Nothing in </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>these Terms </span><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>shall affect any
                statutory rights that you are entitled to as a consumer and that you cannot
                contractually agree to alter or waive.</span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><u><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'></span></u></p>
                
                <!-- <p class=Heading style='margin-left:27.0pt;text-indent:-27.0pt;line-height:
                120%;mso-list:l1 level1 lfo17'><a name="_Toc7"><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'>8.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><u style='text-underline:#004D80'><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>Limitation
                of Liability</span></u></a><span style='mso-bookmark:_Toc7'></span><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p> -->
                <p class="bold">8. <span class="pl-2"><u> Limitation of Liability</u></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><i><span lang=FR style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:FR'>Section 8</span></i><i><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'> limits our liability with regard to the
                Platform.</span></i><b><i><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></i></b></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Unless otherwise excluded or limited by the applicable
                law, t</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>he
                Owner</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> will not be liable for any damages, including, but not limited to,
                incidental, punitive, special or other related damages, arising out or in
                connection with youruse of</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                the Platform, the services provided under the Service Contract, </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>any
                content made available through</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                the Platform</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>, whether provided by t</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>he Owner, the Provider, the
                Client, </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>or by third parties, or any transactions concluded
                through </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                Platform. </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'></span></p>
                
                <!-- <p class=Heading style='margin-left:27.0pt;text-indent:-27.0pt;line-height:
                120%;mso-list:l1 level1 lfo17'><a name="_Toc8"><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'>9.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><u style='text-underline:#004D80'><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif"'>Release</span></u></a><span
                style='mso-bookmark:_Toc8'></span><span style='font-size:12.0pt;line-height:
                120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p> -->
                <p class="bold">9. <span class="pl-2"><u> Release</u></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><i><span lang=FR style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:FR'>Section 9</span></i><i><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'> how you release us from legal claims.</span></i><i><span
                lang=NL style='font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></i></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>You hereby absolutely and unconditionally release and
                forever discharge the Owner and any and all participants, parent corporations,
                subsidiary corporations, affiliated corporations, insurers, indemnitors,
                successors and assigns thereof, together with all of the present and former
                directors, officers, agents and employees of any of the foregoing, from any and
                all claims, demands or causes of action of any kind, nature or description,
                whether arising in law or equity or upon contract or tort or under any state or
                federal law or otherwise, which you have had, now has or have made claim to
                have against any such person for or by reason of any act, omission, matter,
                cause or thing whatsoever arising from the beginning of time to and including
                the date of these Terms, whether such claims, demands and causes of action are
                matured or unmatured or known or unknown.</span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'></span></p>
                
                <!-- <p class=Heading style='margin-left:27.0pt;text-indent:-27.0pt;line-height:
                120%;mso-list:l1 level1 lfo17'><a name="_Toc9"><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'>10.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><u
                style='text-underline:#004D80'><span style='font-size:12.0pt;line-height:120%;
                font-family:"Century Gothic","sans-serif"'>Indemnification</span></u></a><span
                style='mso-bookmark:_Toc9'></span><span style='font-size:12.0pt;line-height:
                120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p> -->
                <p class="bold">10. <span class="pl-2"><u> Indemnification</u></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><i><span lang=FR style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:FR'>Section </span></i><i><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>10 explains that you agree to defend us against
                all claims.</span></i><i><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></i></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>You hereby agree to indemnify, defend, save, and hold
                harmless the Owner, its members, officers, directors, and other agents from and
                against all claims, liabilities, causes of action, damages, judgments,
                attorneys" fees, court costs, and expenses which arise out of or are related to
                your use of the Platform, </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                Service Contracts, </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>violation of the rights of a third party, failure to
                perform as required, or result form conduct while engaging in any activity
                outside the scope of these Terms before, during or after the termination of the
                Terms. This obligation of indemnification survives the expiration or
                termination of the Terms. You agree to cooperate with any and all litigation
                arising from or related to the Platformor the Owner, as appropriate.</span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'></span></p>
                
                <!-- <p class=Heading style='margin-left:27.0pt;text-indent:-27.0pt;line-height:
                120%;mso-list:l1 level1 lfo17'><a name="_Toc10"><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'>11.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><u
                style='text-underline:#004D80'><span style='font-size:12.0pt;line-height:120%;
                font-family:"Century Gothic","sans-serif"'>Term and Termination</span></u></a><span
                style='mso-bookmark:_Toc10'></span><span style='font-size:12.0pt;line-height:
                120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></p> -->
                <p class="bold">11. <span class="pl-2"><u> Term and Termination</u></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><i><span lang=FR style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:FR'>Section </span></i><i><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>11 states when these Terms start, terminate, and
                are subject to amendment.</span></i><i><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></i></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Term</span></b><b><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Th</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>e
                Terms</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> shall commence on the day </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                user</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> accept</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>s
                it or starts using the Platform </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>and continue until the</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'> user ceases to use the
                Platform (e.g., by deleting the Account), unless terminated earlier by the
                Owner</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>. </span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Termination.
                </span></b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>The
                Owner reserves the right, at its sole discretion with or without a notice to
                the user, to terminate the Terms. The Terms shall be terminated automatically
                if the user breaches any provision of the Terms. </span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Amendments.</span></b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'> The Owner </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>reserves
                the right to modify the Terms at any time, effective upon posting of an updated
                version on the Platform. Such amendments may be necessary due to the changes in
                the requirements of laws, regulations, new features of the Platform, or the
                Owner"s business practices. The Owner will send you a notification (if it has
                your email address) about any material amendments to the Terms that may be of
                importance to you. You are responsible for regularly reviewing the Terms to
                stay informed. Your continued use of the Platform after any changes shall
                constitute your consent to such changes. The Owner also reserves the right to
                modify the services provided through the Platform at any time, at its sole
                discretion.</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'></span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Account data on closure</span></b><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>.</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                Unless required otherwise by law, the Owner shall disable your access to the
                Account and any data related thereto (including, without limitation, your
                messages, files, or any content store) upon closure of the Account for any
                reason. The Owner shall not have any liability whatsoever for your inability to
                access the Account and the said data. </span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Survival</span></b><b><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>After termination of the Terms, the provisions of the
                Terms that expressly or by their nature contemplate performance after the
                Agreement terminates or expires will survive and continue in full force and
                effect. </span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'></span></p>
                
                <!-- <p class=Heading style='margin-left:27.0pt;text-indent:-27.0pt;line-height:
                120%;mso-list:l1 level1 lfo17'><a name="_Toc11"><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'>12.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><u
                style='text-underline:#004D80'><span style='font-size:12.0pt;line-height:120%;
                font-family:"Century Gothic","sans-serif"'>Disputes Between You </span></u></a><span
                style='mso-bookmark:_Toc11'><u style='text-underline:#004D80'><span lang=NL
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>A</span></u></span><span style='mso-bookmark:_Toc11'><u
                style='text-underline:#004D80'><span style='font-size:12.0pt;line-height:120%;
                font-family:"Century Gothic","sans-serif"'>nd</span></u></span><span
                style='mso-bookmark:_Toc11'><u style='text-underline:#004D80'><span lang=NL
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'> Us</span></u></span><span style='mso-bookmark:_Toc11'></span><span
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p> -->
                <p class="bold">12. <span class="pl-2"><u> Disputes Between You And Us</u></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><i><span lang=FR style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:FR'>Section </span></i><i><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>12 explains how disputes can be resolved and
                which law governs the Terms.</span></i><i><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'></span></i></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Dispute </span></b><b><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>Resolution. </span></b><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>You agree to resolve any
                disputes arising out of or relating to this Agreement by means of negotiation
                with the Owner. If the dispute cannot be resolved by means of negotiation, the
                dispute shall be submitted to the binding online arbitration. This Section 12.1
                does not affect any statutory rights that you are entitled to as a consumer.</span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Governing
                </span></b><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Law</span></b><b><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>This Agreement shall be governed and construed in
                accordance with the laws of Australia, Victoria, without regard to its
                conflicts of law provisions.</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'></span></p>
                
                <!-- <p class=Heading style='margin-left:27.0pt;text-indent:-27.0pt;line-height:
                120%;mso-list:l1 level1 lfo17'><a name="_Toc12"><span
                lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic";mso-ansi-language:NL'><span
                style='mso-list:Ignore'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><u style='text-underline:#004D80'><span lang=NL
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>Intellectual Property</span></u></a><span
                style='mso-bookmark:_Toc12'></span><span lang=NL style='font-size:12.0pt;
                line-height:120%;font-family:"Century Gothic","sans-serif";mso-fareast-font-family:
                "Century Gothic";mso-bidi-font-family:"Century Gothic";mso-ansi-language:NL'></span></p> -->
                <p class="bold">13. <span class="pl-2"><u> Intellectual Property</u></span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><b><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The Owner Content. </span></b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>Most of</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> the
                content available</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                on the Platform</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>, including all information,</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'> source code, </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>data,
                logos, marks, designs, graphics, pictures, video files, sound files,
                illustrations, graphics, and similar </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>(collectively, "</span><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>The Owner Content</span></b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>") is owned by the
                Owner, its </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>partners, agents, licensors, vendors, and/or other
                content providers. </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>The
                Owner</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> Content is protected by applicable </span><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>intellectual property
                laws and international treaties. You are not allowed, without obtaining prior
                written authorisation from t</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>he
                Owner</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>, to copy, distribute, make available, disassemble, make alterations,
                decompile, reverse engineer, translate, adapt, rent, loan, use, lease or
                attempt to grant other rights to t</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>he
                Owner</span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'> Content to third parties, or use any manual or automated means to scrap</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>e</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> any
                content available on </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                Platform</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>.<b></b></span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><b><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>The Mates Rates </span></b><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>brand. </span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Y</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>ou may</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> not
                use </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>brand,
                the </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>word
                or figurative </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>trademarks associated with</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'> the Platform, the Owner,</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                Mates Rates, or third-party trademarks</span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'> without prior consent of a trademark owner</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>. </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>You are not allowed
                to </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>use </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>such
                </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>brand</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>s</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> and
                trademarks in any way that suggests that </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>we </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>sponsor, endorse, or associate with </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>you</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                without obtaining prior written consent from</span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'> us</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>.<b></b></span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><b><span lang=NL style='font-family:
                "Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic"'><span style='mso-list:Ignore'><span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><b><span lang=NL style='font-family:"Century Gothic","sans-serif"'>Third-party
                intellectual property. </span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Some of the intellectual property assets, such as
                third-party trademarks, featured on </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>the Platform</span><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'> may be owned by other
                third parties. Such third-party intellectual property does not belong to </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>us </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>and
                it remains the property of the respective third-party proprietors.</span><b><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'></span></b></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><b><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Copyright infringement claims. </span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>If
                you have any grounds to believe that any content available on </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>the Platform</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                violates your or third party"s intellectual property rights, please contact </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>us</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> and
                express your concerns or request to remove the allegedly infringing content. </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>We</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> will
                reply to </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>your</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                copyright infringement claim as soon as possible but no later than 2 weeks.
                Before sending your claim, please make sure that you </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>sign</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> it
                and includeinformation</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>
                that would allow the Owner to locate the allegedly infringing content.</span><b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'></span></b></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><b><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span></b><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Your Content. </span></b><span style='font-family:
                "Century Gothic","sans-serif";mso-ansi-language:EN-US'>You may upload and
                submit various content, such as advertisements, CVs, descriptions of your
                business activities, text messages, photos, videos, links, reviews, and files
                (collectively, "<b>Your Content</b>")</span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'> through the Platform</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>.
                Please note that some of Your Content may become available to other users of </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>the Platform</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                (e.g., if you use </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                Platform</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> as the Service Provider, the </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>Clients </span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>will
                be able to see some of Your Content). Therefore, </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>you must</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> (i)
                exercise your due diligence when uploading Your Content </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>on</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>to </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>the Platform</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>,
                (ii) not to make any sensitive information publicly available to other users of
                </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>the
                Platform</span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>, and (iii) make sure that, by uploading Your Content </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>on</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>to </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>the Platform</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>, you
                comply with th</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>is
                Agreement and all applicable laws, including intellectual property laws</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>.</span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'> Y</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>ou
                grant </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>us</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                unrestricted, sub-licensable, royalty-free, perpetual, and irrevocable rights
                to use, distribute, advertise, adapt, remix, modify, publicly display, publicly
                perform, excerpt, prepare derivative works of, and reproduce Your Content for
                the purposes of carrying ou</span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>t
                our </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>legitimate business interests. </span><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>We </span><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>reserve the right, at </span><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>our</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> sole
                discretion, to refuse to upload, modify, delete, or remove Your Content, in
                whole or in part, that violates these Termsor may harm the reputation of </span><span
                lang=NL style='font-family:"Century Gothic","sans-serif"'>the Platform</span><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>.
                Your Content includes your personal views and recommendations. None of Your
                Content reflects </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>our
                </span><span style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                EN-US'>views, recommendations, endorsement, or any commitments related thereto.<b></b></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'></span></p>
                
                <!-- <p class=Heading style='margin-left:27.0pt;text-indent:-27.0pt;line-height:
                120%;mso-list:l1 level1 lfo17'><a name="_Toc13"><span
                lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic";mso-ansi-language:NL'><span
                style='mso-list:Ignore'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><u style='text-underline:#004D80'><span lang=NL
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>Final Provisions</span></u></a><span style='mso-bookmark:
                _Toc13'></span><span lang=NL style='font-size:12.0pt;line-height:120%;
                font-family:"Century Gothic","sans-serif";mso-fareast-font-family:"Century Gothic";
                mso-bidi-font-family:"Century Gothic";mso-ansi-language:NL'></span></p> -->
                <p class="bold">14. <span class="pl-2"><u> Final Provisions</u></span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Entire agreement</span></b><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>.</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>
                These terms, together with the documents referred to therein, represent the
                entire agreement between you and the Owner regarding your relationship with the
                Owner. </span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Waiver</span></b><b><span lang=NL style='font-family:
                "Century Gothic","sans-serif"'>.</span></b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'> A failure of either party to the Terms to enforce a
                provision of the Terms shall in no event be considered a waiver of any part of
                such provision. No waiver by either party of any breach or default by the other
                party shall operate as a waiver of any succeeding breach or other default or
                breach by such other party. No waiver shall have any effect unless it is
                specific, irrevocable and in writing.</span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Assignability</span></b><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>.</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> You
                are not allowed to assign your rights under the Terms. The Owner is entitled to
                transfer its rights and obligations under the Terms entirely or partially to a
                third party by giving a prior notice to you. If you do not agree to the
                transfer, you can terminate the Terms with immediate effect by deleting the
                Account and stopping to use the Platform. </span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Severability</span></b><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>.</span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'> To
                the extent that any provision of the Terms is deemed unenforceable, all
                remaining provisions of the Terms shall not be affected thereby and shall
                remain in full force and effect.</span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><i><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Force majeure</span></i></b><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>. </span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>The
                availability of the Platform may be affected by factors, which we cannot
                reasonably control, such as bandwidth problems, equipment failure, acts and
                omissions of our third-party service providers, or <i>force majeure</i> events;
                we take no responsibility for the unavailability of the Platform caused by such
                factors.</span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Prevailing language</span></b><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>. </span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>The
                English language version of the Terms will be controlling in all respects and
                will prevail in case of any inconsistencies with translated versions.</span></p>
                
                <p class=Default style='margin:0in;margin-bottom:.0001pt;text-align:justify;
                text-justify:inter-ideograph;text-indent:0in;line-height:120%;mso-list:l1 level2 lfo17;
                tab-stops:list .25in'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-list:Ignore'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><b><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>Electronic records</span></b><b><span lang=NL
                style='font-family:"Century Gothic","sans-serif"'>. </span></b><span
                style='font-family:"Century Gothic","sans-serif";mso-ansi-language:EN-US'>Any
                notices related to your use of the Platform shall be delivered electronically
                and not in paper form. You hereby agree to receive the said notices in
                electronic form. </span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'></span></p>
                
                <!-- <p class=Heading style='margin-left:27.0pt;text-indent:-27.0pt;line-height:
                120%;mso-list:l1 level1 lfo17'><a name="_Toc14"><span
                lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-hansi-font-family:"Arial Unicode MS";
                mso-bidi-font-family:"Century Gothic";mso-ansi-language:NL'><span
                style='mso-list:Ignore'>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><u style='text-underline:#004D80'><span lang=NL
                style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-ansi-language:NL'>Contact</span></u></a><span style='mso-bookmark:_Toc14'></span><span
                lang=NL style='font-size:12.0pt;line-height:120%;font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:NL'></span></p> -->
                <p class="bold">15. <span class="pl-2"><u> Contact</u></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-tab-count:1'> </span>Email
                address: </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>m</span><span
                lang=DE style='font-family:"Century Gothic","sans-serif";mso-ansi-language:
                DE'>atesratesworks@gmail.com</span><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-tab-count:1'> </span>Mail
                address: Mates Rates, </span><span lang=IT style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:IT'>Epping </span><span lang=NL style='font-family:"Century Gothic","sans-serif"'>VIC
                3076, Australia</span><span lang=NL style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'></span></p>
                
                <p class=Default style='margin-top:0in;text-align:justify;text-justify:inter-ideograph;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic";
                mso-ansi-language:EN-US'><span style='mso-tab-count:1'> </span></span></p>
                
                <p class=Default align=center style='margin-top:0in;text-align:center;
                line-height:120%'><span style='font-family:"Century Gothic","sans-serif";
                mso-ansi-language:EN-US'>[END]</span></p>
                
                </div>
        </div>
    </div>
    
</section>
</section>
