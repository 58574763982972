import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { iReview, iUser } from '../model/all-classes';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  public userProfile: iUser;
  public reviewsList: iReview[];

  public submitted: boolean = false;
  public reviewData: any = {
    comment: '',
    rating: 0
  }

  constructor(public service: DbDataApiService,
    public router: Router,
    public route: ActivatedRoute,
    private toastr: ToastrService,
    public zone: NgZone) { }

  ngOnInit(): void {
    this.service.openNotifyMd = false;
    this.reviewsList = [];
    this.userProfile = new iUser();
    if (this.route.snapshot.params['id']) {
      this.userProfile.uid = this.route.snapshot.params['id'];
    } else {
      this.router.navigate(['/search-providers']);
    }

    if (this.service.usersList && this.service.usersList.length) {
      this.getUserProfileData()
    }
    this.service.userDataCheck.subscribe((value) => {
      if (this.service.usersList && this.service.usersList.length) {
        this.getUserProfileData()
      }
    });

    if (this.service.reviewsList && this.service.reviewsList.length) {
      this.getUserReviewsData()
    }
    this.service.reviewDataCheck.subscribe((value) => {
      if (this.service.reviewsList && this.service.reviewsList.length) {
        this.getUserReviewsData()
      }
    });
  }

  public getUserProfileData() {
    var uIndex = this.service.usersList.findIndex(obj => obj.uid == this.userProfile.uid);
    if (uIndex >= 0) {
      this.userProfile = JSON.parse(JSON.stringify(this.service.usersList[uIndex]));
    }
  }

  public getUserReviewsData() {
    var self = this;
    self.reviewsList = [];

    for (var i = 0; i < self.service.reviewsList.length; i++) {
      if (self.service.reviewsList[i].ratedUser == self.userProfile.uid) {
        self.reviewsList.push(JSON.parse(JSON.stringify(self.service.reviewsList[i])));
      }
    }

    self.updateReviewUserName();
  }


  updateReviewUserName() {
    var self = this;

    if (self.reviewsList && self.reviewsList.length && self.service.usersList && self.service.usersList.length) {

      for (var i = 0; i < self.reviewsList.length; i++) {
        var uIndex = self.service.usersList.findIndex(obj => obj.uid == self.reviewsList[i].uid);
        if (uIndex >= 0) {
          self.reviewsList[i].ratedUserName = self.service.usersList[uIndex].name;
        } else {
          self.reviewsList[i].ratedUserName = '';
        }
      }
    }

  }

  submitReviewData() {
    var self = this;
    if (!self.service.userData || !self.service.userData.uid) {
      self.router.navigate(['/home']);
    }

    if (self.reviewData.rating < 1 || !self.reviewData.comment || self.reviewData.comment.length < 10 || self.reviewData.comment > 300) {
      self.submitted = true;
      return;
    }
    self.submitted = false;
    self.service.loading = true;

    var reviewKey = firebase.database().ref().child('Reviews/').push().key;
    self.reviewData['ratedUser'] = self.userProfile.uid;
    self.reviewData['uid'] = self.service.userData.uid;
    self.reviewData['timeStamp'] = Number(new Date());

    if (!self.userProfile.rating) {
      self.userProfile.rating = self.reviewData.rating;
    } else {
      self.userProfile.rating = (self.userProfile.rating + self.reviewData.rating) / 2;
    }

    var updates = {};
    updates['/Reviews/' + reviewKey] = self.reviewData;
    updates['/Users/' + self.userProfile.uid + '/rating'] = self.userProfile.rating;

    firebase.database().ref().update(updates).then(() => {
      self.sendUserNotification();
      var uIndex = self.service.usersList.findIndex(obj => obj.uid == self.userProfile.uid);
      if (uIndex >= 0) {
        self.service.usersList[uIndex].rating = self.userProfile.rating;
      }

      self.reviewData.ratedUserName = self.service.userData.name;
      self.reviewData.reviewId = reviewKey;
      self.service.reviewsList.unshift(self.reviewData)
      self.reviewsList.unshift(self.reviewData)

      self.service.loading = false;
      self.toastr.success('User Review posted successfully.', '', { timeOut: 3500 });
      var md: any = document.getElementById('closeReviewMd');
      if (md) {
        md.click();
      }

      self.reviewData = {};
      self.reviewData = {
        comment: '',
        rating: 0
      }
    }).catch((e) => {
      self.service.loading = false;
      self.toastr.success('User Review is not posted.' + e.message, '', { timeOut: 3500 });
    });
  }


  sendUserNotification() {
    var self = this;
    self.service.loading = true;

    var recieverList = [self.userProfile.uid]
    var tokensList: any = [];
    var userData = JSON.parse(JSON.stringify(self.userProfile));

    if (userData.deviceTokens && userData.deviceTokens.length) {
      for (var k = 0; k < userData.deviceTokens.length; k++) {
        if (userData.deviceTokens[k]) {
          tokensList.push(userData.deviceTokens[k]);
        }
      }
    }

    if (tokensList && tokensList.length) {
      var addMessage = firebase.functions().httpsCallable('sendNotification');
      addMessage({
        title: 'New Review',
        message: this.service.userData.name + ' just left a review on your profile check it out.',
        deviceToken: tokensList,
      }).then((result) => {
        self.saveNotificationFirebase(tokensList, recieverList);
      }, (error) => {
        self.toastr.error(error.message, '', { timeOut: 3500 });
      });
    } else {
      self.saveNotificationFirebase(tokensList, recieverList);
    }


  }

  saveNotificationFirebase(tokensList, recieverList) {
    var self = this;

  //   let payload = [
  //     "dataUid": "\(myData?.uid ?? "")"
  // ]
 

// New Quote Received
// let payload = [
//       "title": "New Quote Received",
//       "message": "\(myData?.name ?? "")  just sent you a new quote.",
//       "dataUid": "\(self.job?.jobId ?? "")"
//   ]
//   var data: HttpParameters = [
//       "title": "New Quote Received",
//       "message": "\(myData?.name ?? "")  just sent you a new quote.",
//       "deviceToken": self.job?.user?.deviceTokens ?? [],
//       "data": payload,
//       "jobId": "\(self.job?.jobId ?? "")"
//   ]

    var updates = {};
    var newPostKey = firebase.database().ref().child('Notifications/').push().key;
    var postData = {
      title: 'New Review',
      category: 'New Review',
      message: this.service.userData.name + ' just left a review on your profile check it out',
      receiverId: recieverList,
      senderId: self.service.userData.uid,
      userId: self.service.userData.uid,
      deviceToken: tokensList,
      notificationId: newPostKey,
      timeStamp: Number(new Date()),
    };

    updates['/Notifications/' + "/" + newPostKey] = postData;
    firebase.database().ref().update(updates).then(() => {
      self.service.loading = false;
    }).catch((error) => {
      self.service.loading = false;
      self.toastr.error(error.message, '', { timeOut: 3500 });
    });

  }

}
