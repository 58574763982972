<app-navigation></app-navigation>
<ngx-loading [show]="service.loading"> </ngx-loading>
<section class="main-cont">
  <section class="section-1 page-title">
    <div class="row  align-items-center">
      <div class="col-sm-6">
        <h4 class="mb-0">Posted Quotes</h4>
      </div>
      <div class="col-sm-6">
        <!-- <button class="btn btn-outline-secondary" type="button">Previous</button>
        <button class="btn btn-outline-secondary" type="button">Next</button> -->
        <input type="search" class="form-control searchField" [(ngModel)]="term"
          placeholder="Search Qoutes by User, Category, Sub Category">
      </div>

    </div>
  </section>
  <section class="section-2">
    <div class="row">

      <div class="col-md-4 col-sm-6" *ngFor="let job of myQoutedJobs | search:term:searchFields; let i=index;">
        <div class="card item-quote">
          <!-- <div class="like-star">
            <i class="far fa-star"></i>
          </div> -->
          <div class="rating">
            <i class="fas fa-star"></i>
            <span *ngIf="!job.rating">Not rated yet</span>
            <span *ngIf="job.rating">{{service.userData.rating | number:'1.2-2'}}</span>

          </div>
          <!-- <img [src]="job.fileUrls[0]" class="card-img-top"> -->
          <img [src]=" job.fileUrls[0] " class="card-img-top" *ngIf="job.fileUrls && job.fileUrls[0]">
          <img src=" /assets/imgs/person.svg" class="card-img-top" *ngIf="!job.fileUrls || !job.fileUrls[0]">
          <div class="card-body">
            <h5 class="card-title">{{job.userName | titlecase}}</h5>
            <p class="mb-1"><small class="primary">{{job.categoryName | titlecase}},
                {{job.subCategoryName | titlecase}}</small></p>
            <div class="price">
              <h5 class="primary bold mt-0 mb-2" *ngIf="job.price"><small>Price: </small> ${{job.price || '0'}}</h5>
            </div>
            <p class="pointer mb-2 descText" (click)="description=job.description" data-toggle="modal"
              data-target="#descModal">{{job.description}}</p>
            <p class="locationText">{{job.location}}</p>
            <div class="d-flex">
              <button class="btn btn-success" type="button" *ngIf="job.status!='Ended'">Live</button>
              <button class="btn btn-danger" type="button" *ngIf="job.status=='Ended'">Ended</button>

              <p class="primary">Quote: <span>${{job.qoutedPrice}}</span></p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4" *ngIf="!myQoutedJobs || !myQoutedJobs.length">
        You did not post any qoute yet.
      </div>

    </div>
  </section>
</section>



<!-- Modal -->
<div class="modal tichun fade" id="descModal" tabindex="-1" aria-labelledby="quoteModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <h4 class="primary text-center">Job Description</h4>
        <p> {{description}} </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


<!--update Qoute Status Modal-->
<div class="modal fade" id="qouteStatusModal" tabindex="-1" aria-labelledby="qouteStatusModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title primary" id="deleteModalLabel">Update Qoute Status</h5>
      </div>
      <div class="modal-body text-center">
        <img src="/assets/imgs/CrossIcon.png" height="100px;">
        <p>Are you sure? D you want to End this Qoute: ?</p>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" id="closeQouteStatusMd" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-danger" (click)="updateQouteStatus()">End Qoute</button>
      </div>
    </div>
  </div>
</div>
