<ngx-loading [show]="service.loading"> </ngx-loading>
<section class="signup-form">
  <div class="row">
    <div class="col-sm-4 form-left">
      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
          <img class="img-responsive" src="/assets/imgs/logo.svg" alt="">
          <h4 class="py-4">Why our service?</h4>
          <ul>
            <li>We’re fast and experienced</li>
            <li>Powered by tech, so you can</li>
            <li>Updates and pics for every</li>
          </ul>
        </div>
        <div class="col-sm-2"></div>
      </div>
    </div>
    <div class="col-sm-8 form-right">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-8 px-0">
          <div class="row">
            <div class="col-sm-12">
              <h1>Good news! We are here Let’s
                create your account.</h1>
            </div>
            <form [formGroup]="signupForm">
              <div class="row">
                <div class="col-sm-6 my-1">
                  <div class="form-group">
                    <label for="my-input">Full Name</label>
                    <input id="my-input" class="form-control" type="text" formControlName="name">
                    <div *ngIf="submitted && f.name.errors" class="redErrorText pl-2">
                      <div *ngIf="f.name.errors.required">*Full Name is required</div>
                      <div *ngIf="f.name.errors.minlength || f.name.errors.maxlength">*Full Name length should be
                        between 3 to 30 characters</div>
                    </div>
                  </div>

                </div>
                <div class="col-sm-6 my-1">
                  <div class="form-group">
                    <label for="my-input">Email Address</label>
                    <input id="my-input" class="form-control" type="text" formControlName="email">
                    <div *ngIf="submitted && f.email.errors" class="redErrorText pl-2">
                      <div *ngIf="f.email.errors.required">*Email is required</div>
                      <div *ngIf="f.email.errors.pattern">*Email pattern is invalid, Kindly enter valid email.</div>
                    </div>
                  </div>

                </div>
                <div class="col-sm-6 my-1">
                  <div class="form-group">
                    <label for="my-input">Password</label>
                    <input id="my-input" class="form-control" type="password" formControlName="password">
                    <div *ngIf="submitted && f.password.errors" class="redErrorText pl-2">
                      <div *ngIf="f.password.errors.required">*Password is required</div>
                      <div *ngIf="f.password.errors.minlength || f.password.errors.maxlength">*Password length should be
                        between 6 to 15 characters</div>
                    </div>
                  </div>

                </div>
                <div class="col-sm-6 my-1">
                  <div class="form-group">
                    <label for="my-input">Confirm password</label>
                    <input id="my-input" class="form-control" type="password" formControlName="c_password">

                    <div *ngIf="submitted && f.c_password.errors" class="redErrorText pl-2">
                      <div *ngIf="f.c_password.errors.required">*Confirm password is required</div>
                      <div *ngIf="f.c_password.errors.minlength || f.c_password.errors.maxlength">*Confirm password
                        length should be between 6 to 15 characters</div>
                    </div>

                    <div *ngIf="f.c_password.value && f.password.value && !checkPassword()" class="redErrorText pl-2">
                      *Password & Confirm password are not same.
                    </div>

                  </div>

                </div>
                <div class="col-sm-12">
                  <div class="form-check">
                    <input [(ngModel)]="policy" [ngModelOptions]="{standalone: true}" class="form-check-input" type="checkbox">
                    <label for="my-input" class="form-check-label pl-2">I have read the <span> <u class="primary pointer" routerLink="/privacy-policy">Privacy Policy</u> </span> and agree to the <span> <u class="primary pointer" routerLink="/terms&conditions"> Terms & Conditions</u> </span>.</label>
                  </div>
                </div>
                <div class="col-sm-12">
                  <button class="btn btn-primary mt-4" type="button" [disabled]="!signupForm.valid || !policy" (click)="submitSignupForm()">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-sm-2"></div>
      </div>
      <div class="row form-footer">
        <!-- <div class="col-sm-1"></div> -->
        <div class="col-sm-8" style="padding-left: 6%;">
          <p>Already a member? <span class="pl-2 primary pointer" routerLink="/home">Log in</span></p>
        </div>
        <div class="col-sm-2"></div>
      </div>
    </div>
  </div>
</section>
