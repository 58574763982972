import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { iChat, iJob } from '../model/all-classes';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public jobsList: iJob[];
  public userQoutedJobsList: string[];
  public description: string = '';
  public jobData: any = {}

  public searchFields: any = ['userName', 'price', 'categoryName', 'subCategoryName', 'location']
  public term:string = '';
  constructor(public service: DbDataApiService,
    public router: Router,
    private toastr: ToastrService,
    public zone: NgZone) { }

  ngOnInit(): void {

    var self = this;
    self.service.openNotifyMd = false;
    self.jobsList = [];
    self.userQoutedJobsList = [];

    if (self.service.jobsList && self.service.jobsList.length) {
      self.jobsList = JSON.parse(JSON.stringify(self.service.jobsList));
    }

    self.service.jobDataCheck.subscribe(value => {
      if (self.service.jobsList && self.service.jobsList.length) {
        self.jobsList = JSON.parse(JSON.stringify(self.service.jobsList));
      }
    });

    if (self.service.qoutesList && self.service.qoutesList.length) {
      self.getMyQoutesList();
    }

    self.service.qouteDataCheck.subscribe(value => {
      if (self.service.qoutesList && self.service.qoutesList.length) {
        self.getMyQoutesList();
      }
    });
  }

  getMyQoutesList() {
    var self = this;
    self.userQoutedJobsList = [];

    for (var i = 0; i < self.service.qoutesList.length; i++) {
      if (self.service.qoutesList[i].userId == self.service.userData.uid) {
        self.userQoutedJobsList.push(self.service.qoutesList[i].jobId)
      }
    }

  }


  // checkMsgUser(uid) {
  //   this.service.selectedMsgUser = new iChat();
  //   this.service.selectedMsgUser.uid = uid;
  //   this.router.navigate(['/messages']);
  // }


}
