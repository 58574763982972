import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { iJob } from '../model/all-classes';

@Component({
  selector: 'app-jobs-posted',
  templateUrl: './jobs-posted.component.html',
  styleUrls: ['./jobs-posted.component.scss']
})
export class JobsPostedComponent implements OnInit {

 
  public myJobsList: iJob[];
  public searchFields: any = [ 'price', 'categoryName', 'subCategoryName', 'location'];
  public term:string = '';
  public endJobKey: string = '';
  public description: string = '';

  constructor(public service: DbDataApiService,
    public router: Router,
    private toastr: ToastrService,
    public zone: NgZone) { }

  ngOnInit(): void {
    var self = this;
    self.service.openNotifyMd = false;
    self.myJobsList = [];

    if(this.service.newJobQoutes>this.service.myJobQoutes) {
      this.service.myJobQoutes = this.service.newJobQoutes ;

      var data = {
        myJobQoutes: this.service.myJobQoutes,
        myNotifyCounts: this.service.myNotifyCounts,
      };
      localStorage.setItem('mateRatesCounts'+this.service.userData.uid, JSON.stringify(data));
    }
    

    if (self.service.jobsList && self.service.jobsList.length) {
      self.getMyJobsList();
    }

    self.service.jobDataCheck.subscribe(value => {
      if (self.service.jobsList && self.service.jobsList.length) {
        self.getMyJobsList();
      }
    });

  }

  getMyJobsList() {
    var self = this;
    self.myJobsList = [];

    for (var i = 0; i < self.service.jobsList.length; i++) {
      if (self.service.jobsList[i].userId == self.service.userData.uid) {
        self.myJobsList.push(JSON.parse(JSON.stringify(self.service.jobsList[i])));
      }
    }

    var oldJobs:any = []
    if(localStorage.getItem('mateRatesmyJobs')) {
      oldJobs = JSON.parse(localStorage.getItem('mateRatesmyJobs'));
    }

    self.service.myOldJobs = [];

    for (var i = 0; i < self.myJobsList.length; i++) {
      var data = oldJobs.find(obj=> obj.jobId == self.myJobsList[i].jobId ) ;
      if(data) {
        self.service.myOldJobs[i] =  JSON.parse(JSON.stringify(data)) ;
      } else {
        self.service.myOldJobs[i] =  JSON.parse(JSON.stringify(self.myJobsList[i])) ;
        self.service.myOldJobs[i].qouteCount = 0;
      }
     
    }

    localStorage.setItem('mateRatesmyJobs', JSON.stringify( self.service.myOldJobs)) ;
  }


  updateJobStatus() {
    var self = this;
    self.service.loading = true;
    var updateData: any = {};
    updateData['Jobs/' + self.endJobKey + '/status'] = 'Ended';

    firebase.database().ref().update(updateData).then(() => {

      var index = self.service.jobsList.findIndex(x => x.jobId == self.endJobKey);
      if (index >= 0) {
        self.service.jobsList[index].status = 'Ended';
      } var index2 = self.myJobsList.findIndex(x => x.jobId == self.endJobKey);
      if (index2 >= 0) {
        self.myJobsList[index2].status = 'Ended';
      }
      self.endJobKey = '';
      document.getElementById('closeJobStatusMd').click();
      self.service.loading = false;
      self.toastr.success('Job ended Successfully.', '', { timeOut: 2500 });
    }).catch((error) => {
      self.service.loading = false;
      self.toastr.error('Job is not ended, Kindly Try Again!', '', { timeOut: 2500 });
    })

  }

}