<app-navigation></app-navigation>
<section class="main-cont">
  <section class="section-1 page-title pb-0">
    <div class="row">
      <div class="col-sm-9">
        <h4 class="mb-1">Search for Service Providers</h4>
      </div>
      <!-- <div class="col-sm-3">
                <button class="btn btn-outline-secondary" type="button">Previous</button>
                <button class="btn btn-outline-secondary" type="button">Next</button>
            </div> -->
    </div>
  </section>
  <section class="section-2">
    <div class="services">
      <div class="service-item" [class.activeCat]="selectedCat=='All' " (click)="filterUsers('All')">
        <div class="card">
          <img src="/assets/imgs/cleaning.svg" class="card-img-top">
        </div>
        <p>All Categories</p>
      </div>
      <div class="service-item" [class.activeCat]="selectedCat==cat.categoryId" (click)="filterUsers(cat.categoryId)"
        *ngFor="let cat of categoryList ; let i=index;">
        <div class="card">
          <img [src]="cat.categoryImage || '/assets/imgs/cleaning.svg'" class="card-img-top">
        </div>
        <p>{{cat.categoryName}}</p>
      </div>

    </div>
  </section>
  <section class="section-3">
    <div class="row">
      <div class="col-md-3 col-sm-4" [class.d-none]="user.uid == service.userData.uid"
        *ngFor="let user of usersList | search:service.searchTerm:searchFields; let i=index;">
        <div class="card">
          <img class="pointer" [src]="user.profileUrl || '/assets/imgs/person.svg'"
            [routerLink]="['/user-profile',user.uid]">
          <p class="mb-0 username pointer" [routerLink]="['/user-profile',user.uid]">{{user.name | titlecase}}</p>
          <p><small class="primary">{{user.categoryName || 'N/A'}} , {{user.subCategoryName || 'N/A'}}</small></p>
          <div class="ratings">
            <i class="far fa-star" *ngIf="user.rating <0.3"></i>
            <i class="fas fa-star" *ngIf="user.rating >=1"></i>
            <i class="far fa-star" *ngIf="user.rating <1.3"></i>
            <i class="fas fa-star" *ngIf="user.rating >=1.8"></i>
            <i class="fas fa-star-half-alt" *ngIf="user.rating >=1.3 && user.rating <1.8"></i>
            <i class="far fa-star" *ngIf="user.rating <2.3"></i>
            <i class="fas fa-star" *ngIf="user.rating >=2.8"></i>
            <i class="fas fa-star-half-alt" *ngIf="user.rating >=2.3 && user.rating <2.8"></i>
            <i class="far fa-star" *ngIf="user.rating <3.3"></i>
            <i class="fas fa-star" *ngIf="user.rating >=3.8"></i>
            <i class="fas fa-star-half-alt" *ngIf="user.rating >=3.3 && user.rating <3.8"></i>
            <i class="far fa-star" *ngIf="user.rating <4.3"></i>
            <i class="fas fa-star" *ngIf="user.rating >=4.8"></i>
            <i class="fas fa-star-half-alt" *ngIf="user.rating >=4.3 && user.rating <4.8"></i>

            <p class="mb-0" *ngIf="user.rating">({{user.rating | number:'1.2-2'}})</p>
            <p class="mb-0" *ngIf="!user.rating">(Not rated yet)</p>
          </div>
          <!-- <button class="btn btn-primary" type="button" (click)="service.checkMsgUser(user.uid)">Message</button> -->
          <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#loginModal"
            *ngIf="!service.userData || !service.userData.uid">Message</button>
          <button class="btn btn-primary" type="button" (click)="service.checkMsgUser(user.uid)"
            *ngIf="service.userData && service.userData.uid">Message</button>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!usersList || !usersList.length">
        No service provider is available.
      </div>
    </div>
  </section>
</section>



<!-- Modal -->
<!-- <div class="modal tichun fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-login></app-login>
    </div>
  </div>
</div> -->