import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { iJob, iQoute } from '../model/all-classes';

@Component({
  selector: 'app-quotes-submitted',
  templateUrl: './quotes-submitted.component.html',
  styleUrls: ['./quotes-submitted.component.scss']
})
export class QuotesSubmittedComponent implements OnInit {

  public myQoutesList: iQoute[];
  public myQoutedJobs:any[];
  public searchFields: any = ['userName', 'price', 'categoryName', 'subCategoryName', 'location'];
  public term:string = '';
  public endQouteKey: string = '';
  public description:string = '';

  constructor(public service: DbDataApiService,
    public router: Router,
    private toastr: ToastrService,
    public zone: NgZone) { 
    }

  ngOnInit(): void {
    var self = this;
    self.service.openNotifyMd = false;
    self.myQoutesList = [];
    self.myQoutedJobs = [];

    if (self.service.qoutesList && self.service.qoutesList.length) {
      self.getMyQoutesList();
    }

    self.service.qouteDataCheck.subscribe(value => {
      if (self.service.qoutesList && self.service.qoutesList.length) {
        self.getMyQoutesList();
      }
    });

  }

  getMyQoutesList() {
    var self = this;
    self.myQoutesList = [];
    self.myQoutedJobs = [];

    for (var i = 0; i < self.service.qoutesList.length; i++) {
      if (self.service.qoutesList[i].userId == self.service.userData.uid) {
        self.myQoutesList.push(JSON.parse(JSON.stringify(self.service.qoutesList[i])));
      }
    }

    for (var i = 0; i < self.myQoutesList.length; i++) {
      var jobIndex = self.service.jobsList.findIndex(obj => obj.jobId == self.myQoutesList[i].jobId )
      if ( jobIndex >=0 ) {
        var temp:any = JSON.parse(JSON.stringify(self.service.jobsList[jobIndex]) )
        temp.qoutedPrice = self.myQoutesList[i].price ;
        self.myQoutedJobs.push(temp);
      }
    }

  }


  updateQouteStatus() {
    var self = this;
    self.service.loading = true;
    var updateData: any = {};
    updateData['Quotes/' + self.endQouteKey + '/status'] = 'Ended';

    firebase.database().ref().update(updateData).then(() => {

      var index = self.service.qoutesList.findIndex(x => x.qouteId == self.endQouteKey);
      if (index >= 0) {
        self.service.qoutesList[index].status = 'Ended';
      }
      var index2 = self.myQoutesList.findIndex(x => x.qouteId == self.endQouteKey);
      if (index2 >= 0) {
        self.myQoutesList[index2].status = 'Ended';
      }
      self.endQouteKey = '';
      document.getElementById('closeQouteStatusMd').click();
      self.service.loading = false;
      self.toastr.success('Qoute ended Successfully.', '', { timeOut: 2500 });
    }).catch((error) => {
      self.service.loading = false;
      self.toastr.error('Qoute is not ended, Kindly Try Again!', '', { timeOut: 2500 });
    })

  }

}
