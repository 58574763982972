import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { iCategory, iSubCategory, iUser } from '../model/all-classes';
/*range sldier */
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  options: Options = {
    floor: 0,
    ceil: 100
  };

  public profileForm: FormGroup;
  public submitted: boolean = false;
  public userProfile: iUser;
  public categoryList: iCategory[];
  public subCategoryList: iSubCategory[];
  public imgURL: any;
  public imageFile: any;

  public userSettings1: any = {
    noOfRecentSearchSave: 0,
    inputPlaceholderText: 'Enter your location',
    showSearchButton: false,
    showRecentSearch: false,
    inputString: ''
  };

  constructor(private formBuilder: FormBuilder,
    public service: DbDataApiService,
    public router: Router,
    private toastr: ToastrService,
    public zone: NgZone) { }

  ngOnInit(): void {
    var self = this;
    self.service.openNotifyMd = false;
    self.userProfile = new iUser();

    self.categoryList = [];
    self.subCategoryList = [];

    if (self.service.userData && self.service.userData.uid) {
      self.userProfile = JSON.parse(JSON.stringify(self.service.userData));
      if (!self.userProfile.maxRadius) {
        self.userProfile.maxRadius = 0;
      } if (self.userProfile.categoryId) {
        self.getSubCategories('');
      } if (self.userProfile.location) {
        self.userSettings1['inputString'] = self.userProfile.location;
      }
    } if (self.service.categoriesList && self.service.categoriesList.length) {
      self.getActiveCategories();
    }

    self.service.userDataCheck.subscribe(value => {
      self.userProfile = JSON.parse(JSON.stringify(self.service.userData));
      if (!self.userProfile.maxRadius) {
        self.userProfile.maxRadius = 0;
      } if (self.userProfile.categoryId) {
        self.getSubCategories('');
      } if (self.userProfile.location) {
        self.userSettings1['inputString'] = self.userProfile.location;
      }
    });

    self.service.categoryDataCheck.subscribe(value => {
      self.getActiveCategories();
    });

    this.profileForm = this.formBuilder.group({
      name: ['', Validators.compose([
        Validators.required, Validators.minLength(3), Validators.maxLength(30)
      ])],
      phoneNo: ['', Validators.compose([
        Validators.required, Validators.minLength(7), Validators.maxLength(16)
      ])],
      email: [''],
      categoryId: [''],
      subCategoryId: [''],
      location: [''],
      maxRadius: [''],
      biography: ['']
    });

  }

  getActiveCategories() {
    var self = this;
    self.categoryList = [];
    for (var i = 0; i < self.service.categoriesList.length; i++) {
      if (self.service.categoriesList[i].active) {
        self.categoryList.push(JSON.parse(JSON.stringify(self.service.categoriesList[i])));
      }
    }
  }

  get f() { return this.profileForm.controls; }


  getSubCategories(data) {
    var self = this;
    if (data) {
      self.userProfile.subCategoryId = '';
    }

    self.subCategoryList = [];
    if (self.service.subCategoriesList && self.service.subCategoriesList.length && self.service.categoriesList && self.service.categoriesList.length) {

      for (var i = 0; i < self.service.subCategoriesList.length; i++) {
        if (self.userProfile.categoryId == self.service.subCategoriesList[i].categoryId && self.service.subCategoriesList[i].active) {
          self.subCategoryList.push(JSON.parse(JSON.stringify(self.service.subCategoriesList[i])))
        }
      }
    }
  }

  autoCompleteCallback1(data: any): any {
    this.userProfile.latitude = data.data.geometry.location.lat;
    this.userProfile.longitude = data.data.geometry.location.lng;
    this.userProfile.location = data.data.formatted_address;
    var tempArray = data.data.address_components;
    var city = ''
    var country = ''
    for (var i = 0; i < tempArray.length; i++) {
      var typesArray = tempArray[i].types;
      for (var j = 0; j < typesArray.length + 1; j++) {
        if (typesArray[j] == "country") {
          country = tempArray[i].long_name;
        } else if (typesArray[j] == "locality") {
          city = tempArray[i].long_name;
        }
      }
    }
    this.userSettings1 = {
      noOfRecentSearchSave: 0,
      inputPlaceholderText: 'Enter your location',
      showSearchButton: false,
      showRecentSearch: false,
      inputString: city + ', ' + country
    };
  }

  onChangeFile(event: EventTarget) {

    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    this.imageFile = files[0];

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }

  }

  submitProfileData() {
    var self = this;
    if (!self.userProfile || !self.userProfile.uid) {
      self.router.navigate(['/home']);
    }

    // if (!self.userProfile || !self.profileForm.valid || (self.userProfile.categoryId &&
    //   (!self.userProfile.subCategoryId || !self.userProfile.location || !self.userProfile.maxRadius))) {
    //   self.submitted = true;
    //   return;
    // }

    if (!self.userProfile || !self.profileForm.valid || !self.userProfile.location || !self.userProfile.maxRadius) {
      self.submitted = true;
      return;
    }
    if(!self.userProfile.categoryId){ self.userProfile.categoryId = ''}
    else{
      var cIndex = self.categoryList.findIndex(obj => obj.categoryId == self.userProfile.categoryId);
      if (cIndex < 0) {
        return;
      }
    }
    if(!self.userProfile.subCategoryId){ self.userProfile.subCategoryId = ''}
    else{ 
      var scIndex = self.subCategoryList.findIndex(obj => obj.subCategoryId == self.userProfile.subCategoryId)
      if (scIndex < 0) {
        return;
      }
    }

    self.submitted = false;
    self.service.loading = true;

    if (self.imageFile) {
      self.uploadPhoto();
    } else {
      self.updateProfileData();
    }
  }

  uploadPhoto() {
    var self = this;

    const randomId = Math.random().toString(36).substring(2);
    firebase.storage().ref(randomId).put(self.imageFile).then((result) => {
      result.ref.getDownloadURL().then((url) => {
        self.userProfile.profileUrl = url;
        self.updateProfileData();
      });
    }).catch((err) => {
      self.service.loading = false;
      self.toastr.error('Profile data is not updated due to profile image uploading issue', '', { timeOut: 4000 });
    });

  }

  updateProfileData() {
    var self = this;
    debugger

    if (self.userProfile.categoryId) {
      self.userProfile.onlySeeker = false;
    }

    if (!self.userProfile.biography) {
      self.userProfile.biography = '';
    }
    var updates = {};
    updates['/Users/' + self.service.userData.uid] = self.userProfile;

    firebase.database().ref().update(updates).then(() => {
      self.service.loading = false;
      self.service.setActiveUser(self.userProfile);
      self.toastr.success('Profile data updated successfully.', '', { timeOut: 3500 });
      self.router.navigate(['/profile']);
    }).catch((e) => {
      self.service.loading = false;
      self.toastr.success('Profile data is not updated.' + e.message, '', { timeOut: 3500 });
    });
  }

}
