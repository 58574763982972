import { Component, OnInit, NgZone, Input } from '@angular/core';
import { DbDataApiService } from '../services/db-data-api.service';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { iReview } from '../model/all-classes';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public reviewsList: iReview[];

  constructor(private formBuilder: FormBuilder,
    public service: DbDataApiService,
    public router: Router,
    private toastr: ToastrService,
    public zone: NgZone) { }

  ngOnInit(): void {

    this.service.openNotifyMd = false;
    this.getUserReviewsData()
    this.service.reviewDataCheck.subscribe((value) => {
      this.getUserReviewsData()
    });
  }

  public getUserReviewsData() {
    var self = this;
    self.reviewsList = [];

    if (this.service.reviewsList && this.service.reviewsList.length) {

      for (var i = 0; i < self.service.reviewsList.length; i++) {
        if (self.service.reviewsList[i].ratedUser == self.service.userData.uid) {
          self.reviewsList.push(JSON.parse(JSON.stringify(self.service.reviewsList[i])));
        }
      }

      self.updateReviewUserName();
    }
    
  }


  updateReviewUserName() {
    var self = this;

    if (self.reviewsList && self.reviewsList.length && self.service.usersList && self.service.usersList.length) {

      for (var i = 0; i < self.reviewsList.length; i++) {
        var uIndex = self.service.usersList.findIndex(obj => obj.uid == self.reviewsList[i].uid);
        if (uIndex >= 0) {
          self.reviewsList[i].ratedUserName = self.service.usersList[uIndex].name;
        } else {
          self.reviewsList[i].ratedUserName = '';
        }
      }
    }

  }

}
