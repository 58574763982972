<app-navigation></app-navigation>
<ngx-loading [show]="service.loading"> </ngx-loading>
<section class="main-cont">
  <section class="section-1 page-title">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="mb-0">Posted Jobs</h4>
      </div>
      <div class="col-sm-6">
        <!-- <button class="btn btn-outline-secondary" type="button">Previous</button>
        <button class="btn btn-outline-secondary" type="button">Next</button> -->
        <input type="search" class="form-control searchField" [(ngModel)]="term" placeholder="Search Job by Category, Sub Category">
      </div>
    </div>
  </section>
  <section class="section-2">
    <div class="row">
      <div class="col-md-4 col-sm-6" *ngFor="let job of myJobsList | search:term:searchFields; let i=index;">
        <div class="card" [class.item-quote]="!job.onlySeeker">
          <!-- <div class="like-star">
            <div class="circleDiv" *ngIf="job.qouteCount > service.myOldJobs[i].qouteCount"></div>
          </div> -->
          <div class="rating">
            <i class="fas fa-star"></i>
            <span *ngIf="!service.userData.rating">Not rated yet</span>
            <span *ngIf="service.userData.rating">{{service.userData.rating | number:'1.2-2'}}</span>
          </div>
          <img [src]=" job.fileUrls[0] " class="card-img-top" *ngIf="job.fileUrls && job.fileUrls[0]">
          <img src=" /assets/imgs/person.svg" class="card-img-top" *ngIf="!job.fileUrls || !job.fileUrls[0]">

          <div class="card-body">
            <h5 class="card-title">{{service.userData.name | titlecase}}</h5>
            <p class="mb-1"><small class="primary">{{job.categoryName | titlecase}},
                {{job.subCategoryName | titlecase}}</small></p>
                <div class="price">
                  <h5 class="primary bold mt-0 mb-2" *ngIf="job.price"><small>Price: </small> ${{job.price || '0'}}</h5>
                </div>
            <p class="descText mb-2 pointer" (click)="description=job.description " data-toggle="modal"
              data-target="#descModal">{{job.description}}</p>
            <p class="locationText">{{job.location}}</p>
            <div class="d-flex">
              <button class="btn btn-success" type="button" *ngIf="job.status!='Ended'">Live</button>
              <button class="btn btn-danger" type="button" *ngIf="job.status=='Ended'">Ended</button>
              <button class="btn btn-outline-secondary" type="button" (click)="endJobKey=job.jobId" data-toggle="modal"
                data-target="#jobStatusModal" *ngIf="job.status!='Ended'">END</button>
              <div class="d-flex pt-2">

                <p class="primary qouteStyle" [routerLink]="['/quotes-posted',job.jobId]">
                  <span>View Quotes</span><sup>{{job.qouteCount}}</sup>
                </p>


              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4" *ngIf="!myJobsList || !myJobsList.length">
        You did not post any job yet.
      </div>

    </div>
  </section>
</section>

<!-- Modal -->
<div class="modal tichun fade" id="descModal" tabindex="-1" aria-labelledby="descModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <h4 class="primary text-center">Description</h4>
        <p> {{description}} </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!--update Job Status Modal-->
<div class="modal fade" id="jobStatusModal" tabindex="-1" aria-labelledby="jobStatusModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title primary" id="deleteModalLabel">Update Job Status</h5>
      </div>
      <div class="modal-body text-center">
        <img src="/assets/imgs/CrossIcon.png" height="100px;">
        <p>Are you sure? Do you want to End this Job: ?</p>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" id="closeJobStatusMd" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-danger" (click)="updateJobStatus()">End Job</button>
      </div>
    </div>
  </div>
</div>
