<!-- <ngx-loading [show]="service.loading" > </ngx-loading>  -->
<section class="login-form">
    <div class="row">
        <div class="col-sm-4 form-left">
            <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                    <img class="img-responsive" src="/assets/imgs/logo.svg" alt="">
                    <h4 class="py-4">Why our service?</h4>
                    <ul>
                        <li>We’re fast and experienced</li>
                        <li>Powered by tech, so you can</li>
                        <li>Updates and pics for every</li>
                    </ul>
                </div>
                <div class="col-sm-2"></div>
            </div>
        </div>
        <div class="col-sm-8 form-right">
            <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-8 px-0">
                    <div class="row">
                        <div class="col-sm-12">
                            <h1>Welcome Back!</h1>
                            <h1>Enter your info to log in.</h1>
                        </div>
                      
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="my-input">Email Address</label>
                                        <input id="my-input" class="form-control" type="email" [(ngModel)]="userEmail">
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <button class="btn btn-primary mb-2" [disabled]="!userEmail" (click)="sendPasswordResetLink()">Submit</button>
                                </div>
                            </div>
                      
                    </div>
                </div>
                <div class="col-sm-2"></div>
            </div>
            <div class="row form-footer">
                <div class="col-sm-1"></div>
               <div class="col-sm-8">
                <p>Don't have an Account? <span class="pl-2 primary pointer" routerLink="/signup">Sign Up</span></p>
               </div>
               <div class="col-sm-2"></div>
            </div>
        </div>
    </div>
</section>