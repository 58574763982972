<app-navigation></app-navigation>
<section class="main-cont">
  <section class="section-1">
    <div class="row">
      <div class="col-sm-6">
        <div class="row pb-5">
          <div class="col-sm-3 pr-0 profile-img">
            <img [src]="service.userData.profileUrl || '/assets/imgs/person.svg'" alt="">
          </div>
          <div class="col-sm-9">

            <div class="d-flex">
              <h4>{{service.userData.name | titlecase}} Profile</h4>
              <p class="primary pl-5 mt-1 pointer" routerLink="/edit-profile">Edit Profile</p>
            </div>
            <div class="rating">
              <i class="fas fa-star"></i>
              <span *ngIf="!service.userData.rating">Not rated yet</span>
              <span *ngIf="service.userData.rating">{{service.userData.rating | number:'1.2-2'}}</span>
            </div>
          </div>

          <div class="col-sm-12 mt-4">
            <div class="card pointer" routerLink="/jobs-posted">
              <p class="primary">Posted Jobs</p>
              <i class="fas fa-chevron-right"></i>
            </div>
            <div class="card pointer" routerLink="/quotes-submitted">
              <p class="primary">Posted Quotes</p>
              <i class="fas fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 pt-5">
        <div class="reviews-cont">
          <div class="reviews-header">
            <h4 class="primary">Reviews</h4>

          </div>
          <div *ngIf="!reviewsList || !reviewsList.length">
            <h5 class="text-danger pl-2">No reviews to show.</h5>
          </div>
          <div class="reviews-body mt-4">
            <div class="card review" *ngFor="let review of reviewsList; let i=index;">
              <div class="row">
                <div class="col-sm-12 px-0">
                  <p> {{review.comment}} </p> 
                </div>
                <div class="col-sm-12 px-0">
                  <div class="d-flex justify-content-between">
                    <p class="primary">{{review.ratedUserName}}</p>
                    <div>
                      <i class="far fa-star" *ngIf="review.rating <0.3"></i>
                      <i class="fas fa-star" *ngIf="review.rating >=1"></i>
                      <i class="far fa-star" *ngIf="review.rating <1.3"></i>
                      <i class="fas fa-star" *ngIf="review.rating >=1.8"></i>
                      <i class="fas fa-star-half-alt" *ngIf="review.rating >=1.3 && review.rating <1.8"></i>
                      <i class="far fa-star" *ngIf="review.rating <2.3"></i>
                      <i class="fas fa-star" *ngIf="review.rating >=2.8"></i>
                      <i class="fas fa-star-half-alt" *ngIf="review.rating >=2.3 && review.rating <2.8"></i>
                      <i class="far fa-star" *ngIf="review.rating <3.3"></i>
                      <i class="fas fa-star" *ngIf="review.rating >=3.8"></i>
                      <i class="fas fa-star-half-alt" *ngIf="review.rating >=3.3 && review.rating <3.8"></i>
                      <i class="far fa-star" *ngIf="review.rating <4.3"></i>
                      <i class="fas fa-star" *ngIf="review.rating >=4.8"></i>
                      <i class="fas fa-star-half-alt" *ngIf="review.rating >=4.3 && review.rating <4.8"></i>
    
    
                      ({{review.rating | number:'1.2-2'}})
                    </div>
                  </div>
                </div>

              </div>
             
             
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</section>
